
var sharedFactories = angular.module('sharedFactories', [])
.factory('GoogleMaps', [function() {

	var geocoder;
	var map;
	var default_lat = 37.3842;
	var default_lng = -98.6673;
	var sd_lat = 32.7848;
	var sd_lng = -116.7023;
	var default_zoom = 3;
	var zoomedIn = false;
	var markersArray = [];
	var that = this;
	
	google.maps.Map.prototype.clearOverlays = function() {
		for (var i = 0; i < markersArray.length; i++ ) {
			markersArray[i].setMap(null);
		}
		markersArray = [];
	};
	
	this.createMarker = function(location, title, clickEvent) {
		var marker = new google.maps.Marker({
			position: location,
			map: map,
			title: title
		});
		
		markersArray.push(marker);
		
		if (clickEvent) {
			google.maps.event.addListener(marker, 'click', clickEvent);
		} else {
			google.maps.event.addListener(marker, "click", function() {
				$('.map-marker-detail').filter('.map-marker-detail').show();
				$('.map-marker-detail h3').html(title);
			});
		}
		
		return marker;
	};
	
	this.getLocation = function(value, cbfunc) {
		geocoder.geocode({ 'address': value }, cbfunc);
	};
	
	this.clearMarkers = function() {
		if (map && map.clearOverlays) {
			map.clearOverlays();
		}
	};

	function initializeGmaps() {
		var params = getParams();
		
		// use geocoder if the vendor's address is not lat/long
		geocoder = new google.maps.Geocoder();
		
		var latlng = new google.maps.LatLng(default_lat, default_lng);
		
		var mapOptions = {
			center: latlng,
			zoom: default_zoom,
			// disableDefaultUI: true
			streetViewControl: false,
			mapTypeControl: false,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: google.maps.ControlPosition.BOTTOM_CENTER
			},
		};
		
		map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);
		
		google.maps.event.addListener(map, 'center_changed', function() {
			if (that.mapChangedEvent) that.mapChangedEvent(map.getCenter(), map.getZoom());
		});
		
		google.maps.event.addListener(map, 'zoom_changed', function() {
			if (that.mapChangedEvent) that.mapChangedEvent(map.getCenter(), map.getZoom());
		});	
		
		if (params.latitude && params.longitude) {
			var latilong = new google.maps.LatLng(params.latitude, params.longitude);
			
			map.panTo(latilong);
			zoomMap();
			
			var address = '';
			geocoder.geocode({ latLng: latilong }, function(data, status) {
				$.each(data[0].address_components, function(i, component) {
					// this is the city
					if (component.types[0] === 'locality') address += component.short_name;
					// this is the state
					if (component.types[0] === 'administrative_area_level_1') address += ', ' + component.short_name;
				});
				
				if (that.updateLocationString) that.updateLocationString(address);
			});
			
		} else if(navigator.geolocation) {
			var success = function(position) {
				map.panTo(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
				zoomMap();
			};
			var error = function() { /*createMap(default_lat, default_lng);*/ }

			navigator.geolocation.getCurrentPosition(success, error);
		}
	}
	
	function codeAddress() {
		var address = document.getElementById('address').value;
		geocoder.geocode( { 'address': address}, function(results, status) {
			if (status == google.maps.GeocoderStatus.OK) {
				map.setCenter(results[0].geometry.location);
				var marker = new google.maps.Marker({
					map: map,
					position: results[0].geometry.location
				});
			} else {
				alert('Geocode was not successful for the following reason: ' + status);
			}
		});
	}
	
	this.centerUSA = function() {
		this.goto(3,default_lat,default_lng);
	};

	function centerAlb() {
		this.goto(10,35.1113,-106.5319);
	}

	function centerSD() {			
		this.goto(10,sd_lat,sd_lng);
	}

	function zoomMap() {
		var zoom = Number(9);
		map.setZoom(zoom);
	}
	
	function centerMap() {
		var lat = document.getElementById('lat').value;
		var lng = document.getElementById('lng').value;
		var latlng = new google.maps.LatLng(lat,lng);
		map.setZoom(default_zoom);
		map.panTo(latlng);
	}

	function logMap() {

	}
	
	this.goto = function(zoom,lat,lng){
	
		var latlng = new google.maps.LatLng(lat,lng);
		map.panTo(latlng);
		map.setZoom(zoom);
		/*
		if(!zoomedIn) {
			smoothZoom(map, zoom, map.getZoom(), true);
			zoomedIn = true;
		} else {
			smoothZoom(map, default_zoom, map.getZoom(), false);
			zoomedIn = false;
		}*/
	};
	
	
	function smoothZoom(map, level, cnt, mode) {
		
		// If mode is zoom in
		if(mode == true) {
		
			if (cnt > level) {
				return;
			} else {
				var z = google.maps.event.addListener(map, 'zoom_changed', function(event){
				google.maps.event.removeListener(z);
					smoothZoom(map, level, cnt + 1, true);
				});
				setTimeout(function(){map.setZoom(cnt)}, 80);
			}
		// mode if false (zoom out)
		} else {
			if (cnt < level) {
				return;
			} else {
				var z = google.maps.event.addListener(map, 'zoom_changed', function(event) {
				google.maps.event.removeListener(z);
					smoothZoom(map, level, cnt - 1, false);
				});
				setTimeout(function(){map.setZoom(cnt)}, 80);
			}
		}
	}
	
	initializeGmaps();
	
	return this;
}]);