import portalApp from './_angular';
portalApp
.controller('ClientReportSubCtrl', ['$scope', function($scope) {
    $scope.$on('$routeChangeStart',function(e) {
        $scope.is_loading = true;
    });
    $scope.$on('$routeChangeSuccess',function(e) {
        $scope.is_loading = false;
    });
}])
.controller('ClientReportCtrl', ['$scope', '$routeParams', 'Account', 'AccountProfile', 'ClientAnnualReports', 'USER_TYPE', 'ACCOUNT_ID', function($scope, $routeParams, Account, AccountProfile, ClientAnnualReports, USER_TYPE, ACCOUNT_ID) {
	var previous_year = new Date(moment().subtract('year', 1).format('YYYY'));
	$scope.new_report = {};
	$scope.delete_report = {};
	$scope.annual_reports = [];

    $scope.$on('$routeChangeStart',function(e) {
        $scope.is_loading = true;
    });
    $scope.$on('$routeChangeSuccess',function(e) {
        $scope.is_loading = false;
    });

	if (!$routeParams.accountID) $routeParams.accountID = ACCOUNT_ID;

	function resetNewReport() {
		return new ClientAnnualReports({
			year: previous_year.getUTCFullYear(),
			title: 'Annual Report for ' + yearAndNext(previous_year)
		});
	}

	function getAnnualReports() {
		ClientAnnualReports.query({ accountID: $routeParams.accountID }, function(response) {
			$scope.annual_reports = response;
		});
	}

	$scope.new_report = resetNewReport();

	$scope.new_report_button_text = 'Click to upload file...';
	var new_report_options = {
		data: {
			type: 'private',
			scope: 'account',
			action: 'reports'
		},
		previousSize: 0,
		handleProgress: function(e) {
			if (!e) return;

			var progress = this.totalProgress + e.loaded;

			var size = parseInt(progress / this.totalSize * 100, 10);

			$scope.new_report_button_text = size + '% uploaded...';

			if (size >= 100) $scope.new_report_button_text = 'Finalizing...';

			if (!$scope.$$phase) $scope.$apply();
		},
		handleComplete: function(e) {
			var status = e.target.status;

			switch (status) {
				case 200:
					var json = $.parseJSON(e.target.response);

					if (!json.success) {
						growlError(json.message);
					} else {

						$scope.new_report.file = json.data;
						$scope.new_report.file_id = json.data.id;
					}

					break;
				default:
					growlError('Upload failed!');
					break;
			}

			$scope.new_report_button_text = 'Click to upload file...';

			if (!$scope.$$phase) $scope.$apply();
		}
	};
	$scope.uploadFile = function() {
		new_report_options.data.accountID = $scope.account.id;

		$.each(arguments, function(i, file) {
			new FileUploader(file, new_report_options);
		});
	};
	$scope.createNewReport = function(e) {
		disableButton(e.delegateTarget);
		$scope.new_report.$save(function() {

			undisableButton(e.delegateTarget);

			hideModals();
			$scope.new_report = resetNewReport();
			getAnnualReports();
		});
	};
	$scope.deleteReport = function(e) {
		disableButton(e.delegateTarget);
		$scope.delete_report.$delete(function() {
			undisableButton(e.delegateTarget);
			hideModals();
			getAnnualReports();
		});
	};

	Account.get({ accountID: $routeParams.accountID }, function(response) {
		$scope.account = response;
		$scope.new_report.account_id = $scope.account.id;
	});

	AccountProfile.get({ accountID: $routeParams.accountID }, function(response) {
		$scope.account_profile = response;
	});

	getAnnualReports();
}]);
