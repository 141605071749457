import portalApp from './_angular';
portalApp
.directive('alCropper', [function() {

	function link(scope, element, attrs) {
		var $target, $holder, $content, $img, $preview, $preview_img, $save, $change, $res_alert, $progress, upload_file;
		var cropper;

		var progress_options = {
			previousSize: 0,
			handleProgress: function(e) {
				if (!e) return;

				var chunksize = e.loaded - this.previousSize;
				this.previousSize = e.loaded;

				$progress.trigger('progressbar.update', chunksize);
			},
			handleComplete: function(e) {
				var status = e.target.status;

				switch (status) {
					case 200:
						var json = $.parseJSON(e.target.response);
						scope.closeCropper();
						$target.attr('src', json.data.url.or);

						if (scope.updateCropperImage) scope.updateCropperImage(json.data);
						break;
					default:
						//$(that).addClass('error-404');
						break;
				}

				$save.html('Save');
				$holder.find('.btn').prop('disabled', false);
				$progress.parent().slideUp();
			}
		};

		function initializeJcrop(file) {
			upload_file = file;

			if (file.type.split('/')[0] !== 'image') {
				growlError('Must upload an image!');
				return;
			}

			if (cropper && cropper.destroy) {
				cropper.destroy();
				$img.attr('src', '').removeAttr('style');
			}

			var boxWidth = calcWidth();
			var boxHeight = 500;

			// set new html img src
			var reader = new FileReader();
			reader.onload = function (e) {
				$img.attr('src', e.target.result);
				$preview_img.attr('src', e.target.result);

				var options = {
					aspectRatio: 1,
					boxWidth: boxWidth,
					boxHeight: boxHeight,
					onChange: showPreview,
					onSelect: showPreview
				};

				if (!$content.attr('data-square-crop')) {
					delete options.aspectRatio;
				}

				$img.Jcrop(options, function() {
					cropper = this;
					this.setSelect([0, 0, 400, 400]);
				});
			};
			reader.readAsDataURL(file);
		}

		// dynamic width calculation to provide best fit for screen
		function calcWidth() {
			return window.outerWidth < 600 ? window.outerWidth - 80 : 600;
		}
		function assignElements() {
			$img			= $holder.find('.cropper-image');
			$preview		= $holder.find('.cropper-preview');
			$preview_img	= $preview.find('img');
			$res_alert		= $holder.find('.cropper-resolution-alert');
			$save			= $holder.find('.cropper-save');
			$change			= $holder.find('.cropper-new-image');
			$progress		= $holder.find('.progress');
		}

		function showPreview(coords) {
			var bounds = cropper.getBounds();

			//var box_height = 200 / coords.h;


			/*$holder.find('.cropper-preview img').css({
				width: Math.round(rx_med * bounds[0]) + 'px',
				height: Math.round(ry_med * bounds[1]) + 'px',
				marginLeft: '-' + Math.round(rx_med * coords.x) + 'px',
				marginTop: '-' + Math.round(ry_med * coords.y) + 'px'
			});*/
			var height_ratio = 200 / coords.h;
			var width_ratio = 200 / coords.w;

			var marginTop = height_ratio * coords.y;
			var marginLeft = width_ratio * coords.x;

			var new_width = width_ratio * bounds[0];
			var new_height = height_ratio * bounds[1];

			// if wider then adjust size by width
			if (coords.w > coords.h) {
				$preview_img.width('auto');
				$preview_img.height(new_height);
			} else {
				$preview_img.height('auto');
				$preview_img.width(new_width);
			}

			/*if (coords.w >= 200) {
				var center_width = (coords.x + (coords.w / 2) - 200) * width_ratio;
				marginLeft = center_width;
			}

			if (coords.h >= 200) {
				var center_height = (coords.y + (coords.h / 2) - 200) * height_ratio;
				marginTop = center_height;
			}*/

			$preview_img.css('margin-top', -marginTop);
			$preview_img.css('margin-left', -marginLeft);


			// here it is... though
			if (coords.w < 400 || coords.h < 400) {
				$res_alert.removeClass('hide');
			}
			else { $res_alert.addClass('hide'); }
		}

		function assignTarget(target_id) {
			$target = $(target_id);
		}

		function createFileInput(cbfunc) {
			var $file_input = $('<input type="file" />');
			$file_input.change(cbfunc);
			$file_input.click();
		}

		$holder = element;
		$content = $holder.find('.cropper-container');

		assignElements();

		$progress.affinityprogressbar();

		scope.saveCropper = function(url) {
			$save.html('<i class="fa fa-spin fa-spinner"></i>Saving...');
			$holder.find('.btn').prop('disabled', true);
			$progress.parent().slideDown();

			var coords = cropper.tellSelect();
			progress_options.crop = coords;
			progress_options.url = url;

			$progress.trigger('progressbar.increment-amount', upload_file.size);

			new FileUploader(upload_file, progress_options);
		};

		scope.showCropper = function() {
			$('body').addClass('fullscreen');
			$holder.show();
		};

		scope.closeCropper = function() {
			$holder.hide();
			$('body').removeClass('fullscreen');
		};

		scope.showImageUploader = function(e) {
			assignTarget($(e.target).attr('data-cropper'));

			createFileInput(function(e) {
				scope.createCropper.apply(this, e.target.files);
			});
		};

		scope.changeImage = function(e) {
			createFileInput(function(e) {
				initializeJcrop.apply(this, e.target.files);
			});
		};

		scope.createCropper = function(file) {
			if ($(this).attr('data-target')) {
				assignTarget($(this).attr('data-target'));
			}

			initializeJcrop(file);

			scope.showCropper();
		};
	}

	return {
		link: link
	};
}]);
