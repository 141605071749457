import portalControllers from './_controllers';

portalControllers
    .controller('pendingApplicationCountCtrl', ['$scope', '$http', '$routeParams', function ($scope, $http, $routeParams) {
        var path_parts = getPathParts();
        var url_prefix = '';
        var products_url_prefix = '';
        //to determine if the vendor id is in the url (is an admin)
        if (path_parts[0] == 'vendors') {
            url_prefix = '/api/v2/vendors/' + path_parts[1] + '/licenses/applications/';
        } else {
            url_prefix = '/api/v2/licenses/applications/';
        }
        $scope.url_prefix = url_prefix;
        $.getJSON(url_prefix + 'pending-licenses', function (response) {
            $scope.pending_application_count = response.count;
        });

    }])
    .controller('ApplicationCtrl', ['$scope', '$http', '$routeParams', 'LicenseApplications', '$location', '$window', 'USER_TYPE', 'API', 'VendorInsurance', 'AccountProfileUpdate', function ($scope, $http, $routeParams, LicenseApplications, $location, $window, USER_TYPE, API, VendorInsurance, AccountProfileUpdate) {

        var path_parts = getPathParts();
        var url_prefix = '';
        var products_url_prefix = '';
        var application_id = '';
        var accountID = '';
        //to determine if the vendor id is in the url (is an admin)
        if (path_parts[0] == 'vendors') {
            url_prefix = '/api/v2/vendors/' + path_parts[1] + '/licenses/applications/';
            products_url_prefix = 'vendors/' + path_parts[1] + '/';
            $scope.invoice_link = '/vendors/' + path_parts[1] + '/invoices/';
            application_id = path_parts[4];

            accountID = path_parts[1];
        } else {
            url_prefix = '/api/v2/licenses/applications/';
            application_id = '';
        }
        $scope.defaultProductImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/alp-icon.png';
        $scope.path = window.location.pathname;
        /****************************************************************
         Form Overrides
         ****************************************************************/
        //to override the licensor update form submission
        $scope.setFormOverrides = function () {
            $('#add-clients-form').validate({
                submitHandler: function (form) {
                    var $button = $(form).find('[type="submit"]');
                    $button.prop('disabled', true).data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i>Saving...');
                    $.ajax({
                        url: window.location.pathname + '/organizations',
                        type: 'post',
                        data: $(form).serialize(),
                        dataType: 'json',
                        success: function (response) {
                            $scope.parseApplication(response.data);
                        },
                        complete: function () {
                            $('#add-clients-modal').modal('hide');
                            $button.html($button.data('old')).prop('disabled', false);
                        }
                    });
                }
            });

            //to override the products update form submission
            $('#update-products-form').validate({
                submitHandler: function (form) {
                    var $button = $(form).find('[type="submit"]');
                    $button.prop('disabled', true).data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i>Saving...');
                    $.ajax({
                        url: window.location.pathname + '/products',
                        type: 'post',
                        data: $(form).serialize(),
                        dataType: 'json',
                        success: function (response) {

                            $scope.parseApplication(response.application);
                            growl('Product Changes Saved');

                        },
                        complete: function () {
                            $button.html($button.data('old')).prop('disabled', false);
                        }
                    });
                }
            });

            //to override the operations form submission operations-form
            /*
             $('#operations-form').validate({
             submitHandler: function(form) {
             var $button = $(form).find('[type="submit"]');
             $button.prop('disabled', true).data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i>Saving...');
             $.ajax({
             url: window.location.pathname + '/operations',
             type: 'post',
             data: $(form).serialize(),
             dataType: 'json',
             success: function(response) {
             // growl('Operations Changes Saved');
             },
             complete: function() {
             $button.html($button.data('old')).prop('disabled', false);
             }
             });
             }
             });*/
        };


        /**************************************************
         Prepare Objects for each section
         ***************************************************/
        $scope.client_spinner = 1;
        $scope.filters = {
            name: "",
            market_id: "",
            organization_type: "",
            license_status: "1"
        };

        $scope.settings = {
            have_annual_renewals: "",
            have_late_fee: "",
            have_royalty_reports: "",
            have_sharing: ""
        };
        $scope.licensors = {
            updates: [],
            newApplications: [],
            alreadyGenerated: []
        };
        $scope.notes = {
            note_to_vendor: "",
            note_to_licensor_for_update: "",
            recommendation_to_client: ""
        };
        $scope.parsed_products = {
            on_application: [],
            archived: [],
            suggested: []
        };
        $scope.fla_required = null;
        $scope.suppliers = {};
        $scope.manufacturers = {};
        $scope.step = 1;
        $scope.application_settings = {
            on_hold: "",
            hold_description: "",
            products_approved: ""
        };
        $scope.client_list = [];
        $scope.initial_count = 0;
        $scope.time_estimate = 0;
        /*************************************************
         Run all the necessary ajax calls
         *************************************************/
        $.getJSON(url_prefix + 'possible-clients', function (response) {
            $scope.possible_clients = response.data;
            $.each($scope.possible_clients, function (i, client) {
                $scope.client_list.push(client);

            });
            $scope.parseApplicationClients();
        });
        $.getJSON(url_prefix + 'license-app/' + application_id, function (response) {
            $scope.parseApplication(response.data);
        });
        $scope.products = [];
        $scope.haveProducts = false;
        $scope.hasInsurance = null;
        $scope.updateGetProducts = function (page) {

        $http({ method: 'GET', url: `/api/vendor-products${accountID ? `?account_id=${accountID}` : ''}` }).success(function (response) {
          $scope.products = response.data;
          $scope.haveProducts = true;
          $scope.parseProducts();
        });
      };

        $scope.updateGetProducts();

        AccountProfileUpdate.get({ accountID: $routeParams.param2, }, (response) => {
          $scope.accountUpdate = response.data;
        });
        /*********************************************************
         Supporting Functions
         *********************************************************/

        $scope.parseApplication = function (application) {

            $scope.application = application;
            VendorInsurance.get({id: application.vendor.id}, function (response) {
                if (response.old) {
                    var today = new Date();
                    var expiration = new Date(response.old.valid_end);
                    var isValid = today < expiration;
                    $scope.hasInsurance = (isValid && response.old.phase == 3);
                } else {
                    $scope.hasInsurance = false;
              }

            });

            $scope.invoice_link = $scope.invoice_link + application.invoice_id;

            // Disable navigation for pending refresh applications
            if (application.is_refresh == 1 && !application.isSubmited) {
                $('.disabled-navigation').html('Please finish and submit the license refresh.').addClass('hide-both');

                $scope.$watch('application.isSubmited', function (value) {
                    if (value) $('.disabled-navigation').removeClass('hide-both');
                });
            }

            $scope.$on('$destroy', function () {
                $('.disabled-navigation').removeClass('hide-both').html('');
            });

            /*Admin page settings*/
            // set the settings object
            $scope.settings = {
                have_annual_renewals: application.vendor.profile.have_annual_renewals,
                have_late_fee: application.vendor.profile.have_late_fee,
                have_royalty_reports: application.vendor.profile.have_royalty_reports,
                have_sharing: application.vendor.profile.have_sharing,
                has_standard_royalty: application.vendor.profile.has_standard_royalty,
                has_minimum_royalty: application.vendor.profile.has_minimum_royalty
            };

            $scope.notes = {
                note_to_vendor: application.note_to_vendor,
                note_to_licensor_for_update: application.note_to_licensor_for_update,
                recommendation_to_client: application.vendor.profile.recommendation_to_client
            };

            $scope.application_settings = {
                on_hold: application.on_hold,
                hold_description: application.hold_description,
                products_approved: application.products_approved,
            };


            $scope.applicationClients = [];
            $scope.applicationClients = application.licesnse_application_clients;

            $scope.initial_count = $scope.applicationClients.length;

            $scope.time_estimate = Math.ceil($scope.applicationClients.length * 4 / 60);

            $scope.selected_products = [];
            $scope.selected_products = application.licesnse_application_products;
            $scope.parseProducts();

            $scope.account_profile = application.vendor.profile;

            if (application.vendor.hasOwnProperty('pending_address') && application.vendor.pending_address.hasOwnProperty('business')) {
                $scope.is_pending_address = true;
                $scope.account = application.vendor.pending_address.business;
            }
            else {
                $scope.is_pending_address = false;
                $scope.account = application.vendor;
            }

            $scope.suppliers = (!$scope.account_profile.supplier_list ? {} : JSON.parse($scope.account_profile.supplier_list));
            $scope.manufacturers = (!$scope.account_profile.manufacturer_list ? {} : JSON.parse($scope.account_profile.manufacturer_list));
            //update the window with all the new values
            if (!$scope.$$phase) $scope.$apply();
            $scope.setFormOverrides();
            $scope.parseApplicationClients();

        };

        $scope.removeClient = function (event, object, url) {
            angular.element($('#checkbox-styled-' + object.id)).scope().is_checked = 0;
            $scope.saveChanges(event, object, url);
        };
        $scope.productAdded = function (data) {
            //$scope.products = [];/pendin
            $.getJSON(url_prefix + 'license-app', function (response) {
                $scope.parseApplication(response.data);
            });
            $scope.updateGetProducts();
        };
        $scope.parseProducts = function () {
            $scope.parsed_products = {
                on_application: [],
                archived: [],
                suggested: []
            };
            if ($scope.haveProducts && $scope.selected_products) {

                $scope.products.forEach(function (element) {
                    //first pick out the archived
                    if (element.category.archive == 1 || element.category.is_parent == 1 || element.category.verified != 1) {
                        $scope.parsed_products.archived.push(element);
                    }
                    //then pick out what is on the application
                    else if ($scope.selected_products.indexOf(element.id) > -1) {
                        $scope.parsed_products.on_application.push(element);
                    }
                    //last everything else is suggested
                    else {
                        $scope.parsed_products.suggested.push(element);
                    }
                });
            }
        };

        $scope.canEditProducts = function() {
            return ($scope.application.status == 2 || $scope.application.status == 3) && $scope.licensors.alreadyGenerated.length < 1;
        };


        $scope.navButtonClicked = function (add) {
            if (add) {
                $scope.step = $scope.step + 1;
            }
            else {
                $scope.step = $scope.step - 1;
            }
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        };

        /*
         Separate and fill in the list of clients on the application
         */
        $scope.parseApplicationClients = function () {
            //if we have all the data that is needed to properly parse the data
            $scope.licensors = {
                updates: [],
                newApplications: [],
                pendingConflict: [],
                alreadyGenerated: []
            };

            var hasFlaRequired = false;


            if ($scope.applicationClients && $scope.possible_clients) {
                $scope.client_spinner = 0;
                $scope.applicationClients.forEach(function (element) {

                    var client = $scope.possible_clients[element];

                    if (client.fla_required == 1) {
                        console.log(client);
                        hasFlaRequired = true;
                    }

                    if (client.license_application_id == $scope.application.id) {
                        //The license has already been generated for this org on this application
                        $scope.licensors.alreadyGenerated.push(client);
                    }
                    else if (client.license_status == 1 && client.license_phase !== 0) {
                        // they have a pending license already, and we need to display a warning
                        $scope.licensors.pendingConflict.push(client);
                    }
                    else if (client.license_status != 2) {
                        //they are not licensed so this is a new license application
                        $scope.licensors.newApplications.push(client);
                    }
                    else {
                        //they are licensed so this is an update
                        $scope.licensors.updates.push(client);
                    }

                });
                if (!$scope.$$phase) $scope.$apply();
                $scope.setFormOverrides();

            }

            $scope.fla_required = hasFlaRequired;
        };




        $scope.submitRefresh = function () {
            $('#submitRefresh').prop('disabled', true).data('old', $('#submitRefresh').html()).html('<i class="fa fa-spin fa-spinner"></i>Submitting...');
            $('#submit-refresh-modal').modal('hide');
            $scope.is_loading = true;
            $http.get(url_prefix + 'submit-refresh')
                .success(function (data) {
                    $location.path(products_url_prefix + "licenses");
                })
                .error(function (data) {
                    growlError(data.error.message);
                    $('#submitRefresh').html($('#submitRefresh').data('old')).prop('disabled', false);
                });
        };

        $scope.rejectApplication = function () {
            $('#reject-application').prop('disabled', true).data('old', $('#reject-application').html()).html('<i class="fa fa-spin fa-spinner"></i>Rejecting...');
            var message = $('#reject-entity-message').html().trim();
            if (!message || message.length < 10) {
                growlError('Please add a message.');
                return;
            }
            $('#reject-application').modal('hide');
            $scope.is_loading = true;
            $http.post(url_prefix + 'submit-application-rejection', {rejectMessage: message})
                .success(function (data) {
                    $location.path(products_url_prefix + "licenses");
                })
                .error(function (data) {
                    growlError(data.error.message);
                    $('#reject-application').html($('#reject-application').data('old')).prop('disabled', false);
                });
        };

        /*
         code to handle application updates
         */
        $scope.saveChanges = function (event, object, url) {
            $scope.clickedButton = $(event.delegateTarget);
            $scope.buttonPreviousHTML = $scope.clickedButton.html();
            $scope.clickedButton.html('<span class="fa fa-spin fa-spinner"></span>').prop('disabled', true);
            $http.post(url_prefix + url, object).success(function (data) {
                growl(data.message);
                $scope.clickedButton.html($scope.buttonPreviousHTML).prop('disabled', false);

                $scope.clickedButton = false;
                $scope.buttonPreviousHTML = false;
                //if it has data then it has updated info for the application
                if (data.hasOwnProperty('data')) {
                    $scope.parseApplication(data.data);
                }
            });
        };

        $scope.showTab = function (tab) {
            $('#nav-tabs').find("a[data-target='#" + tab + "']").tab('show');
        };

        $scope.licensesGenerating = [];

        $scope.generateSingleLicense = function (client, type) {
          if (!$scope.licensesGenerating.includes(client.id)) {
            $scope.licensesGenerating.push(client.id);
            $http.get('/api/v2/vendors/' + path_parts[1] + '/licenses/applications/' + client.id + "/generateLicense").then(
              //first, success
              function (response) {
                $scope.licensesGenerating = $scope.licensesGenerating.filter(c => c !== client.id);
                let i;
                switch (type) {
                  case 'update':
                  i = $scope.licensors.updates.findIndex(c => c.id === client.id);
                  $scope.licensors.updates.splice(i, 1);

                  break;
                  case 'pending':
                  i = $scope.licensors.pendingConflict.findIndex(c => c.id === client.id);
                  $scope.licensors.pendingConflict.splice(i, 1);
                  break;
                  case 'newApp':
                  i = $scope.licensors.newApplications.findIndex(c => c.id === client.id);
                  $scope.licensors.newApplications.splice(i, 1);
                  break;

                  default:
                  break;
                }
                $scope.licensors.alreadyGenerated.push(client);
                $scope.licensesGenerating = $scope.licensesGenerating.filter(c => c !== client.id);
              },
              //then fail
              function (response) {
                // console.log('fail');
                $scope.licensesGenerating = $scope.licensesGenerating.filter(c => c !== client.id);
              }
            );
          }
      }

    }])
    .controller('ApplicationApprove', ['$scope', '$http', '$location', function ($scope, $http, $location) {

        var path_parts = getPathParts();
        var url_prefix = '/api/v2/vendors/' + path_parts[1] + '/licenses/applications/';
        var license_url = 'vendors/' + path_parts[1] + '/licenses';

        $scope.message = {
            message: ''
        };


        $scope.finished = false;

        $scope.running = 0;

        $scope.success_signed_count = 0;
        $scope.fail_signed_count = 0;


        /*
         $http.get('/api/v1'+$scope.link_prefix+'licenses?where%5Bphase%5D%5Bis%5D=3&limit=-1').success(function(response) {
         $scope.pendingList = response.data;
         $scope.initial_count = response.data.length;
         $scope.time_estimate = Math.ceil(response.data.length * 4 /60);
         });

         /*
         $scope.approveApplication = function(){
         $('#approve-application').prop('disabled', true).data('old', $('#approve-application').html()).html('<i class="fa fa-spin fa-spinner"></i>Approving...');
         $('#approve-application-modal').modal('hide');
         $scope.is_loading = true;
         $http.get(url_prefix + 'approve')
         .success( function(data){
         $location.path(products_url_prefix+"licenses");
         })
         .error(function(data){
         growlError(data.error.message);
         $('#approve-application').html($('#approve-application').data('old')).prop('disabled', false);
         });
         };
         $scope.startApprovalProcess = function(){

         };
         $scope.generateLicense = function(){

         };

         */

        $scope.startLicenseGeneration = function () {
            $scope.pendingList = $scope.applicationClients;
            /*
             Next, disable the buttons and make whatever necesary UI changes
             */
            $('#cancel-approve-button, #application-modal-approve').hide();
            $('#batch-modal-loading').removeClass('hidden');
            $('#approve-modal-button').html('<i class="fa fa-spin fa-spinner"></i>Sending...').prop('disabled', true);
            $scope.generateLicense();
            $scope.generateLicense();
            $scope.generateLicense();
        };

        $scope.generateLicense = function () {


            if ($scope.pendingList.length === 0) {
                if ($scope.running === 0) {
                    $http.get(url_prefix + 'approve')
                        .success(function (data) {
                            if (data.success) {
                                $('#approve-application-modal').modal('hide');
                                $location.path(license_url);
                            }
                            else {
                                growlError(data.message);
                            }

                        })
                        .error(function (data) {
                            growlError(data.error.message);
                            //$('#approve-application').html($('#approve-application').data('old')).prop('disabled', false);
                        });

                }
                return;
            }
            $scope.running += 1;


            var clientId = $scope.pendingList.shift();


            $http.get(url_prefix + clientId + "/generateLicense").then(
                //first, success
                function (response) {
                    if (response.data.success) {
                        $scope.success_signed_count += 1;
                    } else {
                        $scope.fail_signed_count += 1;
                    }
                    $scope.running -= 1;
                    $scope.time_estimate = Math.ceil($scope.pendingList.length * 4 / 60);
                    $scope.generateLicense();
                },
                //then fail
                function (response) {
                    // console.log('fail');
                    $scope.fail_signed_count += 1;
                    $scope.running -= 1;
                    $scope.time_estimate = Math.ceil($scope.pendingList.length * 4 / 60);
                    $scope.generateLicense();
                }
            );
        };
    }])

    .controller('LicenseApplicationClientAddCtrl', ['$scope', function ($scope) {
        $scope.is_checked = 1;

        if (($scope.select_client == $scope.client.id) || ($scope.applicationClients.indexOf($scope.client.id) >= 0)) {
            $scope.is_checked = 1;


        } else {
            $scope.is_checked = 0;

        }

        //this is to hide all except those that are selected. This will keep all the elements on the form and just hide those that dont want to be seen
        $scope.isExcludedByFilter = applySearchFilter();
        $scope.$watch('filters.license_status', function (value, oldval, $scope) {
            applySearchFilter();
        }, true);
        $scope.$watch('filters.market_id', function (value, oldval, $scope) {
            applySearchFilter();
        }, true);
        $scope.$watch('filters.organization_type', function (value, oldval, $scope) {
            applySearchFilter();
        }, true);
        $scope.$watch('filters.name', function (value, oldval, $scope) {
            applySearchFilter();
        }, true);
        $scope.$watch('applicationClients', function (value, oldval, $scope) {
            applySearchFilter();
        }, true);

        function applySearchFilter() {
            //I am going to need four filters. One for license status, Gender Type, Name and Market
            var LicenseFilter = (
                //preselected so needs to stay selected
                ($scope.applicationClients.indexOf($scope.client.id) < 0) &&

                (
                    //nothing is selected so allow
                    $scope.filters.license_status === "" ||
                    (
                        (//if not licensed selected
                            $scope.filters.license_status == 1 &&
                            (
                                $scope.client.license_status != 2
                            )
                        ) ||
                        (//if licensed selected
                            $scope.filters.license_status == 2 &&
                            (
                                $scope.client.license_status == 2 &&
                                //to remove all pending licenses
                                ['1', '2', '3'].indexOf($scope.client.license_phase) < 0
                            )
                        )
                    )
                )
            );

            var MarketFilter = ($scope.client.market_id == $scope.filters.market_id || $scope.filters.market_id === "");

            var GenderFilter = ($scope.client.organization_type == $scope.filters.organization_type || $scope.filters.organization_type === "");

            var NameFilter = ($scope.client.name.toLowerCase().indexOf($scope.filters.name.toLowerCase()) !== -1);

            //should only show up if it passes all three filters
            $scope.isExcludedByFilter = !(LicenseFilter && MarketFilter && GenderFilter && NameFilter);
        }
    }])
;
