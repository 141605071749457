import portalControllers from './_controllers';
portalControllers
    .controller('InsuranceIndexCtrl', ['$scope', '$http', function($scope, $http){
        $scope.is_loading = true;
        $scope.creatingNew = false;

        $http.get('/api/v2/insurance').then(
            function(response) {
                $scope.policies = response.data.data;
                $scope.is_loading = false;
            },
            function(response){}
        );

    }])

    .controller('InsuranceViewCtrl', ['$scope', '$http', '$location', '$routeParams', 'Insurance', 'VendorInsurance', function($scope, $http, $location, $routeParams, Insurance, VendorInsurance) {
		$scope.date = new Date();
        var path_parts = getPathParts();
        //to determine if the vendor id is in the url (is an admin)
        if (path_parts[0] == 'vendors') {
            $scope.role = 'admin';
        } else {
            $scope.role = 'vendor';
        }
        console.log(path_parts, 'these are the parts');

        $scope.insurance_button_text = 'Upload';

        $scope.hash = $location.hash();
        $scope.is_loading = true;
        $scope.initial_load_complete = false;

        $scope.insurance = {
            new: null,
            old: null
        };

        var accountId = $routeParams.accountID;
        var insuranceGet = $scope.role == 'admin' ? VendorInsurance.get : Insurance.get;

        insuranceGet({id: accountId}, function(data) {
            $scope.is_loading = false;
            $scope.initial_load_complete = true;
            $scope.insurance.new = data.new;
            $scope.insurance.old = data.old;
            $scope.displayAdminTransition = false;
            $scope.vendorInsuranceDescription = data.vendor_insurance_description;
            if (data.new) {
                $scope.creatingNew = true;
                if (data.new.phase == 2) {
                    $scope.displayAdminTransition = true;
                }
            }
        });

        /**
         * Action for an admin to update a given insurance record.
         * @param {string} formId
         */
        $scope.updateInsurance = function(formId) {
            if (formId !== 'old' && formId !== 'new') {
                console.error("Invalid formId");
                return;
            }
            var insuranceModel = $scope.insurance[formId];
            if (!insuranceModel) {
                console.error("Attempting to submit empty insurance model");
                return;
            }
            if($scope.validateForm(formId) || $scope.role == 'admin'){
                insuranceModel.account_id = accountId;
                var insurance = new Insurance(insuranceModel);
                $scope.is_loading = true;
                insurance.$save(
                    {id: insuranceModel.id},
                    function success(response) {
                        $scope.insurance[formId] = response;
                        $scope.errors = {};
                        $scope.is_loading = false;
                        if (formId == 'new') {
                            if($scope.insurance.new.phase == 2) {
                                $scope.displayAdminTransition = true;
                            } else {
                                $scope.displayAdminTransition = false;
                            }
                        }
                    },
                    function fail(response) {
                        // Emit the first error as a warning
                        if (response.data && response.data.errors) {
                            for (var k in response.data.errors) {
                                growlError(response.data.errors[k]);
                                break;
                            }
                            $scope.is_loading = false;
                        }
                    }
                );
            }
        };

        $scope.displaySubmitForReviewModal = function(formId) {
            if ($scope.validateForm(formId)) {
                $('#submit-policy-modal').modal('show');
            }
        };

        /**
         * Action for a vendor to submit a insurance record for review.
         * @param formId
         */
        $scope.submitForReview = function(formId) {
            if (formId !== 'old' && formId !== 'new') {
                console.error("Invalid formId");
                return;
            }
            var insuranceModel = $scope.insurance[formId];
            if (!insuranceModel) {
                console.error("Attempting to submit empty insurance model");
                return;
            }
            if($scope.validateForm(formId)) {
                insuranceModel.account_id = accountId;
                var insurance = new Insurance(insuranceModel);
                $scope.is_loading = true;
                $('#submit-policy-modal').modal('hide');
                insurance.$save(
                    {id: insuranceModel.id},
                    function success(response) {
                        growl("Insurance successfully submitted for review");
                        $scope.insurance[formId] = response;
                        $scope.is_loading = false;
                    },
                    function fail(response) {
                        // Emit the first error as a warning
                        if (response.data && response.data.errors) {
                            for (var k in response.data.errors) {
                                growlError(response.data.errors[k]);
                                break;
                            }
                            $scope.is_loading = false;
                        }
                    }
                );
            }
        };

        $scope.formatDate = function(formId, field){
          var insuranceModel = $scope.insurance[formId];
          //make sure the data is in the proper pattern
          var date = new Date(insuranceModel[field]);
          if(date == 'Invalid Date'){
            return;
          }
          if(date.getHours() > 0){
            date.setDate(date.getDate() + 1);
          }
          //var dayAddition = date.getHours() > 0 ? 1 : 0;
          const month = date.getMonth() + 1;
          const day = date.getDate();

          insuranceModel[field] = `${date.getFullYear()}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`;
        };
        $scope.adminAction = function(formId, action) {
            var insuranceModel = $scope.insurance[formId];
            var insurance = new Insurance(insuranceModel);
            insurance.action = action;
            $scope.is_loading = true;
            if (action === 'accept') {
                $('#accept-policy-modal').modal('hide');
            }
            if (action === 'reject') {
                $('#send-to-vendor-modal').modal('hide');
            }

            insurance.$adminAction(
                {id: insuranceModel.id},
                function success(response) {
                    if (action === 'accept') {
                        growl("Insurance successfully accepted");
                        $scope.insurance.old = response;
                        $scope.insurance.new = null;
                        $scope.creatingNew = false;
                    } else {
                        growl("Insurance successfully sent back to vendor");
                        $scope.insurance[formId] = response;
                    }
                    $scope.is_loading = false;
                }, function fail(response) {
                    console.error(response);
                    growlError("An error occurred.");
                }
            );
        };

        $scope.showNewPolicy = function() {
            var ignoredFields = [
                'id', 'valid_start', 'valid_end', 'message', 'admin_note', 'file_id', 'file',
                'err_additional_insured', 'err_incorrect_coverage', 'err_incorrect_document', 'err_incorrect_holder',
                'err_missing_cert'
            ];
            $scope.creatingNew = true;
            if (!$scope.insurance.new) {
                $scope.insurance.new = {};
            }
            for (var key in $scope.insurance.old) {
                if (ignoredFields.indexOf(key) === -1) {
                    $scope.insurance.new[key] = $scope.insurance.old[key];
                }
            }
            $scope.insurance.new.phase = 1;
        };

		$scope.validateForm = function(formId) {
			$scope.validateDate(formId);
			var insuranceForm = (formId == 'new' ? $scope.newinsuranceForm : $scope.oldinsuranceForm);
			if(formId == 'new'){
				$scope.newfileError = !$scope.insurance[formId].file_id;
			}
			else{
				$scope.oldfileError = !$scope.insurance[formId].file_id;
			}

			$scope.error = ($scope.startDateError !== false || $scope.endDateError !== false || !insuranceForm.$valid || $scope.fileError);

			return !$scope.error;


		};

		$scope.validateDate = function(formId){
			var insuranceModel = $scope.insurance[formId];
      var startDate = new Date(insuranceModel.valid_start);
      var endDate = new Date(insuranceModel.valid_end);
			$scope.startDateError = (startDate.getTime() < 0 ? 'Invalid Date' : false);
			$scope.endDateError = (endDate.getTime() < 0 ? 'Invalid Date' : false);

			if(!$scope.startDateError && !$scope.endDateError){
				$scope.endDateError = (startDate >= endDate ? 'Must be after Start Date' : false);
			}

		};


        var insurance_options = {
            data: {
                type: 'private',
                scope: 'account',
                action: 'insurance'
            },
            previousSize: 0,
            handleProgress: function(e) {
                if (!e) return;

                var progress = this.totalProgress + e.loaded;

                var size = parseInt(progress / this.totalSize * 100, 10);

                $scope.insurance_button_text = size + '% uploaded...';

                if (size >= 100) $scope.insurance_button_text = 'Finalizing...';

                if (!$scope.$$phase) $scope.$apply();
            },
            handleComplete: function(e) {
                var status = e.target.status;

                switch (status) {
                    case 200:
                        var json = $.parseJSON(e.target.response);

                        if (!json.success) {
                            growlError(json.message);
                        } else {
                            $scope.fileError = false;
                            $scope.insurance.new.file = json.data;
                            $scope.insurance.new.file_id = json.data.id;
                        }

                        break;
                    default:
                        growlError('Upload failed!');
                        break;
                }

                $scope.insurance_button_text = 'Upload';

                if (!$scope.$$phase) $scope.$apply();
            }
        };
        $scope.uploadFile = function() {
            insurance_options.data.accountID = accountId;

            $.each(arguments, function(i, file) {
                new FileUploader(file, insurance_options);
            });
        };



    }]);
