
(function($) {
	
	function ProgressBar(options) {
		var defaults = {
			total: 0,
			start: 0,
			current: 0,
		};
		
		this.options = $.extend(defaults, options);
		
		this.reset = function() {
			this.options.current = 0;
			this.options.total = 0;
			this.options.width = 0;
		};
		
		this.start = function() {
			this.addClass('active progress-striped');
			this.slideDown();
		};
		
		// sets how big the total is
		this.incrementProgressAmount = function(event, amount) {
			this.options.total += amount;
		};
		
		// update the percentage (moves the bar)
		this.updateProgress = function(event, amount) {
			this.options.current += amount;
			this.updateWidth();
		};
		
		this.updateWidth = function() {
			this.options.width = (this.options.current / this.options.total * 100).toFixed(2);
			if (this.options.width > 100) this.options.width = 100;
			
			this.find('.progress-bar').attr('aria-valuenow', this.options.width).css('width', this.options.width + '%');
			
			if (this.options.width >= 100) {

				window.setTimeout(function() {
					$(this).removeClass('active progress-striped').trigger('progressbar.done');
					this.reset();
				}.bind(this), 500);
			}
		};
		
		$(this).updateWidth = this.updateWidth.bind(this);
		
		this.on('progressbar.increment-amount', this.incrementProgressAmount.bind(this));
		this.on('progressbar.start', this.start.bind(this));
		this.on('progressbar.update', this.updateProgress.bind(this));
	}
	
	$.fn.affinityprogressbar = ProgressBar;
	
})(jQuery);