

var portalControllers = angular.module('portalControllers', [])
/*************************************
	GridCtrl

	@author Jason
	@date 4/1/14
	@returns
*/
.controller('GridCtrl', ['$scope', '$http', '$timeout', '$controller', '$location', '$routeParams', '$compile', 'USER_TYPE', function($scope, $http, $timeout, $controller, $location, $routeParams, $compile, USER_TYPE) {
	$scope.entityID = null;
	var review_html = false;

	$scope.$on('$destroy', function() {
		$('body').removeClass('fullscreen');
	});

	$scope.$watch('entityID', function(value) {

		var parts, section;
		parts = getPathPartsFromUrl($location.url());
		if (parts[0] == 'clients' || parts[0] == 'vendors') section = parts[2];
		else section = parts[0];

		value = parseInt(value, 10);
		if (!angular.isNumber(value)) return;

		function updateTemplate() {
			if (!value) return;
			$routeParams.entityID = value;

			// Step 1: parse HTML into DOM element
			var template = angular.element(review_html);

			// Step 2: compile the template
			var linkFn = $compile(template);

			// Step 3: link the compiled template with the scope.
			var element = linkFn($scope);

			$('#review-master').html(element);
		}

		if (!review_html) {
			$('<div />').load('/ajax/tpl/'+ section +'/review', function(response) {
				review_html = $(this).find('#review-master').html();

				updateTemplate();
			});
		} else {
			updateTemplate();
		}
	});

	$scope.type_of_user = USER_TYPE;

	$scope.isNumber = function (n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	};

	var $controller_element = $('[ng-controller="GridCtrl"]');

	$scope.updateScope = function() {
		$scope.is_loading = true;

		$http.get(api_url + window.location.search).success(function(data) {
			$scope.grid.setData(data);

			$scope.param_str = $scope.grid.formatParams();
		});

		$scope.getCounts();
	};

	$scope.$on('$routeUpdate',function(e) {
		$scope.updateScope();
	});

	var parts = getPathParts();
	var part;
	if ((parts.length === 3 && parts[2] === 'review') || (parts.length === 5 && parts[4] === 'review')) {
		if (parts.length === 3) part = parts[1];
		else part = parts[3];

		$scope.entityID = part;
	} else delete $scope.entityID;

	var api_url = $controller_element.attr('data-url');

	if (!api_url) {
		api_url = '/api/v1/';
		if ($routeParams.accountID) {
			api_url += parts[0] + '/' + parts[1] + '/' + parts[2];
		} else if (parts[0] == 'account' && parts[1] == 'users') {
			api_url += 'clients/' + 1 + '/users';
		} else {
			api_url += parts[0];
		}
	}

	$scope.closeModal = function(response) {
		if (response.success) {
			$scope.updateScope();
			$('.modal').modal('hide');
			$('body').removeClass('modal-open');
		}
	};

	$scope.$watch('dataObject', function() {
		$timeout(function() {
			$('.selectpicker').selectpicker('refresh');

			$scope.params = getParams();
		}, 200);
	});

	if ($routeParams.accountID) $scope.accountID = $routeParams.accountID;

	$scope.getCounts = function() {
		$http.get(api_url + '/counts').success(function(response) {
			$scope.counts = response.data;

		});
	};

	$scope.loadReviewUrl = function(url, entityID) {
		var parts, section;

		if (url) {
			parts = getPathParts();
			if (parts.length > 1 && parts[2] != 'review') url = '/' + parts[0] + '/' + parts[1] + url;
		} else {
			url = window.location.pathname;
		}

		$scope.entityID = entityID;
		$location.path(url, false);
	};

	$scope.loadReviewer = function(e, entity) {

		if (e.target.nodeName === 'A' && e.target != e.delegateTarget) return;
		$scope.loadReviewUrl(entity.url + '/review', entity.entity_id);
	};

	$scope.goToEntity = function(entity) {
		var parts = getPathParts();
		var url = entity.url + '/review' + window.location.search;
		if (USER_TYPE == 'admin' && (parts[0] == 'clients' || parts[0] == 'vendors')) {
			url = '/' + parts[0] + '/' + parts[1] + url;
		}
		$location.path(url);
	};

	$scope.setSelectedAccount = function() {
		var arr = this.getValue();
		var value = arr[0];
		if (!$scope.dataObject.where) $scope.dataObject.where = {};

		$scope.dataObject.where.account_id = { is: value };

		$scope.grid.addWhere('account_id', 'is', $scope.dataObject.where.account_id.is);
	};

	$scope.grid = {
		limits: {
			'3': '3 per page',
			'15': '15 per page',
			'25': '25 per page',
			'50': '50 per page',
			'100': '100 per page'
		},
		refresh: function() {
			var params = this.formatParams();

			$scope.param_str = params;

			$location.search(params);

			if (!$scope.$$phase) $scope.$apply();
		},
		setData: function(data) {
			$scope.dataObject = data;
			$scope.is_loading = false;
		},

		/*exportCsv: function(url) {
			url = url || window.location.pathname;

			url = this.getUrlWithParams(url + '/csv');

			window.open(url, '_blank');
			return false;
		},*/
		formatParams: function() {
			// limit check/update so we don't have a page out of range
			if ($scope.params.page && $scope.dataObject) {
				var newTotalPages = Math.ceil($scope.dataObject.total / $scope.dataObject.limit);
				if (newTotalPages <  $scope.params.page) $scope.params.page = newTotalPages;
			}

			return $.param($scope.params).replace(/\+/g, '%20');
		},
		clearParams: function(e) {
			$scope.params = {};
		},
		getParams: function() {
			return $scope.params;
		},
		getParam: function(key) {
			return $scope.params[key];
		},
		setParam: function(key, value, no_refresh) {
			$scope.params[key] = value;

			if (key !== 'page') delete $scope.params.page;

			if (!no_refresh) $scope.grid.refresh();
		},
		addSearch: function() {
			this.setParam('search', $scope.dataObject.search);
		},

		groupByStatus: function() {
			if (!$scope.params.group) $scope.params.group = {};

			if (arguments[0]) {
				$scope.params.group.status = {};

				$.each(arguments, function(i, val) {
					$scope.params.group.status[i] = val;
				});
			} else delete $scope.params.group.status;

			$scope.grid.refresh();
		},

		groupByPhase: function() {
			if (!$scope.params.group) $scope.params.group = {};

			if (arguments[0]) {
				$scope.params.group.phase = {};

				$.each(arguments, function(i, val) {
					$scope.params.group.phase[i] = val;
				});
			} else delete $scope.params.group.phase;

			$scope.grid.refresh();
			return false;
		},

		groupBy: function(key, value) {
			if (!$scope.params.group) $scope.params.group = {};

			if (arguments[1]) {
				$scope.params.group[key] = {};

				$.each(arguments, function(i, val) {
					if (i === 0) return;

					$scope.params.group[key][i - 1] = val;
				});
			} else delete $scope.params.group[key];

			$scope.grid.refresh();
		},

		groupSingular: function(key, value) {
			if (!$scope.params.group) $scope.params.group = {};


			if (arguments[1] !== null) $scope.params.group[key] = value;
			else delete $scope.params.group[key];

			$scope.grid.refresh();
		},

		addWhere: function(key, operator, value) {
			if (!$scope.params.where) $scope.params.where = {};

			if ((value === '' || value === undefined) && $scope.params.where[key]) delete $scope.params.where[key][operator];
			else if ($scope.params.where[key]) {
				$scope.params.where[key][operator] = value;
			} else {
				$scope.params.where[key] = {};
				$scope.params.where[key][operator] = value;
			}

			$scope.grid.refresh();
		},

		removeWhere: function(key, operator){
			if ($scope.params.where) {
				if ($scope.params.where[key]) {
					delete $scope.params.where[key][operator];
				}
			}
		},

		goToDate: function(date) {
			this.setParam('start', moment(date).format('YYYY-MM-DD'), true);
			this.setParam('end', moment(date).format('YYYY-MM-DD'));
		},
		changeEndDate: function() {
			this.setParam('end', $scope.dataObject.end);
		},
		changeStartDate: function() {
			this.setParam('start', $scope.dataObject.start);
		},
		changePage: function() {
			this.setParam('page', $scope.dataObject.page);
		},
		updateLimit: function() {
			this.setParam('limit', $scope.dataObject.limit);
		},
		nextPage: function() {
			$(".main-content").animate({ scrollTop: 0 }, 400);

			$scope.dataObject.page += 1;
			this.setParam('page', $scope.dataObject.page);
		},
		prevPage: function() {
			$(".main-content").animate({ scrollTop: 0 }, 400);

			$scope.dataObject.page -= 1;
			this.setParam('page', $scope.dataObject.page);
		},

		clickHeaderSort: function(e) {
			var $el = $(e.delegateTarget);
			var $th = $el.parents('th').first();
      var $i = $el.find('svg').first();

			if (!$scope.params.sort) $scope.params.sort = {};

			var key = $th.attr('data-key');

			// if no sort => asc
			if ($i.hasSVGClass('fa-sort')) {
				$i.removeSVGClass('fa-sort');
				$i.addSVGClass('fa-sort-up');
				$scope.params.sort[key] = 'asc';
				$th.addClass('sorting');
			}
			// if asc => desc
			else if ($i.hasSVGClass('fa-sort-up')) {
        $i.toggleClass('fa-sort-up fa-sort-down');
        $i.removeSVGClass('fa-sort-up');
				$i.addSVGClass('fa-sort-down');
				$scope.params.sort[key] = 'desc';
				$th.addClass('sorting');
			}
			// if desc => no sort
			else {
        $i.toggleClass('fa-sort-down fa-sort');
        $i.removeSVGClass('fa-sort-down');
				$i.addSVGClass('fa-sort');
				delete $scope.params.sort[key];
				$th.removeClass('sorting');
      }

			$scope.grid.refresh();
		},

		clickMultiSelect: function(option, checked) {

			var val = $(option).val();
			var $parent = $(option).parents('th').first();
			var key = $parent.attr('data-key');

			// add or remove all
			if (val === 'multiselect-all') {

				$(option).siblings().each(function(i, el) {
					var val = $(el).val();

					if (checked) this.addToGroup(key, val);
					else this.removeFromGroup(key, val);
				}.bind(this));

			} else {
				// if checked, add it to the group object
				if (checked) this.addToGroup(key, val);

				// else remove from group object
				else this.removeFromGroup(key, val);
			}

			$scope.grid.refresh();
		},
		addToGroup: function(key, value) {
			if (!$scope.params.group) $scope.params.group = {};

			// if doesn't exist just add it to key: { 0: value }
			if (!$scope.params.group[key]) {
				$scope.params.group[key] = { 0: value };
			} else {
				var i;
				for (i in $scope.params.group[key]) {}
				var location = parseInt(i, 10) + 1;
				$scope.params.group[key][location] = value;
			}
		},

		removeFromGroup: function(key, value) {
			if (!$scope.params.group) return;

			if (!$scope.params.group[key]) return;

			var temp = [];
			for (var i in $scope.params.group[key]) {
				if ($scope.params.group[key][i] != value) {
					temp.push($scope.params.group[key][i]);
				}
			}

			if (temp.length) {
				$scope.params.group[key] = {};
				for (i = 0; i < temp.length; i++) {
					$scope.params.group[key][i] = temp[i];
				}
			} else {
				delete $scope.params.group[key];
			}

		}
	};

	$scope.vendorAdded = function(response) {
		if (response.success) {
			$location.path('/vendors/' + response.data.id + '/settings');
			$scope.$apply();

			$('.modal').modal('hide');
			$('body').removeClass('modal-open');
		} else if (response.error) {
      growlError(response.error);
    } else {
      growlError('An error occurred.');
    }
	};

	$scope.params = getParams();
	$scope.updateScope();

}])
.controller('UploadImageCtrl', ['$scope', '$http', function($scope, $http) {

	$scope.imageUploadFile = function() {
		var form = document.getElementById('image-upload-form');
		var fd = new FormData(form);
		var url = '/api/v2/images';
		$http.post(url, fd, {
			transformRequest: angular.identity,
			headers: {'Content-Type': undefined}
		})
		.success(function() {
			$('#upload-image-modal').modal('hide');
		}).error(function() {
			console.error('It died');
		});
	};

}])
.controller('AccountsTableHeaderRowCtrl', ['$scope', function ($scope) {

}])
.controller('AccountsTableBodyRowCtrl', ['$scope', '$filter', function($scope, $filter) {

}])
.controller('LicensesTableHeaderRowCtrl', ['$scope', function ($scope) {

}])
.controller('LicensesTableBodyRowCtrl', ['$scope', '$filter', function($scope, $filter) {

	$scope.$watch('object', function() {
		$scope.object.phase = $filter('licensePhaseTd')($scope.object);

		//$scope.object.name = $filter('accountNameLink')($scope.object);
	});
}]);

export default portalControllers;

