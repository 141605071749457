import portalControllers from './_controllers';
portalControllers
.controller('FindGreekAdminOverviewCtrl',['$scope', '$http', function($scope, $http, API){

	$scope.counts = {
		'app_tags' : 0,
		'app_users' : 0,
		'app_vendors' : 0,
		'app_products' : 0,
		'app_collections' : 0
	};

	$http.get('/api/v2/findgreek/counts').then(
		function(response){
			var counts = response.data.counts;
			for(var element in counts){
				$scope.counts[element] = counts[element];
			}
		},
		function(response){
		}
	);
}])
.controller('FindGreekVendorCtrl',['$scope','$http',function($scope, $http){
	$scope.toggleEnabled = function(vendor){
		$scope.vendor = vendor;
		$http.get('/api/v2/findgreek/vendors/toggleenabled/'+vendor.id).then(
			function(response){
				response = response.data;
				$scope.vendor.findgreek_disabled_at = response.data.disabled_at;
				growl(response.data.message);
			},
			function(response){
				console.log(response);
			}
		);
	};
}])

.controller('FindGreekTagsCtrl',['$scope','$http',function($scope, $http){

	$scope.openPopup = function(tag){
		var modal = $('#tags-modal');
		angular.element(modal).scope().setTag(tag);

		modal.modal('toggle');
	};

	$scope.genderMapping = function(gender){
		if(gender == 1) return 'Male';
		if(gender == 2) return 'Female';
		if(gender == 3) return 'Neutral';

	};

}])
.controller('FindGreekTagsEditCtrl',['$scope','$http',function($scope, $http){
	$scope.clientList = false;
	$scope.tag = {'name':'', 'tags':'', 'gender':'3', 'organization_account_id':'', 'priority':'2'};
	$scope.isNew = true;
	$scope.saving = false;
	$http.get('/api/v2/clients?limit=-1&return-func=toBasic&sort%5Bname%5D=asc').then(
		function(response){
			$scope.clientList = response.data.data;
			if (!$scope.$$phase) $scope.$apply();
		},
		function(response){

		}
	);

	$scope.setTag = function(tag){
		$scope.isNew = false;
		$scope.tag.id = tag.id;
		$scope.tag.name = tag.name;
		$scope.tag.gender = tag.gender;
		$scope.tag.organization_account_id = tag.organization_account_id;
		$scope.tag.keywords = tag.keywords;
		$scope.tag.priority = tag.priority;
		if (!$scope.$$phase) $scope.$apply();
	};

	$scope.addOrUpdateTag = function(){
		if($scope.tag.name.length > 0){
			$scope.saving = true;
			$http.post('/api/v2/findgreek/tag', {'data':$scope.tag}).then(
				function(response){
					//here I need to refresh the dataObject
					resetModal(response);
				},
				function(response){

				}
			);
		}
		else{
			growlError('Tag name is required.');
		}

	};

	function resetModal(response){
		//here I need to refresh the dataObject
				//and close the modal
				$scope.saving = false;
				if($scope.updateTags){
					$scope.updateTags(response.data.data.id);
				}

				if($scope.updateScope){
					$scope.updateScope();
				}

				$('#tags-modal').modal('toggle');
	}

	$scope.deleteTag = function(){
		$scope.saving = true;
		$http.get('/api/v2/findgreek/tag/'+$scope.tag.id+'/delete').then(
			function(response){
				growl(response.data.data);
				resetModal(false);
			},
			function(response){

			}
		);
	};

	$('#tags-modal').on('hidden.bs.modal', function(){
		$scope.isNew = true;
		$scope.tag = {'name':'', 'keywords':'', 'gender':'3', 'organization_account_id':'', 'priority':'2'};
		if (!$scope.$$phase) $scope.$apply();
	});

}])
.controller('FindGreekProductCtrl',['$scope','$http', '$location', '$window', '$element', '$timeout', '$filter','$route','gatewayApi', function($scope, $http, $location, $window, $element, $timeout, $filter, $route, gatewayApi){

	var path_parts = getPathParts();
	var url_prefix ='/api/v2/';
	//to determine if the vendor id is in the url (is an admin)
	if (path_parts[0] == 'vendors') {
		url_prefix = url_prefix+'vendors/'+path_parts[1]+'/';

	}
	url_prefix = url_prefix + 'findgreek/';

	var product_id = '';

	$scope.is_loading = true;
	$scope.findgreekProduct = [];
	$scope.clientList = [];
	$scope.availableLicensedProducts = [];
	$scope.allProducts = [];
	$scope.productNamesIndexedByID = [];
	$scope.imageChanged = false;
	$scope.errors = [];
	$scope.saving = false;

	$scope.statusMapping = {2:{'text':'Approve Design','class':'btn-success','message':'This design is approved for production. You will receive a separate notification shortly to confirm whether or not this product image has been approved for inclusion on FindGreek.<br /><br />Please be advised that approval of this design is limited only to the appropriateness of the "theme" and the portion of the design that includes the marks of our client(s). Further, it is the express expectation, per the license agreement, that you have secured approval from any other intellectual property owner (if any), including trademarks and/or copyrights, prior to printing or production of any product.'},3:{'text':'Reject Design','class':'btn-danger','message':'We must reject this proposed design on behalf of our client(s). It should not be produced and/or marketed and will not be included on FindGreek.'},4:{'text':'Client Review','class':'btn-info','message':'Please review this design.<br /><br />If you do not approve or reject the design within two (2) business days, we will approve it on your behalf.'},5:{'text':'Approve FindGreek','class':'btn-success','message':'This product has been approved and is now included on FindGreek.<br /><br />As a reminder, it is the express expectation, per the license agreement, that you have secured approval from any other intellectual property owner (if any), including trademarks and/or copyrights, prior to printing or production of any product.'},6:{'text':'Reject FindGreek','class':'btn-danger','message':'We must reject this proposed product. While the design is approved for production, the image does not meet the requirements for inclusion on FindGreek.'}};

	var productsLoaded = false;
	var clientsAndProductsLoaded = false;
	var tagsLoaded = false;

	//get the product info
	function getProducts(){
		$http.get('/api/v2'+$location.path()).then(
			function(response){
				$scope.findgreekProduct = response.data.data;
				if($scope.findgreekProduct.rank === null) $scope.findgreekProduct.rank = 3;
				if($scope.findgreekProduct.client_account_id) getClientRules();
				productsLoaded = true;
				$scope.parseInputs();
			},
			function(response){}
		);
	}

	function getClientRules(){
		$http.get('/api/v2'+$location.path()+'/client/'+$scope.findgreekProduct.client_account_id).then(
			function(response){
				$scope.primary_client = response.data;
			},
			function(response){}
		);
	}

	if($location.path().indexOf('add') > -1){
		$scope.findgreekProduct.rank = 3;
		productsLoaded = true;

	}else{
		product_id = 'products/'+path_parts[path_parts.length -1]+'/';
		getProducts();
	}

    var secondaryImageUploadError = function(response) {
        $scope.imageUploadingCount -= 1 ;
        growlError('Image upload failed');
        if($scope.imageUploadingCount === 0){
            growl('Finished uploading images!');
        }

        if(this.button){
            this.button.disabled = false;
            this.button.innerHTML = this.oldText;
        }
    };

    var handleUploadUpdate = function(response){};

    $scope.showFileChooser = function(changeFunction, multiple, e) {
        var $file = $('<input type="file" '+(multiple ? 'multiple=""' : '')+' />');
        $file.change(function() {
            var element;
            if(e){
                element = e.toElement;
            }
            changeFunction(this.files, element);
        });

        $file.click();
    };

    $scope.remove = function (scope) {
        scope.remove();
    };

    $scope.uploadSecondaryImage = function(files) {
        var acceptable_formats = ['image/jpeg', 'image/gif', 'image/png', 'image/tiff'];

        function updateId(response){
            var json = $.parseJSON(response.target.response);
            this.id = json.file_id;
            $scope.imageUploadingCount -= 1 ;
            if($scope.imageUploadingCount === 0){
                growl('Finished uploading images!');
            }
            if (!$scope.$$phase) $scope.$apply();
        }
        function readerOnloadFunction(e) {
            this.urls = {};
            this.urls.or = e.target.result;
            if (!$scope.$$phase) $scope.$apply();
        }

        // loop through all files and upload them
        for(var i = 0; i < files.length; i++) {
            if (acceptable_formats.indexOf(files[i].type) < 0) {
                growlError('Cannot upload  because it is not an image');
            }
            else {
                $scope.imageUploadingCount += 1 ;
                var image = {};
                var reader = new FileReader();
                reader.onload = readerOnloadFunction.bind(image);
                reader.readAsDataURL(files[i]);
                gatewayApi.uploadFile('/findgreek/secondaryImages', files[i], updateId.bind(image), secondaryImageUploadError, handleUploadUpdate, false);
                $scope.findgreekProduct.secondary_images.push(image);
                if (!$scope.$$phase) $scope.$apply();
            }

        }
    };

	$http.get(url_prefix+product_id+'client_list/').then(
		function(response){
			$scope.clientList = $filter('orderBy')(response.data.data.clients,'name');
			$scope.allProducts = response.data.data.products;
			$scope.mappingArrays = response.data.data.clientProductsMap;
			clientsAndProductsLoaded = true;
			$scope.parseInputs();
		},
		function(response){}
	);


	function getTags(){
		$http.get('/api/v2/findgreek/data_object/Tag?limit=-1').then(
		function(response){
			$scope.tags = response.data.data;
			tagsLoaded = true;
			$scope.parseInputs();
		},
		function(response){

		});
	}

	getTags();

	$scope.updateTags = function(id){

		$scope.findgreekProduct.tags.push(String(id));
		getTags();
	};

	$scope.readyStatusUpdate = function(status){
		$scope.status = status;

		$scope.confirmButtonStyle = $scope.statusMapping[status].class;
		$scope.confirmButtonText = $scope.statusMapping[status].text;

		$scope.findgreekProduct.message = $scope.statusMapping[status].message;

		if (!$scope.$$phase) $scope.$apply();

	};

	$scope.unReadyStatusUpdate = function(status){

		delete $scope.status;

	};

	$scope.sendStatusUpdate = function(){
		var payload = verifyFormProduct();
		if(payload !== false){
			$scope.is_loading = true;
			$scope.saving = true;
			$http.post('/api/v2'+$location.path()+'/status',{'status':$scope.status, 'message':$scope.findgreekProduct.message, 'data':payload}).then(
				function(response){
					//$scope.findgreekProduct = response.data.data;
					$scope.parseResponse(response.data);
				},
				function(response){
				}
			);
			delete $scope.status;
		}

	};

	$scope.parseInputs = function(){
		if(productsLoaded === false || clientsAndProductsLoaded === false || tagsLoaded === false){
			return;
		}
		for (var i = 0, len = $scope.allProducts.length; i < len; i++) {
			$scope.productNamesIndexedByID[$scope.allProducts[i].category_id] = $scope.allProducts[i].name;
		}

		$scope.fillProductDropdown();
		$scope.is_loading = false;
		$scope.saving = false;


		if (!$scope.$$phase) $scope.$apply();
		setTimeout(function() {
			$('select.multiselect').multiselect('rebuild');
		});

		$scope.$watch(
			function(){return $scope.findgreekProduct.client_account_id;},
			function(newValue, oldValue){
				if(oldValue != newValue){
					getClientRules();
					$scope.findgreekProduct.product_category_id = "";
				}
			}
		);
	};

	$scope.fillProductDropdown = function(){
		$scope.availableLicensedProducts = [];

		var clientID = $scope.findgreekProduct.client_account_id;
		if(clientID < 1 || typeof(clientID) == 'undefined'){
			return;
		}
		var mappingArray = $scope.mappingArrays[clientID];

		for (var i = 0, len = $scope.allProducts.length; i < len; i++) {
			if(mappingArray.indexOf($scope.allProducts[i].entity_id) > -1){
				$scope.availableLicensedProducts.push($scope.allProducts[i]);
			}
		}

		$scope.availableLicensedProducts = $filter('orderBy')($scope.availableLicensedProducts, 'name');

		setTimeout(function() {
			$($element[0].querySelector('select')).change();
		});
		if (!$scope.$$phase) $scope.$apply();
	};

	$scope.saveProduct = function(){

		var payload = verifyFormProduct();

		if($scope.errors.length === 0){
			$scope.is_loading = true;
			$scope.saving = true;
			if (!$scope.$$phase) $scope.$apply();
			$http.post(url_prefix+'products', {'data':payload}).then(
				function(response){
					//$scope.findgreekProduct = response.data.data;
					$scope.parseResponse(response);

				},
				function(response){

				}
			);
			return true;
		}
		else{
			return false;
		}
	};

	function verifyFormProduct(){

		var payload = {};
		$scope.errors = [];
		if($scope.findgreekProduct.id){
			payload.id = $scope.findgreekProduct.id;
		}else{
			if(typeof($('#newImage').attr('src')) == "undefined"){
				$scope.errors.push('image');
			}
		}
		if($scope.smallCoords && $scope.imageChanged){
			$scope.errors.push('smallCoords');
			growlError('Cropped images must be at least 400x400px.');

		}

		//	Required	//
		payload.type = checkValue($scope.findgreekProduct.type, 'type');
		payload.client_account_id = checkValue($scope.findgreekProduct.client_account_id, 'client');
        payload.product_category_id = checkValue($scope.findgreekProduct.product_category_id, 'category');

		var selectedClient = $filter('filter')($scope.clientList, payload.client_account_id, 'id');
		//	Not Required	//
		payload.name = $scope.findgreekProduct.name;

		if(typeof(payload.name) === 'string' && payload.name.toLowerCase().includes(selectedClient[0].name.toLowerCase())){
			$scope.errors.push('clientName');
			growlError('Organization names are not allowed in product names.');

		}

        payload.vendor_product_identifier = $scope.findgreekProduct.vendor_product_identifier;
		payload.description = $scope.findgreekProduct.description;
		payload.rank = $scope.findgreekProduct.rank;
		payload.status = $scope.findgreekProduct.status;

        payload.eligible_for_featured = $scope.findgreekProduct.eligible_for_featured;
        payload.eligible_for_home = $scope.findgreekProduct.eligible_for_home;
        payload.eligible_for_org = $scope.findgreekProduct.eligible_for_org;
        payload.eligible_for_deals = $scope.findgreekProduct.eligible_for_deals;

        var imageIds = [];
        if($scope.findgreekProduct.secondary_images){
            $.each($scope.findgreekProduct.secondary_images, function(index, image){
                imageIds.push(image.id);
            });
        }
        payload.secondaryImages = imageIds;


		//	Special Rules	//
		if($scope.errors.indexOf('type') == -1 && $scope.findgreekProduct.type == 2){
            //only check validity of web address if product is available online
			payload.product_web_address = checkValue($scope.findgreekProduct.product_web_address, 'web_address');
		}
    else{
      payload.product_web_address = $scope.findgreekProduct.product_web_address;
    }

    payload.price = $scope.findgreekProduct.price;
    payload.sale_price = $scope.findgreekProduct.sale_price;
    payload.is_on_sale = $scope.findgreekProduct.is_on_sale;



		payload.tags = $scope.findgreekProduct.tags;

		if($scope.errors.length === 0){
			return payload;
		} else {
			growlError('Required fields are missing!');
			return false;
		}
	}

	$scope.parseResponse = function(response){
		if($scope.imageChanged){
			$scope.saveCropper(url_prefix+'products/'+response.data.id+'/image');
		}
		else{
			$scope.findgreekProduct = response.data;
			$scope.parseInputs();
		}
		$scope.imageChanged = false;
	};

	function checkValue(value, valueTitle){
		if(typeof(value) == 'undefined' || value === null || value.length <= 0){
			$scope.errors.push(valueTitle);
		}
		return value;
	}
	$scope.cancelChanges = function(){
		$scope.imageChanged = false;
		getProducts();
	};


	$scope.resetProduct = function(){
		growl('Design Submitted!');
		$scope.findgreekProduct = [];
		productsLoaded = true;
		$scope.parseInputs();
		//$route.reload();
	};

	$scope.goToNext = function(type){
		$scope.is_loading = true;
		$http.get(url_prefix+type+'/'+$scope.findgreekProduct.id+'/next').then(
			function(response){
				if(response.data.data === 0){
					$scope.is_loading = false;
					growlError('There are no '+type+'s pending review that were submitted after this '+type+'.');
				}else{
					var oldURL = $location.path();
					var newURL = oldURL.substring(0, oldURL.lastIndexOf('/')+1)+response.data.data;
					$location.path(newURL);
				}
			},
			function(response){

			}
		);
	};

	$scope.archiveProduct = function(){
		$scope.is_loading = true;
		$http.get(url_prefix+'products/'+$scope.findgreekProduct.id+'/archive').then(
			function(response){
				$scope.parseResponse(response.data);
				$scope.is_loading = false;
				var archiveStatus = response.data.data.archived_at ? 'archived' : 'un-archived';
				growl('Product now '+archiveStatus);
			},
			function(response){
				$scope.is_loading = false;
			}
		);
	};

	$scope.deleteProduct = function(){
		if(!$scope.deleting){
			$scope.deleting = true;
		}
		else{
			$http.get(url_prefix+'products/'+$scope.findgreekProduct.id+'/delete').then(
				function(response){
					growl('Product Deleted');
					var oldURL = $location.path();
					var newURL = oldURL.substring(0, oldURL.lastIndexOf('/'));
					$location.path(newURL);
				},
				function(response){
					$scope.is_loading = false;
				}
			);
		}

	};

	$scope.resurrectProduct = function(){

		$scope.is_loading = true;
		$http.get(url_prefix+'products/'+$scope.findgreekProduct.id+'/resurrect').then(
			function(response){
				growl('Product Resurrected');
				$scope.parseResponse(response.data);
				$scope.is_loading = false;
			},
			function(response){
				$scope.is_loading = false;
			}
		);

	};





}]);
