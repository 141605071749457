// package imports
import angular from 'angular';
import angularSanitize from 'angular-sanitize';
import ngRoute from 'angular-route';
import ngResource from 'angular-resource';
// import ngUITree from 'angular-ui-tree';
import ngstorage from 'ngstorage';
// require('moment');
// import 'react-dom';

import './global/shared/3-third-party/jquery.validate.min';
import 'bootstrap';

import './global/shared/2-bootstrap/13-bootstrap-select';
import './global/shared/2-bootstrap/14-bootstrap-multiselect';
import './global/portal/third-party/jquery.wheelzoom';
import './global/portal/third-party/jquery.priceformat';
// import './global/portal/third-party/croppic';
import './global/portal/third-party/jquery.Jcrop.min';
// import './global/portal/third-party/jquery.sortable';
// import './global/portal/third-party/typeahead.bundle.min';


// we do this if want to use a standard JS file
require('script-loader!./global/portal/__init.js');
require('script-loader!./global/shared/analytics.js');
require('script-loader!./global/shared/helpers.js');
require('script-loader!./global/shared/3-third-party/holder.js');
// require('script-loader!./global/shared/3-third-party/fastclick.js');
require('script-loader!./global/portal/third-party/switchery.js');

require('script-loader!./global/portal/_app.js');
require('script-loader!./global/shared/_api.js');
// require('script-loader!./global/portal/nav.js');
require('script-loader!./global/portal/utils/growler.js');
import './global/portal/utils/progressbar';
// require('script-loader!./global/portal/utils/dragndrop.js');
require('script-loader!./global/portal/utils/fileuploader.js');
require('script-loader!./global/portal/utils/searcher.js');

require('script-loader!./global/portal/third-party/medium-editor');
import './global/shared/3-third-party/magicsuggest-min';
// import './global/portal/third-party/medium-editor';
require('script-loader!./reports-charts');
require('script-loader!./global/portal/views/designs.add.view');

require('script-loader!pikaday');
require('script-loader!@fortawesome/fontawesome-pro/js/fontawesome')
require('script-loader!@fortawesome/fontawesome-pro/js/v4-shims')





// Portal folder imports
import './global/portal/angular/_angular';
import './global/portal/angular/filters';
import './global/portal/angular/controllers.review';
import './global/portal/angular/_controllers';
import './global/portal/angular/_routes';
import './global/portal/angular/services';
import './global/portal/angular/directives';
import './global/portal/angular/controllers.account';
import './global/portal/angular/controllers.activity';
import './global/portal/angular/controllers.admin';
import './global/portal/angular/controllers.applications';
import './global/portal/angular/controllers.apply';
import './global/portal/angular/controllers.auth';
import './global/portal/angular/controllers.brand_marks';
import './global/portal/angular/controllers.brand';
import './global/portal/angular/controllers.client_rules';
import './global/portal/angular/controllers.clients';
import './global/portal/angular/controllers.dashboards';
import './global/portal/angular/controllers.designs';
import './global/portal/angular/controllers.findgreek';
// import './global/portal/angular/controllers.image';
import './global/portal/angular/controllers.inquiries';
import './global/portal/angular/controllers.insurance';
import './global/portal/angular/controllers.invoices';
import './global/portal/angular/controllers.labels';
import './global/portal/angular/controllers.licenses';
import './global/portal/angular/controllers.markets';
import './global/portal/angular/controllers.notifications';
import './global/portal/angular/controllers.products';
import './global/portal/angular/controllers.reports';
import './global/portal/angular/controllers.royalty_schedules';
import './global/portal/angular/controllers.users';
import './global/portal/angular/directives.cropper';
import './global/portal/angular/directives.designs';
import './global/portal/angular/directives.findgreek';


// Shared Folder imports
import './global/shared/angular/directives';
import './global/shared/angular/filters';
import './global/shared/angular/factories';
import './global/shared/angular/api';
import './global/shared/angular/angular-ui-tree.min'; // This one fails when trying to use package
import './global/shared/angular/resources';

// import './reports-charts';
