
angular.module('portalFilters', [])
.filter('range', function() {
	return function(input, total) {
		total = parseInt(total, 10);
		for (var i=0; i<total; i++)
			input.push(i);
		return input;
	};
})
.filter('rangeAt1', function() {
	return function(input, total) {
		total = parseInt(total, 10);
		for (var i=1; i<=total; i++)
			input.push(i);
		return input;
	};
})
.filter('brandCategory', function() {
	return function(marks, category_id) {
		var new_arr = [];

		function loopCatID(catID) {
			$.each(marks, function(i, mark) {
				if (mark.category_id == catID) new_arr.push(mark);
			});
		}

		if (typeof category_id == 'object') {
			$.each(category_id, function(i, catID) {
				loopCatID(catID);
			});
		} else {
			loopCatID(category_id);
		}

		return new_arr;
	};
})
.filter('inVendorReview', function() {
	return function(clients) {
		var new_clients = [];
		$.each(clients, function(i, client) {
			if (client.phase && client.phase.id == 1) new_clients.push(client);
		});
		return new_clients;
	};
})
.filter('brandPrimary', function() {
	return function(marks, is_primary) {
		var new_arr = [];
		$.each(marks, function(i, mark) {
			if (mark.is_primary == is_primary) new_arr.push(mark);
		});
		return new_arr;
	};
})
.filter('accountStatusLabel', function() {
	return function (account) {
		var label = 'default';
		var icon = 'bullseye';
		var currentFeature = getPathParts().pop();
		if (currentFeature == 'clients' || currentFeature == 'vendors') { currentFeature = ''; } else { currentFeature = '/'+currentFeature; }

		switch(account.status.id) {
			case '1':
			case '4':				// registered
      case '12':
				label = 'info';		// pending
				break;
			case '2':
				label = 'success';	// current
				break;

			case '3':
				label = 'danger';	// cancelled
				break;

			case '5':				// illegal
				label = 'danger';
				break;

			case '6':				// out of business
			case '7':				// non producing
			case '8':				// one time
			case '10':				// declined
            case '11':				// non-issue
				label = 'warning';
				break;

		}
		// return '<span class="label label-'+ label +'">'+ status.display +'</span>';
		return '<a class="load-account" href="'+ account.url + currentFeature + '"><div style="font-weight: bold; line-height:1;" class="text-'+ label +'"><i class="fa fa-'+ icon +'"></i> &nbsp;'+ account.status.display +'</div></a>';
	};
})
.filter('userFirstPhoto', function() {
	return function(user) {
		var fullname = '';
		var title = user.title;
		var imgSrc = user.image.th.substr(0, 6) == 'holder' ? 'data-src="' + user.image.th + '"' : 'src="' + user.image.th + '"';
		var $img = $('<img class="pull-left hidden-xs" '+ imgSrc +'>');
		if (user.image.th.substr(0, 6) == 'holder') Holder.run({ images: $img[0] });
		// var currentFeature = getPathParts().pop();
		// if (currentFeature == 'clients' || currentFeature == 'vendors') { currentFeature = ''; } else { currentFeature = '/'+currentFeature; }
		if (!user.first_name && !user.last_name) fullname = user.email;
		else if (!user.first_name) fullname = user.last_name;
		else if (!user.last_name) fullname = user.first_name;
		else fullname = user.first_name + ' ' + user.last_name;

		title = title || '';
		var $element = '<a class="load-account-user" href="'+ user.account.url + user.url +'">' + $img[0].outerHTML + '<strong>' + fullname +'</strong><small>'+ title +'</small></a>';
		return $element;
	};
})
.filter('licensePhaseTd', function() {
	return function(entity) {
		return '<a href="#" onclick="loadReview(\'' + entity.url + '/review\')">'+ getLicensePhaseLabel(entity.phase) +'</a>';
	};
})
.filter('clickableDate', function() {
	return function(date) {
		return moment(date).format('MMMM Do, YYYY');
	};
})
.filter('activityGroup', function() {

	return function(items, group_array) {
		if (!items) return items;
		var new_items = [];
		$.each(items, function(i, item) {
			if (group_array.indexOf(item.group) > -1) new_items.push(item);
		});
		return new_items;
	};
})
.filter('entityPhase', function() {
	return function(phaseNumber) {
		switch (phaseNumber) {

		}
	};
})
.filter('invoiceType', function() {
	return function(invoiceType) {
		switch (parseInt(invoiceType, 10)) {
			case 1:
				return 'All';
			case 2:
				return 'Annual';
			case 3:
				return 'Monthly';
			case 4:
				return 'Application Fees';
			case 5:
				return 'Misc';
			case 6:
				return 'Late Fee';
			case 7:
				return 'One Time';
			case 8:
				return 'Quarterly Report';
			case 9:
				return 'Ads';
			case 10:
				return 'Refund';
            case 11:
                return 'Royalty Payments';
            case 12:
                return 'Labels';
            case 13:
                return 'Expedited Designs';
            case 14:
                return 'Back Royalties';
            case 15:
                return 'Interest';
            case 16:
                return 'Audit';
            case 17:
                return 'Minimum Royalty';
			default:
				return 'n/a';
		}
	};
})
.filter('paymentStatus', function() {
	return function(paymentStatus) {
		switch (parseInt(paymentStatus, 10)) {
			case 1:
				return 'Due';
			case 2:
				return 'Partially Paid';
			case 3:
				return 'Voided';
			case 4:
				return 'Waived';
			case 5:
				return 'Received Check';
			case 6:
				return 'Received EFT';
			case 7:
				return 'Failed EFT';
			case 8:
				return 'Pending EFT';
			default:
				return 'n/a';
		}
	};
})
.filter('invoiceStatus', function() {
	return function(invoiceStatus) {
		switch (parseInt(invoiceStatus, 10)) {
			case 1:
				return 'Open';
			case 2:
				return 'Closed';
			case 3:
				return 'Voided';
			case 4:
				return 'Waived';
			default:
				return 'n/a';
		}
	};
});
