import portalControllers from './_controllers';
portalControllers
    .controller('ClientRulesCtrl',['$scope', 'USER_TYPE', '$http', '$location', function($scope, USER_TYPE, $http, $location){

        $scope.isAdmin = USER_TYPE == 'admin';

        $scope.rules = [];
        $scope.filtered_rules = [];

        $scope.new_rule = {
            'product_category_id': '',
            'brand_mark_id': '',
            'distribution_channel_id': '',
            'has_exclusive_vendor': 0,
            'excluded_vendors':[]
        };

        $scope.filters = {
            'product_category': '',
            'brand_mark': ''
        };

        $scope.selected_rule = {};

        $scope.product_categories = [];
        $scope.brand_marks = [];
        $scope.distribution_channels = [];
        $scope.exclusive_vendors = [];
        $scope.vendor = [];

        $scope.account_id = $location.path().split('/')[2];

        var categories = [];
        $http.get('/api/v1/products/categories/tree').then(
            function success (response) {
                var productCategories = response.data.data;
                for(var x in productCategories){
                    parseCategoryArray(productCategories[x], '');
                }
                $scope.product_categories = categories;
                $scope.product_categories.sort(function (c1, c2) {
                    return c1.title.localeCompare(c2.title);
                });
            },
            function fail(response) {
                console.warn(response);
            }
        );

        function parseCategoryArray(category, parentNameString) {
            if (category.items) {
                if (parentNameString !== '') {
                    parentNameString = parentNameString + ', ';
                }
                parentNameString = parentNameString + category.title;
                for (var x in category.items) {
                    parseCategoryArray(category.items[x], parentNameString);
                }
            } else {
                category.parent_name = parentNameString;
                categories.push(category);
            }
        }

        $http.get('/api/brandmarks', { params: {'client_id': $scope.account_id } }).then(
            function success (response) {
                $scope.brand_marks = response.data.data;
            },
            function fail(response) {
                console.warn(response.data);
            }
        );
        $http.get('/api/distributionChannels', { params: {'client_id': $scope.account_id } }).then(
            function success (response) {
                $scope.distribution_channels = response.data.data;
            },
            function fail(response) {
                console.warn(response.data);
            }
        );
        $http.get('/api/vendors?minimal=1', {
            params: {
                'status[]': [1,2,3,4],
                order_by: 'short_name'
            }
        }).then(
            function success (response) {
                $scope.vendors_suggest.setData(response.data.data);
                $scope.new_vendors_suggest.setData(response.data.data);
            }
        );



        $scope.selectRule = function (rule) {
            $scope.selected_rule = rule;
            var vendorArray = rule.excluded_vendors.map(function (vendor) {
                return vendor.id;
            });
            if (vendorArray.length > 0) {
                $scope.vendors_suggest.setValue(vendorArray);
            } else {
                $scope.vendors_suggest.clear();
            }
            $scope.updateMaxSelectSize('vendors_suggest', $scope.selected_rule.has_exclusive_vendor);

        };

        $scope.updateMaxSelectSize = function(suggestID, hasExclusive)
        {
            var newMax = ( hasExclusive ? 1 : null);
            $scope[suggestID].setMaxSelection(newMax);
            if (!$scope.$$phase) $scope.$apply();
        };

        $scope.filterRules = function () {

            var loopFilter = function (filterName) {
                return function (rule) {
                    if (!$scope.filters[filterName]) {
                        return true;
                    }
                    if (!rule[filterName]) {
                        return false;
                    }
                    return rule[filterName].id === $scope.filters[filterName];
                };
            };

            $scope.filtered_rules = $scope.rules;
            for (var property in $scope.filters) {
                if ($scope.filters.hasOwnProperty(property)) {
                    var filterFn = loopFilter(property);
                    $scope.filtered_rules = $scope.filtered_rules.filter(filterFn);
                }
            }
        };

        $scope.getRules = function () {
            $scope.is_loading = true;
            var params = {
                'client_id': $scope.account_id
            };
            $http.get('/api/clientRules', {'params': params}).then(
                function success (response) {
                    $scope.is_loading = false;

                    $scope.rules = response.data.data;
                    $scope.rules.sort(function (r1, r2) {
                        if (r1.product_category && r2.product_category) {
                            return r1.product_category.name.localeCompare(r2.product_category.name);
                        } else {
                            return !r1.product_category ? -1 : 0;
                        }
                    });
                    $scope.exclusive_vendors = $scope.rules.filter(function(rule) {
                        return !!rule.exclusive_vendor;
                    }).map(function(rule) {
                        return rule.exclusive_vendor;
                    });
                    $scope.filterRules();
                },
                function fail (response) {
                    $scope.is_loading = false;
                    console.warn(response.data);
                }
            );
        };
        $scope.getRules();


        $scope.deleteRule = function (ruleId, modalId) {
            $http.delete('/api/clientRules/' + ruleId).then(
                function success(response) {
                    $scope.reloadPage(modalId);
                    growl(response.data.message);
                },
                function fail(response) {
                    growlError(response.data.message);
                }
            );
        };

        $scope.createRule = function (modalId) {
            $scope.new_rule.client_id = $scope.account_id;
            $scope.is_loading = true;
            $http.post('/api/clientRules', $scope.new_rule).then(
                function success(response) {
                    $scope.reloadPage(modalId);
                    growl('success!');
                },

                function fail(response) {
                    $scope.is_loading = false;
                    $scope.errors = response.data.errors;
                    for(var x in $scope.errors){
                        growlError($scope.errors[x]);
                    }
                }
            );
        };

        $scope.saveVendorList = function () {
            $scope.is_loading = true;
            var vendors = $scope.selected_rule.new_excluded_vendors;
            var has_exclusive_vendor = $scope.selected_rule.has_exclusive_vendor;

            $http.put('/api/clientRules/' + $scope.selected_rule.id + '/vendors', {has_exclusive_vendor: has_exclusive_vendor, excluded_vendors: vendors}).then(
                function success(response) {
                    $scope.reloadPage('excluded-vendors-modal');
                    growl('success!');
                },

                function fail(response) {
                    $scope.is_loading = false;
                    $scope.errors = response.data.errors;
                    for(var x in $scope.errors){
                        growlError($scope.errors[x]);
                    }
                }
            );
        };

        $scope.reloadPage = function (modalId) {
            //Close the modal
            $('#'+modalId).modal('toggle');
            //reset the selected_schedule
            $scope.selected_rule = {};
            //Refresh list
            $scope.getRules();
        };

        $scope.setSuggest = function(ms, attrs) {
            $scope[attrs.id] = ms;
        };

        $scope.setNewVendors = function(e,m,r) {
            var vendors = m.getValue();
            if (vendors) {
                $scope.new_rule.new_excluded_vendors = m.getValue();
            } else {
                $scope.new_rule.new_excluded_vendors = null;
            }
        };

        $scope.setVendors = function(e,m,r) {
            var vendors = m.getValue();
            if (vendors) {
                $scope.selected_rule.new_excluded_vendors = m.getValue();
            } else {
                $scope.selected_rule.new_excluded_vendors = null;
            }
        };
    }])
;
