import portalControllers from './_controllers';
portalControllers
.controller('AddProductModalCtrl', ['$scope', '$http', 'API', function($scope, $http, API) {

	$scope.closeModal = function(response) {
		var scope = angular.element('#step-form').scope();
		$.each(response.data, function(i, prod) {
			prod.is_checked = 1;
			scope.products.push(prod);
		});
		scope.$apply();
		$('#add-product-category').modal('hide');
	};
}])
.controller('AddProductCtrl', ['$scope', '$http', '$controller', 'API', function($scope, $http, $controller, API) {
	API.call('products/categories').success(function(response) {
		$scope.product_categories = response.data;

		organizeCategories();
	});

	$scope.categories_by_id = {};
	$scope.categories_by_parent = {};
	$scope.selected_category_options = [];

	function organizeCategories() {
		$.each($scope.product_categories, function(i, cat) {
			$scope.categories_by_id[cat.id] = cat;

			if (!$scope.categories_by_parent[cat.parent_id]) $scope.categories_by_parent[cat.parent_id] = {};
			$scope.categories_by_parent[cat.parent_id][cat.id] = cat;
		});
	}

	function addChildren(category) {
		var children = $scope.categories_by_parent[category.id];
		$scope.selected_category_options.push(category);
		if (children) {
			$.each(children, function(i, child) {
				addChildren(child);
			});
		}
	}

	$scope.addProductCategory = function(e, id) {
		var $el = $(e.delegateTarget);

		$el.html(' <i class="fa fa-spin fa-spinner"></i> Adding').toggleClass('btn-success btn-primary').prop('disabled', true);

		var parts = getPathParts();
		var api_url = '';

		if (parts[0] == 'vendors') api_url = parts[0] + '/' + parts[1] + '/products/categories/' + id;
		else if (parts[0] != 'products') api_url = 'vendors/' + $('#add-product-ctrl').attr('data-account') + '/products/categories/' + id;
		else api_url = 'products/categories/' + id;

		$.ajax({
			url:  '/api/v1/' + api_url,
			dataType: 'json',
			method: 'post',
			success: function(response) {
				if (response.success) {
					$el.html(' <i class="fa fa-check"></i> Added');
					response.data.is_checked = 1;
					if ($scope.productAdded) $scope.productAdded(response.data);
				} else {
					$el.html(' <i class="fa fa-plus"></i> Add').toggleClass('btn-success btn-primary').prop('disabled', false);
				}
			}
		});
	};

	$scope.addProductWithChildren = function(e, id) {
		$scope.selected_category = $scope.categories_by_id[id];

		addChildren($scope.selected_category);

		var scope = angular.element('#add-product-category').scope();
		$scope.selected_category = $scope.selected_category;
		$scope.selected_category_options = $scope.selected_category_options;

		$('#add-product-category').modal('show');
	};
}])
.controller('ProductCategoryTreeCtrl', ['$scope', 'API', '$http', function($scope, API, $http) {
	$scope.getProductCategories = function(){
		API.call('products/categories/tree').success(function(response) {
			$scope.product_categories = response.data;
			if (!$scope.$$phase) $scope.$apply();
		});
	};

	$scope.getProductCategories();
	$scope.addNewChild = function(parent){
		$scope.parent = parent;

		$scope.newName = "";
		$scope.isFirstChild = "0";

		$('#add-child-modal').modal('toggle');
	};

	$scope.saveNewChild = function(){
		$scope.submitted = true;
		$http.post('api/v2/product-categories', {'parent_id': $scope.parent.id, 'name':$scope.newName, 'is_first': $scope.isFirstChild}).then(
			function(response){
				$scope.submitted = false;
				$scope.getProductCategories();
				$('#add-child-modal').modal('toggle');
			},
			function(response){
				console.log(response);
			}
		);
	};
}]);
