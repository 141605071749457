import portalControllers from './_controllers';
portalControllers
.controller('AuthLoginCtrl', ['$scope', '$window', '$route', '$location', function($scope, $window, $route, $location) {
	$scope.errors = false;

	var params = getParams();
	if (params.reset) $scope.reset = true;
	if (params.confirm) $scope.confirm = true;
	if (params.errors) $scope.errors = params.errors;

	$('#login-form').validate({
		submitHandler: function(form) {
			form.submit();
		}
	});
}])
.controller('AuthResetPasswordStep1', ['$scope', '$location', function($scope, $location) {
	$scope.error_message = false;
	$scope.success_message = false;

	$scope.postSubmit = function(response) {

		if (response.success) {
			$scope.error_message = false;
			$scope.success_message = response.message;
		} else {
			$scope.error_message = response.message;
			$scope.success_message = false;
		}
		$scope.$apply();
	};
}])
.controller('AuthResetPasswordStep2', ['$scope', '$location', function($scope, $location) {
	$scope.errors = false;

	$scope.postSubmit = function(response) {

		if (response.success) {
			$scope.errors = false;
			$location.url('/login?reset=true');
		} else {
			$scope.errors = response.errors;
		}
		$scope.$apply();
	};
}])
.controller('AuthConfirm', ['$scope', '$location', function($scope, $location) {
	$scope.errors = false;

	$scope.postSubmit = function(response) {

		if (response.success) {
			$scope.errors = false;
			$location.url('/login?confirm=true');
		} else {
			$scope.errors = response.errors;
		}
		$scope.$apply();
	};
}])
.controller('AuthSignupCtrl', ['$scope', function($scope) {
	$scope.errors = false;
	$scope.success = false;

	$scope.postSubmit = function(response) {

		if (response.success) {
			$scope.errors = false;
			$scope.success = true;
		} else {
			$scope.errors = response.errors;
		}
		$scope.$apply();
	};
}]);
