import portalControllers from './_controllers';
portalControllers
    .controller('GridCtrlWithLicenseApplication', ['$scope', '$http', '$routeParams', 'LicenseApplications', '$location', '$window', 'USER_TYPE', '$controller', function ($scope, $http, $routeParams, LicenseApplications, $location, $window, USER_TYPE, $controller) {

        angular.extend(this, $controller('GridCtrl', {$scope: $scope}));

        var path_parts = getPathParts();
        var url_prefix = '';
        var products_url_prefix = '';
        //to determine if the vendor id is in the url (is an admin)
        if (path_parts[0] == 'vendors') {
            url_prefix = '/api/v2/vendors/' + path_parts[1] + '/licenses/applications/';
            $scope.link_prefix = '/vendors/' + path_parts[1] + '/';
            $scope.account_id = path_parts[1];
        } else {
            url_prefix = '/api/v2/licenses/applications/';
            $scope.link_prefix = '/';
        }
        $scope.url_prefix = url_prefix;
        $scope.currentMonth = new Date().getMonth() + 1;

        $.getJSON(url_prefix + 'license-app', function (response) {
            $scope.licenseApplication = response.data;
        });

        $scope.startRefresh = function () {
            $('#refresh-video-modal').modal('hide');
            $scope.is_loading = true;
            $location.path('/licenses/applications');
        };

    }])
    .controller('AdminBatchApprove', ['$scope', '$http', function ($scope, $http) {
        $scope.message = {
            message: ''
        };

        $scope.finished = false;

        $scope.running = 0;
        $scope.initial_count = 0;
        $scope.success_signed_count = 0;
        $scope.fail_signed_count = 0;
        $scope.time_estimate = 0;


        $http.get('/api/v1' + $scope.link_prefix + 'licenses?where%5Bphase%5D%5Bis%5D=3&limit=-1').success(function (response) {
            $scope.pendingList = response.data;
            $scope.initial_count = response.data.length;
            $scope.time_estimate = Math.ceil(response.data.length * 4 / 60);
        });


        $scope.sendBatchApprove = function () {
            /*
             Next, disable the buttons and make whatever necesary UI changes
             */
            $('#cancel-approve-button, #batch-modal-sign').hide();
            $('#batch-modal-loading').removeClass('hidden');
            $('#approval-modal-button').html('<i class="fa fa-spin fa-spinner"></i>Sending...').prop('disabled', true);
            $scope.sendVendorLicense();
            $scope.sendVendorLicense();
            $scope.sendVendorLicense();

        };

        $scope.sendVendorLicense = function () {
            if ($scope.pendingList.length === 0) {
                if ($scope.running === 0) {
                    $scope.finished = true;
                }
                return;
            }
            $scope.running += 1;
            var license = $scope.pendingList.shift();
            $http.post('/api/v2/entities/' + license.entity_id + '/approve', {
                message: license.note_to_vendor,
                is_batch_approve: 1
            }).then(
                function (response) {
                    if (response.data.success) {
                        $scope.success_signed_count += 1;
                    } else {
                        $scope.fail_signed_count += 1;
                    }
                    $scope.running -= 1;
                    $scope.time_estimate = Math.ceil($scope.pendingList.length * 4 / 60);
                    $scope.sendVendorLicense();
                },
                function (response) {
                    // console.log('fail');
                    $scope.fail_signed_count += 1;
                    $scope.running -= 1;
                    $scope.time_estimate = Math.ceil($scope.pendingList.length * 4 / 60);
                    $scope.sendVendorLicense();
                }
            );
        };

        $scope.sendBatchApproveEmail = function (sendEmail) {
            if (sendEmail) {
                var $button = $('#close-notify-button');
                $button.html('<i class="fa fa-spin fa-spinner"></i>Sending...').prop('disabled', true);
                $http.get('/api/v2/accounts/' + $scope.account_id + '/send-admin-batch-notify').success(function (response) {
                    $button.hide();
                    $('#approval-all-modal').modal('hide');
                    // $scope.is_loading = true;
                    //$scope.das.refresh(true);
                });
            }
            else {
                $('#approval-all-modal').modal('hide');
                // $scope.is_loading = true;
                //$scope.das.refresh(true);
            }
            var controllerElement = document.querySelector('#master-controller');
            var controllerScope = angular.element(controllerElement).scope();
            controllerScope.updateScope();

        };

    }])
    .controller('AdminBatchCancel', ['$scope', '$http', function ($scope, $http) {

        $scope.confirmText = '';

        $scope.sendCancel = function () {
            var $button = $('#cancel-all-button');
            $button.html('<i class="fa fa-spin fa-spinner"></i>Sending...').prop('disabled', true);
            var body = {'action': 'cancel'};
            $http.put('/api/v2/vendors/' + $scope.account_id + '/licenses', body).success(function (response) {
                $scope.confirmText = '';
                $button.hide();
                $('#cancel-all-modal').modal('hide');
                $('.modal-backdrop').remove();
                location.reload();
            });
        };
    }])
    .controller('AdminInvoiceMinimumRoyalty', ['$scope', '$http', function ($scope, $http) {
        $scope.generateInvoices = function () {
            var $button = $('#invoice-minimums-button');
            $button.html('<i class="fa fa-spin fa-spinner"></i>Sending...').prop('disabled', true);
            var $yearSelect = $('#invoice-minimums-year-select');
            $http.post('/api/v2/vendors/' + $scope.account_id + '/minimumInvoices', {
                year: $yearSelect.val()
            }).success(function (response) {
                $('#invoice-cancelled-modal').modal('hide');
                $('.modal-backdrop').remove();
                growl(response.message);
                $button.html('Yes, Create Invoice').prop('disabled', false);
            });
        };
    }])
    .controller('BatchSignCountCtrl', ['$scope', '$http', function ($scope, $http) {
        $http.get('/api/v1/account/licenses/refresh-count').success(function (response) {
            $scope.refreshCount = response.count;
        });
    }])
    .controller('BatchSignCtrl', ['$scope', '$http', function ($scope, $http) {
        $scope.signee = {
            signee_title: '',
            signee: ''
        };

        $scope.finished = false;

        $scope.royaltyButtonClicked = false;
            $scope.summaryButtonClicked = false;

        $scope.running = 0;
        $scope.initial_count = 0;
        $scope.success_signed_count = 0;
        $scope.fail_signed_count = 0;
        $scope.time_estimate = 0;

        $http.get('/api/v2/licenses/batch').success(function (response) {
            $scope.batchList = response;
            $scope.initial_count = response.length;
            $scope.time_estimate = Math.ceil(response.length * 4 / 60);
        });

        $scope.downloadButtonClicked = function (button) {
            $scope[button] = true;
        };

        $scope.sendBatchSign = function () {
            /*
             First make sure that some kind of signature and title was entered
             */
            if ($scope.signee.signee_title === '') {
                growlError('Please include your title.');
                return;
            }
            if ($scope.signee.signee === '') {
                growlError('Please include your signature.');
                return;
            }
            /*
             Next, disable the buttons and make whatever necesary UI changes
             */

            $('#cancel-approve-button, #batch-modal-sign').hide();
            $('#batch-modal-loading').removeClass('hidden');
            $('#approval-modal-button').html('<i class="fa fa-spin fa-spinner"></i>Signing...').prop('disabled', true);
            $scope.signLicense();
            $scope.signLicense();
            $scope.signLicense();
        };

        $scope.signLicense = function () {
            if ($scope.batchList.length === 0) {
                if ($scope.running === 0) {
                    $scope.finished = true;
                }

                return;
            }
            $scope.running += 1;
            var license = $scope.batchList.shift();

            $http.post('/api/v2/entities/' + license.entity_id + '/approve', $scope.signee).then(
                function (response) {
                    if (response.data.success) {
                        $scope.success_signed_count += 1;
                    } else {
                        $scope.fail_signed_count += 1;
                    }

                    $scope.running -= 1;
                    $scope.time_estimate = Math.ceil($scope.batchList.length * 4 / 60);
                    $scope.signLicense();
                },
                function (response) {
                    $scope.fail_signed_count += 1;
                    $scope.running -= 1;
                    $scope.time_estimate = Math.ceil($scope.batchList.length * 4 / 60);
                    $scope.signLicense();
                }
            );
        };

    }])

    .controller('AccountSignNowCtrl', ['$scope', '$http', function ($scope, $http) {
        $scope.sign_now = [];

        $scope.$watch('dataObject.data', function (value) {
            if (!value) return;

            $scope.refreshPending();
        });

        $scope.refreshPending = function () {
            $http.get('/api/v1/account/licenses').success(function (response) {
                $scope.sign_now = [];

                if (response.length) {
                    angular.forEach(response, function (object) {
                        $scope.sign_now.push(object);
                    });
                }
            });
        };
    }])
    .controller('AccountBatchSignBoxCtrl', ['$scope', '$http', function ($scope, $http) {
        $scope.batchCount = 0;

        $scope.getBatchSignable = function () {
            $http.get('/api/v2/licenses/batch').success(function (response) {
                $scope.batchCount = response.length;
            });
        };

        $scope.getBatchSignable();
    }])
    .controller('LicenseWithCtrl', ['$scope', '$http', function ($scope, $http) {
        $http.get('/api/v1/account/licenses/with/' + $scope.object.id).success(function (response) {
            if (response.data) {
                $scope.object.phase = response.data.phase;
                $scope.object.status = response.data.status;
                $scope.object.applied_at = response.data.created_at;
                $scope.object.entity_id = response.data.entity_id;
                $scope.object.is_refresh = response.data.is_refresh;
            } else {
                $scope.object.phase = {};
            }
        });
        $scope.addClient = function ($event) {
            var button = $event.currentTarget;
            button.disabled = true;
            button.innerHTML = '<i class="fa fa-spin fa-spinner"></i>';
            // console.log($event);

            $http.post($scope.url_prefix + 'add-client', {id: $scope.object.id}).success(function (data) {
                growl(data.message);
                $scope.licenseApplication = data.data;
                if (!$scope.$$phase) $scope.$apply();
            });
        };
    }])
    .controller('refreshProductsCtrl', ['$scope', '$http', function ($scope, $http) {
        //console.log($scope.refreshLicense);
        $http.get('/api/v2/licenses/' + $scope.batchLicense.entity_id + '/products').success(function (response) {
            //console.log(response);
            $scope.products = response.products;
            $scope.new_products = response.new_products;
            $scope.existing_products = response.existing_products;
            $scope.old_products = response.old_products;
            $scope.unapproved_products = response.unapproved_products;

        });
    }])
    /*#############################################################################################################################
     #	NEW LICENSE REVIEWER
     #############################################################################################################################*/
    .controller('LicenseReview', ['$scope', '$location', '$sce', '$routeParams', 'Licenses', 'LicenseProducts', 'EntityReview', 'USER_TYPE', '$http', '$filter', 'AccountProfile', function ($scope, $location, $sce, $routeParams, Licenses, LicenseProducts, EntityReview, USER_TYPE, $http, $filter, AccountProfile) {

        $scope.user_signature = '';
        $scope.view_product = {};
        $scope.schedules = {};
        $scope.possibleSchedules = [];
        $scope.filteredPossibleSchedules = [];
        $scope.selectedScheduleId = null;
        $scope.vendorProfile = null;
        $scope.setViewProduct = function (product) {
            $scope.view_product = product;
        };

        $scope.selectRoyaltySchedule = function(id) {
            $scope.selectedScheduleId = id;
        };

        $scope.fetchVendorProfile = function (vendorId) {
            AccountProfile.get({accountID: vendorId}, function (response) {
                $scope.vendorProfile = response;
            });
        };

        $scope.fetchRoyaltySchedules = function(){
            $http.get('/api/royaltySchedules', {params:{'license_id':$routeParams.entityID}}).then(function (response) {
                var schedules = response.data.data;

                schedules.sort(function (a, b) {
                    // Default ("Standard Royalty") schedules come before non-default schedules
                    if (a.is_default_schedule) {
                        return -1;
                    }
                    if (b.is_default_schedule) {
                        return 1;
                    }
                    return 0;
                });

                $scope.schedules = schedules;



                filterPossibleSchedules();
            });
        };

        function filterPossibleSchedules() {
            $scope.filteredPossibleSchedules = $scope.possibleSchedules.filter(function(schedule) {
                if (schedule.is_default_schedule) {
                    return false;
                }

                return $filter('filter')($scope.schedules, {id: schedule.id}).length === 0;
            });
        }

        $scope.fetchPossibleRoyaltySchedules = function(){
            var params = {
                'client_id':$scope.entity.client_account_id,
                'available_to_vendor_id':$scope.entity.vendor_account_id,
                'not_deleted':true
            };
            $http.get('/api/royaltySchedules', {params:params}).then(function (response) {
                $scope.possibleSchedules = response.data.data;
                filterPossibleSchedules();
            });
        };

        $scope.updateLicense = function () {
            var params = $location.search();
            params.licenseID = $routeParams.entityID;
            if ($routeParams.accountID) params.account_id = $routeParams.accountID;

            Licenses.get(params, function (response) {
                $scope.entity = response;
                $scope.fetchPossibleRoyaltySchedules();
                $scope.refetchTemplate();
                $scope.fetchVendorProfile($scope.entity.vendor.id);

                $scope.review = new EntityReview($scope.entity);
            });
        };

        $scope.urlToEntity = function(url) {
          var parts = getPathParts();
          var newURL = url;
          if (parts[0] != 'licenses' && USER_TYPE == 'admin') {
            newURL = '/' + parts[0] + '/' + parts[1] + url;
          }
          return newURL;
        };

        $scope.nextUrl = function() {
          return $scope.urlToEntity($scope.entity.next);
        };

        $scope.prevUrl = function() {
          return $scope.urlToEntity($scope.entity.prev);
        };

        $scope.removeSchedule = function () {
            $scope.is_loading = true;
            $http.delete('/api/v2/licenses/'+$routeParams.entityID+'/royaltySchedules/'+$scope.selectedScheduleId).then(function(response) {
                $('#remove-royalty-schedule-modal').modal('toggle');
                $scope.selectedScheduleId = null;
                $scope.refetch();
            }, function (response) {
                $scope.is_loading = false;
                growlError(response.data.message);
            });
        };

        $scope.testChange = function(test) {
            console.log(test);
        };

        $scope.addRoyaltySchedule = function () {
            $scope.is_loading = true;
            $http.post('/api/v2/licenses/'+$routeParams.entityID+'/royaltySchedules', {'schedule_id': $scope.selectedScheduleId}).then(function() {
                $('#add-royalty-schedule-modal').modal('toggle');
                $scope.selectedScheduleId = null;
                $scope.refetch();
            }, function (response) {
                $scope.is_loading = false;
                growlError(response.data.message);
            });
        };

        $scope.updateProducts = function () {
            LicenseProducts.get({licenseID: $routeParams.entityID}, function (response) {
                $scope.products = response.products;
                $scope.new_products = response.new_products;
                $scope.existing_products = response.existing_products;
                $scope.old_products = response.old_products;
                $scope.unapproved_products = response.unapproved_products;
                $scope.newly_unapproved_products = response.newly_unapproved_products;
            });
        };

        $scope.refetch = function() {
            $scope.updateProducts();
            $scope.fetchRoyaltySchedules();
            $scope.fetchPossibleRoyaltySchedules();
            $scope.refetchTemplate();
            $scope.is_loading = false;
        };

        $scope.updateLicense();
        $scope.fetchRoyaltySchedules();
        $scope.updateProducts();
        // $scope.updateActivity();

        $scope.reviewEntity = function (e, choice) {

            var data = {};
            if (USER_TYPE == 'admin') {
                data.message = $('#' + choice + '-entity-message').html().trim();

                if (!data.message || data.message.length < 10) {
                    growlError('Please add a message');
                    return;
                }

            } else if (choice == 'approve' && USER_TYPE != 'admin') {
                data.signee = $scope.user_signature;

                if (!data.signee || data.signee === '') {
                    growlError('Please sign the agreement');
                    return;
                }

                if (USER_TYPE == 'client') data.signee_title = $scope.entity.client_user_title;
                else data.signee_title = $scope.entity.vendor_user_title;

                if (!data.signee_title || data.signee_title === '') {
                    growlError('Please add your title');
                    return;
                }
            } else if (choice == 'reject' && USER_TYPE == 'client') {
                data.message = $('#reject-entity-message').val().trim();

                if (!data.message || data.message.length < 20) {
                    growlError('Please add your reason for rejection');
                    return;
                }
            }

            disableButton(e.delegateTarget);

            $scope.review[choice](data, function (response) {
                $scope.updateLicense();
                // $scope.updateActivity();

                undisableButton(e.delegateTarget);

                hideModals();
            });
        };

        $scope.setPhase = function (e, phase) {
            if (USER_TYPE != 'admin') return;

            disableButton(e.delegateTarget);

            var data = {
                phase: phase
            };

            $scope.review.setPhase(data, function (response) {
                $scope.updateLicense();
                // $scope.updateActivity();

                undisableButton(e.delegateTarget);

                hideModals();
            });
        };

        $scope.saveLicense = function (e) {
            var $button = $(e.delegateTarget);

            $button.data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i>Saving...').prop('disabled', true);

            $scope.entity.$save(function () {
                $scope.refetchTemplate();

                $button.html($button.data('old')).prop('disabled', false);

                growl('License application saved!');
            });
        };

        $scope.markAll = function (e) {
            var option = $scope.selected_mass_option;

            if (!option.key) return;

            var data = {
                action: option.key,
                value: option.value
            };

            if (option.textarea) {
                data.hold_description = $(option.textarea).val();
            }

            var $button = $(e.delegateTarget);

            $button.data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i>Processing...').prop('disabled', true);

            $.ajax({
                type: 'post',
                url: '/api/v2/licenses/' + $routeParams.entityID + '/mark',
                dataType: 'json',
                data: data,
                success: function (response) {
                    growlit(response);

                    $scope.updateLicense();

                    hideModals();
                },
                complete: function (response) {
                    $button.html($button.data('old')).prop('disabled', false);
                }
            });
        };

        $scope.mass_options = {
            payment_approved: {
                header: 'Payment Approved',
                text: 'Mark all pending licenses as payment approved?',
                button: 'Yes, mark as approved',
                key: 'payment_approved',
                value: '1'
            },
            payment_not: {
                header: 'Payment Not Approved',
                text: 'Mark all pending licenses as payment <strong>not</strong> approved?',
                button: 'Yes, mark as not approved',
                danger: true,
                key: 'payment_approved',
                value: '0'
            },
            products_approved: {
                header: 'Products Approved',
                text: 'Mark all pending licenses as products approved?',
                button: 'Yes, mark as approved',
                key: 'products_approved',
                value: '1'
            },
            products_not: {
                header: 'Products Not Approved',
                text: 'Mark all pending licenses as products <strong>not</strong> approved?',
                button: 'Yes, mark as not approved',
                danger: true,
                key: 'products_approved',
                value: '0'
            },
            no_hold: {
                header: 'Remove Hold',
                text: 'Remove hold on all pending licenses?',
                button: 'Yes, remove hold',
                key: 'on_hold',
                value: '0',
                textarea: '#all-on-hold-message'
            },
            put_on_hold: {
                header: 'Put on Hold',
                text: 'Put a hold on all pending licenses?',
                button: 'Yes, put on hold',
                key: 'on_hold',
                value: '1',
                textarea: '#all-on-hold-message'
            }
        };

        $scope.selected_mass_option = {};

        $scope.selectMassOption = function (option, textarea_id) {
            $scope.selected_mass_option = $scope.mass_options[option];
            $scope.selected_mass_option.textarea_id = textarea_id;
        };

        $scope.deleteLicense = function(e) {
            var params = $location.search();
            disableButton(e.delegateTarget);
            hideModals();
            params.licenseID = $routeParams.entityID;
            Licenses.delete({licenseID: params.licenseID}, function (response) {
                undisableButton(e.delegateTarget);
                window.location = '/licenses';
            });
        };

        $scope.updateUseArchivedProductsFlag = function () {
            $scope.entity.$save(function () {
                $scope.refetchTemplate();

                growl('License application saved!');
            });
        };


        $scope.product_options = {
            remove: {
                header: 'Remove Product',
                text: 'Please choose whether to <strong>remove</strong> from this license application or all pending license applications.',
                danger: true,
                icon: 'fa-times',
                color: 'danger',
                action: 'remove_product'
            },
            strike: {
                header: 'Strike Product',
                text: 'Please choose whether to <strong>strike</strong> from this license application or all pending license applications.',
                icon: 'fa-ban',
                color: 'warning',
                action: 'strike_product'
            },
            add: {
                header: 'Add Product',
                text: 'Please choose whether to <strong>add</strong> to this license application or all pending license applications.',
                icon: 'fa-plus',
                color: 'info',
                action: 'add_product'
            },
            add_category: {
                header: 'Add Product',
                text: 'Please choose whether to <strong>add</strong> to this license application or all pending license applications.',
                icon: 'fa-plus',
                color: 'primary',
                action: 'add_product_category'
            }
        };
        $scope.product_option = {};
        $scope.selectProductOption = function (option, product_id) {
            $scope.product_option = $scope.product_options[option];
            $scope.product_option.product_id = product_id;

        };

        //categories
        $scope.categories = [];

        $http.get('/api/v1/products/categories/tree').then(
            function (response) {
                var productCategories = response.data.data;
                for(var x in productCategories){
                    parseCategoryArray(productCategories[x], '');
                }
                if ($scope.product_category_suggest) {

                  $scope.product_category_suggest.setData($scope.categories);
                }
            }
        );

        function parseCategoryArray(category, parentNameString) {
            if (category.items) {
                if (parentNameString !== '') {
                    parentNameString = parentNameString + ', ';
                }
                parentNameString = parentNameString + category.title;
                for (var x in category.items) {
                    parseCategoryArray(category.items[x], parentNameString);
                }
            } else {
                category.parent_name = parentNameString;
                $scope.categories.push(category);
            }
        }

        $scope.setSuggest = function(ms, attrs) {
            $scope[attrs.id] = ms;
        };

        function sendProductCall(data, success, complete) {
            $.ajax({
                url: '/api/v2/licenses/' + $routeParams.entityID + '/products',
                type: 'post',
                data: data,
                dataType: 'json',
                success: success,
                complete: complete
            });
        }

        $scope.productCall = function (e, for_all) {

            var $button = $(e.delegateTarget);
            $button.data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i>Processing...').prop('disabled', true);

            var data = {
                action: $scope.product_option.action,
                product_id: $scope.product_option.product_id,
                category_id: $scope.product_option.category_id,
                for_all: for_all
            };

            sendProductCall(data, function (response) {
                growlit(response);

                $scope.refetch();

                hideModals();
            }, function (response) {
                $button.html($button.data('old')).prop('disabled', false);
            });
        };

        $scope.reValidateProductRules = function() {
            var success = function(response){
                growlit(response);

                $scope.refetch();
                hideModals();
            };

            var complete = function(response) {};
            $.ajax({
                url: '/api/v2/licenses/' + $routeParams.entityID + '/products/revalidate',
                type: 'post',
                data: {},
                success: success,
                complete: complete
            });
        };

        $scope.addNewProduct = function (e, magic, items) {
            if (items[0]) {
                $scope.product_option.category_id = items[0].id;
            }
        };

        var count = 0;

        $scope.updateLicenseType = function (e) {
            if ($scope.entity.license_type === '') return growlError('Cannot choose this option');

            $scope.entity.$save(function () {
                growl('License application updated!');

                $('#license-template-textarea').trigger('update-template');

                $scope.refetchTemplate();
            });
        };

        $scope.refetchTemplate = function () {
            $scope.iframe_url = '/api/v2' + $sce.trustAsResourceUrl($scope.entity.url) + '/pdf?view=true&rand=' + (count++);
        };

        $scope.admin_set_phase = '';
        $scope.admin_set_title = '';

        $scope.setAdminOption = function (phase, title) {
            $scope.admin_set_phase = phase;
            $scope.admin_set_title = title;
        };
    }]);
