import portalControllers from './_controllers';
portalControllers
.controller('UsersIndexCtrl', ['$scope', '$routeParams', 'Users', 'das', function($scope, $routeParams, Users, das) {
	new das($scope, Users);

	$scope.onlyAddUser = function(e) {
		if ($('#add-user-form').valid()) {
			$('#send-invite-input').val(0);

			$('#add-user-form').submit();
		}
	};

	$scope.inviteAddUser = function(e) {
		if ($('#add-user-form').valid()) {
			$('#send-invite-input').val(1);

			$('#add-user-form').submit();
		}
	};
}])
.controller('UserEdit', ['$scope', '$routeParams', '$location', '$timeout', 'API', 'Users', function($scope, $routeParams, $location, $timeout, API, Users) {
	var parts = getPathParts();

	$scope.scopes = {};

	$scope.hash = window.location.hash;

	if (parts[0] == 'profile') {

		Users.profile(function(response) {
			$scope.user = response;

			$scope.user.$scopes(function(response) {
				$scope.scopes = response;
			});
		});

	} else {
		Users.get({ userID: $routeParams.userID }, function(response) {
			$scope.user = response;
		});

		Users.scopes({ userID: $routeParams.userID }, function(response) {
			$scope.scopes = response;
		});
	}

	$scope.updateScope = function(response) {
		hideModals();

		if (response.success) {
			$scope.user = response.data;
		}

		if (!$scope.$$phase) $scope.$apply();
	};

	$scope.userDeleted = function(response) {
		var parts = getPathParts();
		var url = '';
		for(var i = 0; i < parts.length - 1; i++) {
			url += '/' + parts[i];
		}

		$location.path(url);
		$scope.$apply();

		hideModals();
	};

    $scope.api_key = '';
    $scope.webhook_key = '';
	$scope.getApiKey = function() {
        $.getJSON(window.location.pathname + '/api_key', {}, function(response) {
            $scope.$apply(function() {
                $scope.api_key = response.api_key;
                $scope.webhook_key = response.webhook_key;
			});
        });
	};

	$scope.generateApiKey = function() {

		if ($scope.api_key !== '') {
			var confirmed = confirm('Generating a new API key will invalidate the existing key. Are you sure?');
            if (!confirmed) {
                return;
            }
		}
		$.post(window.location.pathname + '/api_key/generate', {}, function(response) {
            $scope.$apply(function() {
                $scope.api_key = response.api_key;
                $scope.webhook_key = response.webhook_key;
            });
		}, 'json');
	};

	$scope.updateWebhookUrls = function() {
		var formItems = $('#webhook_form').serialize();
		$.post(window.location.pathname + '/webhooks', formItems, function(response) {
			growl('Updated webhook URLs');
		});
	};

	$scope.passwordChanged = function(response) {
		if (response.success) $('#change-password-modal').modal('hide');
	};

	$scope.resendVerification = function(e) {
		var $button = $(e.delegateTarget);
		var prev_text = $button.html();

		$button.prop('disabled', true).html('Sending email...');
		$.getJSON(window.location.pathname + '/resend/verification', {}, function(response) {
			if (response.success) growl(response.message, 'user');
			else growlError(response.message, 'user');

			$button.prop('disabled', false).html('Email sent!');

			$timeout(function() {
				$button.html(prev_text);
			}, 3000);
		});
	};

	$scope.resendInvitation = function(e) {
		var $button = $(e.delegateTarget);
		var prev_text = $button.html();

		$button.prop('disabled', true).html('Sending invite...');
		$.getJSON(window.location.pathname + '/resend/invitation', {}, function(response) {
			if (response.success) growl(response.message, 'user');
			else growlError(response.message, 'user');

			$button.prop('disabled', false).html('Invite sent!');

			$timeout(function() {
				$button.html(prev_text);
			}, 3000);
		});
	};

	$scope.sendResetPassword = function(e) {
		var $button = $(e.delegateTarget);
		var prev_text = $button.html();

		$button.prop('disabled', true).html('Sending reset email...');
		$.ajax({
			url: '/password/remind',
			type: 'post',
			data: {
				email: $scope.user.email
			},
			dataType: 'json',
			success: function(response) {
				if (response.success) growl(response.message, 'user');
				else growlError(response.message, 'user');

				$button.prop('disabled', false).html('Email sent!');

				$timeout(function() {
					$button.html(prev_text);
				}, 3000);
			}
		});
	};

}]);
