var Review = {
	showReviewerLoading: function() {
		$('#review .loading-div').removeClass('hide');
		$('#review .review-container').addClass('hide');
		this.showReviewer();
	},

	showReviewerContainer: function() {
		$('#review .loading-div').addClass('hide');
		$('#review .review-container').removeClass('hide');
		this.showReviewer();
	},

	showReviewer: function() {
		$('#review').show();
		$('body').addClass('fullscreen');
	},

};


var reviewControllers = angular.module('reviewControllers', [])
/*************************************
	ReviewCtrl

	@author Jason
	@date 4/1/14
	@returns
*/
.controller('ReviewCtrl', ['$routeParams', '$scope', '$compile', '$http', 'USER_TYPE', function($routeParams, $scope, $compile, $http, USER_TYPE) {
	$scope.item = {};
	$scope.admin_option = {};

	$scope.$watch('dataObject', function(value) {
		if (!value) return;

		$.each($scope.dataObject.data, function(i, object) {
			if (object.entity_id == $routeParams.entityID) {
				if ($scope.dataObject.data[i + 1]) {
					$scope.dataObject.next = $scope.dataObject.data[i + 1];
				}

				if ($scope.dataObject.data[i - 1]) {
					$scope.dataObject.prev = $scope.dataObject.data[i - 1];
				}
			}
		});
	});

	$scope.entityID = $routeParams.entityID;

	Review.showReviewerLoading();

	$scope.setAdminOption = function(id, title) {
		$scope.admin_option = {
			id: id,
			title: title
		};
	};

	$scope.showBox = function(id) {
		$(id).show();

		$('.hide-when-deciding').hide();
	};

	$scope.hideBox = function(e, box_to_hide) {
		if (e) $(e.target).parents('.review-right-sign').first().hide();
		else $(box_to_hide).hide();

		$('.hide-when-deciding').show();
	};

	$scope.showActivityBox = function(e) {
		$('#send-activity-box').show();
	};

	$scope.hideActivityBox = function(e) {
		if ($(e.target).val()) return;

		$('#send-activity-box').hide();
	};

}])
.controller('ProductReview', ['$scope', '$routeParams', '$timeout', 'API', 'Products', 'EntityReview', 'USER_TYPE', function($scope, $routeParams, $timeout, API, Products, EntityReview, USER_TYPE) {
	if (!$routeParams.entityID) return;

	$scope.reviewEntity = function(e, choice) {
		if (USER_TYPE != 'admin') return;

		var data = {
			message: $('#' + choice + '-entity-message').html().trim()
		};

		disableButton(e.delegateTarget);

		$scope.review[choice](data, function(response) {
			$scope.getProduct();

			undisableButton(e.delegateTarget);

			$scope.hideBox(e, '#' + choice + '-entity-box');
		});
	};

	$scope.setPhase = function(e, phase) {
		if (USER_TYPE != 'admin') return;

		disableButton(e.delegateTarget);

		var data = {
			phase: phase
		};

		$scope.review.setPhase(
			data,
			function(response) {
				$scope.getProduct();

				undisableButton(e.delegateTarget);

				$scope.hideBox(e, '#move-entity-box');
			}	,
			function(response) {
					console.error(response);
			});
	};

	$scope.addProductCategory = function(e,m){
		var val = this.getValue().pop();

		if (val != $scope.entity.product_category_id) {
			$scope.entity.product_category_id = val;
			$scope.ProductForm.$setDirty();
		}

		$scope.$apply();
	};

	$scope.watchProductCategories = function(ms, attrs) {

		$scope.$watch(attrs.alSuggest, function(value) {
			var url = $scope.$eval(attrs.url);

			if (value) {
				$.getJSON(url, { only: value }, function(response) {
					ms.addToSelection(response.data);
				});
			}
		});
	};

	$scope.updateValues = function(response) {
		if (response.success) {
			angular.element('#product-form').scope().ProductForm.$setPristine();
			$scope.$apply();
		}
	};

	$scope.uploading_image = false;

	$scope.uploadImage = function(file) {
		var progress_options = {
			previousSize: 0,
			url: '/products/' + $scope.entity.entity_id + '/image',
			handleProgress: function(e) {
				if (!e) return;

				$scope.uploading_image = true;

				var chunksize = e.loaded - this.previousSize;
				this.previousSize = e.loaded;
				if (!$scope.$$phase) $scope.$apply();
			},
			handleComplete: function(e) {
				var status = e.target.status;

				switch (status) {
					case 200:
						var json = $.parseJSON(e.target.response);
						$scope.entity.image_id = json.data.id;
						$scope.entity.image = json.data.url;
						break;
					default:
						break;
				}

				$scope.uploading_image = false;
				$scope.$apply();
			}
		};

		new FileUploader(file, progress_options);
	};

	$scope.getProduct = function() {
		Products.get({ productID: $routeParams.entityID }, function(response) {
			$scope.entity = response;
			$scope.review = new EntityReview($scope.entity);
		});
	};

	$scope.getProduct();
}]);
