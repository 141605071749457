import portalControllers from './_controllers';

portalControllers
.controller('MarketIndexCtrl', ['$scope', 'Markets', 'das', function($scope, Markets, das) {
	new das($scope, Markets);
}])
.controller('MarketCtrl', ['$scope', '$routeParams', 'Markets', function($scope, $routeParams, Markets) {
	$scope.market = Markets.get({ marketID: $routeParams.marketID });
}])
.controller('MarketBlogIndex', ['$scope', '$routeParams', 'Markets', 'MarketBlogEntries', 'das', function($scope, $routeParams, Markets, MarketBlogEntries, das) {
	new das($scope, MarketBlogEntries);

	$scope.market = Markets.get({ marketID: $routeParams.marketID });
}])
.controller('MarketBlogEntry', ['$scope', '$routeParams', '$location', 'Markets', 'MarketBlogEntries', function($scope, $routeParams, $location, Markets, MarketBlogEntries) {

	MarketBlogEntries.get({ entryID: $routeParams.entryID }, function(response) {
		$scope.entry = response;
	});

	$scope.goToEntry = function(response) {
		if (response.success) {
			$location.url(response.data.url);
			$scope.$apply();
		}
	};

	$scope.updateCropperImage = function(mediaitem) {
		$scope.entry.image_id = mediaitem.id;
		$scope.entry.image = mediaitem.url;
		$scope.$apply();
	};

	var is_add = getPathParts().pop() == 'add';
}]);
