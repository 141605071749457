import portalApp from './_angular';
portalApp
.directive('alDesignsProductSelector', [function() {

	return function(scope, element, attrs, ngModel) {

		element.multiselect({
			enableFiltering: true,
			enableCaseInsensitiveFiltering: true,
			// buttonClass: 'full-buttons',
			nonSelectedText: '<i class="fa fa-caret-down"></i> Select Product <small class="btn-full-tag">Product</small>',
			buttonText: function(options) {
				if (options.length === 0) {
					return '<i class="fa fa-caret-down"></i> Select Product <small class="btn-full-tag">Product</small>';
				} else if (options.length > 3) {
					return '<i class="fa fa-caret-down"></i> ' + options.length + ' selected <small class="btn-full-tag">Product</small>';
				} else {
					var selected = [];
					options.each(function() {
						selected.push([$(this).text(), $(this).data('order')]);
					});

					selected.sort(function(a, b) {
						return a[1] - b[1];
					});

					var text = '';

					for (var i = 0; i < selected.length; i++) {
						text += selected[i][0] + ', ';
					}

					return '<i class="fa fa-caret-down"></i> ' + text.substr(0, text.length -2) + '<small class="btn-full-tag">Product</small>';
				}
			},
			templates: {
				filter: '<div class="input-group"><input class="form-control multiselect-search" type="text" placeholder="Search products..."></div>'
			}
		});
	};
}])
.directive('alDesignsPrimaryClient', [function() {

	return function(scope, element, attrs, ngModel) {
		element.multiselect({
			enableFiltering: true,
			enableCaseInsensitiveFiltering: true,
			// buttonClass: 'full-buttons',
			nonSelectedText: '<i class="fa fa-caret-down"></i> Select Client <small class="btn-full-tag">Primary Client</small>',
			buttonText: function(options) {
				if (options.length === 0) {
					return '<i class="fa fa-caret-down"></i> Select Client <small class="btn-full-tag">Primary Client</small>';
				} else if (options.length > 3) {
					return '<i class="fa fa-caret-down"></i> ' + options.length + ' selected <small class="btn-full-tag">Primary Client</small>';
				} else {
					var selected = [];
					options.each(function() {
						selected.push([$(this).text(), $(this).data('order')]);
					});

					selected.sort(function(a, b) {
						return a[1] - b[1];
					});

					var text = '';

					for (var i = 0; i < selected.length; i++) {
						text += selected[i][0] + ', ';
					}

					return '<i class="fa fa-caret-down"></i> ' + text.substr(0, text.length -2) + '<small class="btn-full-tag">Primary Client</small>';
				}
			},
			templates: {
				filter: '<div class="input-group"><input class="form-control multiselect-search" type="text" placeholder="Search clients..."></div>'
			}
		});
	};
}])
.directive('alDesignsSecondaryClients', [function() {

	return function(scope, element, attrs, ngModel) {
		element.multiselect({
			enableFiltering: true,
			enableCaseInsensitiveFiltering: true,
			// buttonClass: 'full-buttons',
			nonSelectedText: '<i class="fa fa-caret-down"></i> Select Clients <small class="btn-full-tag">Secondary Clients</small>',
			buttonText: function(options) {
				if (options.length === 0) {
					return '<i class="fa fa-caret-down"></i> Select Clients <small class="btn-full-tag">Secondary Clients</small>';
				} else if (options.length > 1) {
					return '<i class="fa fa-caret-down"></i> ' + options.length + ' selected <small class="btn-full-tag">Secondary Clients</small>';
				} else {
					var selected = [];
					options.each(function() {
						selected.push([$(this).text(), $(this).data('order')]);
					});

					selected.sort(function(a, b) {
						return a[1] - b[1];
					});

					var text = '';

					for (var i = 0; i < selected.length; i++) {
						text += selected[i][0] + ', ';
					}

					return '<i class="fa fa-caret-down"></i> ' + text.substr(0, text.length -2) + '<small class="btn-full-tag">Secondary Clients</small>';
				}
			},
			templates: {
				filter: '<div class="input-group"><input class="form-control multiselect-search" type="text" placeholder="Search clients..."></div>'
			}
		});
	};
}]);
