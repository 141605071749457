import portalControllers from './_controllers';
portalControllers
.controller('BrandAdminCtrl', ['$scope', 'BrandMarks', 'das', function($scope, BrandMarks, das) {
	new das($scope, BrandMarks);

	$scope.categories = BrandMarks.categories();
}])
.controller('BrandClientCtrl', ['$scope', '$routeParams', 'BrandMarks', 'das', function($scope, $routeParams, BrandMarks, das) {
	//###################
	// constants
	$scope.showing = {
		images: true,
		styles: true,
		text: true
	};

	$scope.editing_item = {};
	$scope.needs_cover_image = false;

	// eo constants
	//###################

	new das($scope, BrandMarks);

	$scope.$watch('das.data', function(value) {
		$scope.brand_marks = value;
	});

	$scope.categories = BrandMarks.categories();

	//##############################################
	// ngClicks
	$scope.editItem = function(item) {
		angular.copy(item, $scope.editing_item);

		// find category to set as selected category
		$.each($scope.categories, function(i, cat) {
			if (cat.id == item.category_id) $scope.selected_category = cat;
		});

		var file = $scope.editing_item.file;
		if (file && file.content_type && file.content_type.match(/image\/(jpeg|gif|jpg|png|bmp)/)) {
			$scope.needs_cover_image = false;
		} else {
			$scope.needs_cover_image = true;
		}

		$('#brand-mark-modal').modal('show');
	};

	$scope.setCategory = function(categoryID) {
		$.each($scope.categories, function(i, cat) {
			if (cat.id == categoryID) $scope.selected_category = cat;
		});

		if (!$scope.editing_item) $scope.editing_item = {};
		if (!$scope.editing_item.category_id) {
			$scope.editing_item.category_id = $scope.selected_category.id;
		}
	};

	$scope.delete_mark = {};
	$scope.setMarkToDelete = function(mark) {
		$scope.delete_mark = mark;
	};
	$scope.deleteMark = function(e) {
		disableButton(e.delegateTarget);

		BrandMarks.remove({ markID: $scope.delete_mark.id }, function(response) {
			$scope.das.refresh(true);

			undisableButton(e.delegateTarget);
			growl('Mark successfully deleted');
			hideModals();

		}, function(response) {
			undisableButton(e.delegateTarget);
			growl('Error deleting brand mark');
		});
	};

	// eo ngClicks
	//#############################################

	$('#brand-mark-modal').on('hide.bs.modal', function(e) {
		$scope.selected_category = {};
		$scope.editing_item = {};
	});


	$scope.getEnding = function(file_name) {
		if (!file_name) return '';

		return file_name.split('.').pop();
	};

	$scope.getFileIcon = function(file) {
		if (!file) return 'fa-unlink';

		var ending = $scope.getEnding(file.file_name);
		switch (ending) {
			case 'zip':
				return 'fa-file-zip';
			case 'pdf':
				return 'fa-file-pdf';
			default:
				return 'fa-file-image';
		}
	};

	$scope.preview_uploading = false;
	$scope.preview_uploaded = -1.0;
	$scope.preview_media = {};

	$scope.uploading = false;
	$scope.uploaded = -1.0;
	$scope.media = {};

	$scope.$watch('editing_item.value.hex', function(value) {
		if (!value) return;

		if (!$scope.editing_item.value.rgb) {
			$scope.editing_item.value.rgb = { r: '', g: '', b: ''};
		}

		var rgb = hexToRgb(value);
		if (rgb) {
			$scope.editing_item.value.rgb = rgb;
		} else {
			$scope.editing_item.value.rgb = { r: '', g: '', b: ''};
		}
	});



	$scope.dropFile = function(file) {
		if (!file.type.match(/image\/(jpeg|gif|jpg|png|bmp)/)) {
			$scope.needs_cover_image = true;
		} else {
			$scope.needs_cover_image = false;
		}

		new FileUploader(file, {
			data: {
				scope: 'brand',
				action: 'asset',
				accountID: $routeParams.accountID
			},
			handleProgress: function(e) {
				$scope.media = {};
				$scope.uploading = true;
				if (!e) return;
				var progress = this.totalProgress + e.loaded;
				$scope.uploaded = parseInt(progress / this.totalSize * 100, 10);
				$scope.$apply();
			},
			handleComplete: function(response) {
				var json = $.parseJSON(response.target.response);
				$('#upload-file-input').val(json.data.id);
				$scope.uploaded = -1;
				$scope.uploading = false;
				$scope.media = json.data;
				$scope.editing_item.file_id = json.data.id;
				$scope.$apply();
			}
		});
	};

	$scope.dropPreviewFile = function(file) {
		var acceptable_formats = ['image/jpeg', 'image/gif', 'image/png', 'image/tiff'];

		if (acceptable_formats.indexOf(file.type) < 0) {
			growlError('Cannot upload preview because it is not an image');
			return;
		}

		new FileUploader(file, {
			data: {
				scope: 'brand',
				action: 'preview',
				accountID: $routeParams.accountID
			},
			handleProgress: function(e) {
				$scope.preview_media = {};
				$scope.preview_uploading = true;
				if (!e) return;
				var progress = this.totalProgress + e.loaded;
				$scope.preview_uploaded = parseInt(progress / this.totalSize * 100, 10);
				$scope.$apply();
			},
			handleComplete: function(response) {
				var json = $.parseJSON(response.target.response);
				$('#upload-preview-file-input').val(json.data.id);
				$scope.preview_uploaded = -1;
				$scope.preview_uploading = false;
				$scope.preview_media = json.data;
				$scope.editing_item.preview_file_id = json.data.id;
				$scope.$apply();
			}
		});
	};

	$scope.saveMark = function(e) {
		var mark = new BrandMarks($scope.editing_item);
		disableButton(e.delegateTarget);

		mark.$save(function(response) {
			undisableButton(e.delegateTarget);

			// loop through the brand marks and find it
			$.each($scope.brand_marks, function(i, mark) {
				// if found update it
				if (mark.id == response.id) {
					$scope.brand_marks[i] = response;
				}
			});

			hideModals();
		});
	};

	$scope.createMark = function(e) {
		$scope.editing_item.account_id = $routeParams.accountID;

		var mark = new BrandMarks($scope.editing_item);
		disableButton(e.delegateTarget);

		mark.$save(function(response) {
			undisableButton(e.delegateTarget);

			$scope.brand_marks.push(response);

			hideModals();
		});
	};

	$scope.closeModal = function(response) {
		// if editing the item then refresh that item
		if ($scope.editing_item && $scope.editing_item.id) {

		} else {
			// push the brand mark onto the current items

		}

		$scope.updateCategoryIDs();
		$scope.$apply();
		$('#brand-mark-modal').modal('hide');
	};
}])
.controller('BrandImageCtrl', ['$scope', '$timeout', function($scope, $timeout) {
	$scope.is_banner = false;
	$scope.low_res = false;

	$.each($scope.categories, function(i, cat) {
		if (cat.id == $scope.mark.category_id) $scope.mark.category = cat;
	});

	$timeout(function() {
		var $img = $('#mark-' + $scope.mark.id + ' .img-responsive');
		$img.load(function() {
			var width = this.naturalWidth;
			var height = this.naturalHeight;
			if (width > (height * 2)) $scope.is_banner = true;

			// 400 x 400 or something like that
			if (width * height < 160000) $scope.low_res = true;

			if (!$scope.$$phase) $scope.$apply();
		});
	});
}])
.controller('BrandFontCtrl', ['$scope', function($scope) {

	$scope.getFontUrl = function(mark) {
		var url = '/ajax/font-frame?font=' + encodeURIComponent(mark.value);

		if (mark.description) {
			url += '&text=' + encodeURIComponent(mark.description);
		}

		return url;
	};

	$scope.font_url = $scope.getFontUrl($scope.mark);
}]);
