import portalControllers from './_controllers';
portalControllers
.controller('NewHologramOrderCtrl',['$scope', '$http', '$filter', 'USER_TYPE', '$location', 'API', function($scope, $http, $filter, USER_TYPE, $location, API){
	var path_parts = getPathParts();
	var accountID = '';
	var baseURL = '/api/v2/';
	if (path_parts[0] == 'vendors') {
		accountID = path_parts[1]+'/';
		baseURL = baseURL+'vendors/'+accountID;
  }

	$scope.isAdmin = (USER_TYPE != 'vendor');
	//if updating amount, also update ADMIN_FEE in LabelOrder.php
	$scope.adminFee = ($scope.isAdmin ? 25 : 0);

	$scope.hasErrors = false;

	$scope.subTotal = 0;
	$scope.decalCount = 0;
	$scope.products = [];
  $scope.shippingAddress = {};

  $scope.labelsPaymentApproved = {
    approved: false
  };

  var id = path_parts[0] == 'vendors' ? path_parts[1] : false;
  API.paymentMethod(id).success(function (response) {
    $scope.payment_method = response.data;
  });

	$http.get(baseURL+'labels/products').then(
		function(response){
			$scope.products = response.data.data;
			angular.forEach(response.data.data, function(value){
				value.quantityToOrder = 0;
			});
		},
		function(response){

		}
	);

	$http.get('/api/v2/accounts/'+accountID).then(
		function(response){
			var account = response.data;

			$scope.shippingAddressLoaded = true;

			$scope.shippingAddress = {
				ship_to_address_line1:account.address_line1,
				ship_to_address_line2:account.address_line2,
				ship_to_city:account.city,
				ship_to_state:account.state,
				ship_to_zip:account.zip,
				ship_to_company:account.name,
        ship_to_phone:account.phone,
				ship_to_country:'US'
			};
		},
		function(response){

		}
	);
	if(!$scope.isAdmin){
		$http.get('/api/v2/users/0').then(
			function(response){
				$scope.shippingAddress.ship_to_name=response.data.fullname;
				if (!$scope.$$phase) $scope.$apply();
			},
			function(response){

			}
		);
	}
	else{
		$scope.shippingAddress.ship_to_name="";
	}


	$scope.addProduct = function(id){
		$scope.products[id].quantityToOrder += 1;

		$scope.subTotal += parseFloat($scope.products[id].price);
		$scope.decalCount += parseInt($scope.products[id].quantity, 10);
		unsetShippingCost();
	};

	$scope.minusProduct = function(id){
		if($scope.products[id].quantityToOrder > 0){
			$scope.products[id].quantityToOrder -= 1;

			$scope.subTotal -= $scope.products[id].price;
			$scope.decalCount -= $scope.products[id].quantity;
			unsetShippingCost();
		}
	};

	$scope.submitShippingRequest = function(){
		if(!$scope.addressForm.$valid){
			$scope.hasErrors = true;
		}else{
			var data = {
			shippingAddress:$scope.shippingAddress,
				order:$filter('filter')($scope.products, function(item){return item.quantityToOrder > 0;})
			};

			$scope.requestingShipping = true;
			$http.post(baseURL+'labels/orders/shipping', data).then(
				function(response){
					$scope.shippingCost = parseFloat(response.data.cost);

					$scope.requestingShipping = false;
					if (!$scope.$$phase) $scope.$apply();
				},
				function(response){
					$scope.requestingShipping = false;
					growlError(response.data.message);
				}
			);
		}

	};

	$scope.submitOrder = function(){
		$scope.submittingPayingOrder = true;
		$scope.submittingOrder = true;
		var data = {
			shippingAddress:$scope.shippingAddress,
			order:$filter('filter')($scope.products, function(item){return item.quantityToOrder > 0;}),
			shipping_cost:$scope.shippingCost
		};

		$http.post(baseURL+'labels/orders/',data).then(
			function(response){
				if(USER_TYPE == 'vendor'){
					submitPayment(response.data.invoice_id);
				}else{
					//here I need to redirect to the invoice for an admin
					$('#submit-order-pay-modal').modal('hide');
					$location.path("/vendors/"+accountID+"invoices/"+response.data.invoice_id);
				}
				$scope.submittingOrder = false;
				if (!$scope.$$phase) $scope.$apply();
			},
			function(response){
				$scope.submittingOrder = false;
				growlError(response.data.message);
				//here we need to decide what to do and how to handle re-attempting to submit the order
			}
		);
  };

  $scope.showConfirmModal = function() {

    if ($scope.isAdmin) {
      $('#submit-order-pay-modal').modal('show');
    } else {
      $('#confirm-payment').modal('show');
    }
  };

  $scope.hasItems = function() {
    var totalItems = $scope.products.reduce((prev, cur) => prev + cur.quantityToOrder, 0);
    return totalItems > 0;
  };

	function submitPayment(invoiceID){
		$scope.payingInvoice = true;
		$http.post('/invoices/'+invoiceID+'/pay',{}).then(
			function(response){
				$scope.payingInvoice = false;
				$('#submit-order-pay-modal').modal('hide');
				$location.path("/labels");
			},
			function(response){

			}
		);
	}

	var unsetShippingCost = function(){
		if($scope.shippingCost){
			$scope.shippingCost=false;
		}
	};

	$scope.$watch('shippingAddress', unsetShippingCost,true);
}])
.controller('LabelsMaketingCtrl',['$scope', '$http', function($scope, $http){
	$scope.is_loading = true;
	$http.get('/api/v2/clients/11524/brand').then(
		function(response){
			$scope.is_loading = false;
			$scope.brand_marks = response.data.data;
		},
		function(response){

		}
	);
}])
.controller('LabelsUpdateAllCtrl',['$scope', '$http', function($scope, $http){
	$scope.submitRequest = function(){
		$scope.updateAllClicked = true;
		$http.get('/api/v2/labels/update_status/').then(
			function(response){
				growl(response.data);
			},
			function(response){

			}
		);
	};
}])
.controller('LabelsUpdateCtrl',['$scope', '$http', function($scope, $http){


}])
.controller('LabelOrderCtrl', ['$scope', '$http', function($scope, $http){
	$scope.is_loading = true;
	var parts = getPathParts();
	var orderID = parts[parts.length -1];

	$scope.order = {};

	$http.get('/api/v2/labels/orders/'+orderID).then(
		function(response){
			$scope.is_loading = false;
			$scope.order = response.data;
			$scope.original_tracking_list = $scope.order.tracking_numbers;
			$scope.order.tracking_numbers = $scope.order.tracking_numbers.replace(/,/g,', ');
		},
		function(response){}
	);

	$scope.submitRequest = function(){
		$scope.updateBtnDisabled = true;
		$http.get('/api/v2/labels/update_status/'+orderID).then(
			function(response){
				$scope.original_tracking_list = $scope.order.tracking_numbers;
				$scope.updateBtnDisabled = false;
				$scope.order = response.data;
				$scope.order.tracking_numbers = $scope.order.tracking_numbers.replace(/,/g,', ');
				if (!$scope.$$phase) $scope.$apply();
			},
			function(response){

			}
		);
	};
}]);
