var portalApi = angular.module('portalApi', [])
.factory('API', ['$http', '$resource', function($http, $resource) {
	var api_url = '/api/v1/';

	var api_call = function(url, method, data) {
		method = method || 'GET';
		var httpObject = {
			method: method,
			url: api_url + url
		};
		if(data){
			httpObject.data = data;
		}
		return $http(httpObject);
	};

	var API = {};

	API.getAccountUrl = function() {
		var parts = getPathParts();
		var url = 'account';

		if (parts[0] == 'vendors' || parts[0] == 'clients') url += '/' + parts[1];

		return url + '/';
	};

	API.call = function(url, method, data) {
		return api_call(url, method, data);
	};

	API.getAccount = function(accountID) {
		return api_call(accountID ? 'account/' + accountID : 'account');
	};

	API.getLicense = function(accountID) {
		var url = API.getAccountUrl() + 'licenses/with/' + accountID;
		return api_call(url);
	};

	API.getProduct = function(id) {
		return api_call('products/' + id);
	};
	API.getProducts = function(data) {
		return api_call('products', 'GET', data);
	};


	API.getClients = function() {
		return api_call('clients/' + id);
	};

	API.getClient = function(id) {
		return api_call('clients/' + id);
	};

	API.getClientOwner = function(id) {
		return api_call('clients/' + id + '/owner');
	};

	API.getUser = function(id) {
		return api_call('users/' + id);
	};


  API.paymentMethod = function(accountID) {
    return api_call(accountID ? 'account/' + accountID + '/payment-method' : 'account/payment-method');
  };

  API.generateInterestInvoice = function(invoiceId) {
    const httpObject = {
      method: 'POST',
      url: '/api/invoices/' + invoiceId + '/interest'
    };
    return $http(httpObject);
  };

	API.getVendors = function() {
		return api_call('vendors');
	};

	API.getVendor = function(id) {
		return api_call('vendors/' + id);
	};

	API.getVendorOwner = function(id) {
		return api_call('vendors/' + id + '/owner');
	};

	return API;
}]);

export default portalApi;
