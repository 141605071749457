import portalControllers from './_controllers';

portalControllers.controller('VendorsCtrl', ['$scope', 'API', function($scope, API) {
	$scope.dataObject = {};
	$scope.vendors = {};
	$scope.search = '';
	var search_query = '';

	$scope.getData = function() {
		if ($scope.search === '') search_query = '';
		else search_query = '?search=' + encodeURIComponent($scope.search);

		API.call('vendors' + search_query).success(function(response) {
			$scope.dataObject = response;
			$scope.vendors = response.data;
		});
	};

	$scope.getData();
}])
.controller('ClientsCtrl', ['$scope', 'API', function($scope, API) {
	$scope.dataObject = {};
	$scope.clients = {};
	$scope.search = '';
	var search_query = '';

	$scope.getData = function() {
		if ($scope.search === '') search_query = '';
		else search_query = '?search=' + encodeURIComponent($scope.search);

		API.call('clients' + search_query).success(function(response) {
			$scope.dataObject = response;
			$scope.clients = response.data;
		});
	};



	$scope.getData();
}])
.controller('operationsUpdateFormCtrl',['$scope', '$http', '$filter', function($scope, $http, $filter){
    $scope.suppliers = {};
    $scope.manufacturers = {};
    $scope.add_supplier_field = {
      text: ''
    };
    $scope.add_manufacturer = {};
    $scope.distributionChannelSelection = {};

    $scope.addSupplier = function(supplier){
      $scope.suppliers[supplier] = {name : supplier};
      $scope.add_supplier_field.text = '';
    };

    $scope.removeSupplier = function(value){
        delete $scope.suppliers[value];
    };

    $scope.addManufacturer = function(){
        $scope.manufacturers[$scope.add_manufacturer.name] = {
            name : $scope.add_manufacturer.name,
            address : $scope.add_manufacturer.address
        };
        $scope.add_manufacturer = {};
    };

    $scope.removeManufacturer = function(value){
        delete $scope.manufacturers[value];
    };

    $scope.parseOperations = function() {
    	if ($scope.nonParsedDistributionChannels && $scope.account) {

            $scope.suppliers = (!$scope.account.profile.supplier_list ? {} : JSON.parse($scope.account.profile.supplier_list));
            $scope.manufacturers = (!$scope.account.profile.manufacturer_list ? {} : JSON.parse($scope.account.profile.manufacturer_list));

            $scope.nonParsedDistributionChannels.forEach(function (channel, var2) {
            	$scope.distributionChannelSelection[channel.id] =
                    $scope.account.royaltyDistributionChannels.filter(function(selectedchannel) {return selectedchannel.id == channel.id;}).length == 1;
            });
		}
	};

    $http.get('/api/flaCategories').then(
    	function(response) {
    		$scope.fla_categories = response.data.data;
            if (!$scope.$$phase) $scope.$apply();
		},
        function(errorData){
            growlError("Couldn't get FLA Categories");
        }
	);

    $http.get('/api/distributionChannels').then(
    	function(response){
    		$scope.nonParsedDistributionChannels = response.data.data;
            $scope.distributionChannels = parseDistributionChannels($scope.nonParsedDistributionChannels);
            $scope.parseOperations();
		},
		function(errorData){
    		growlError("Couldn't get Distribution Channels");
		}
	);

    function parseDistributionChannels(data) {
    	return data.reduce(function(obj,item){
    		var categoryName = item.category.name;
            obj[categoryName] = obj[categoryName] || [];
            obj[categoryName].push(item);
            return obj;
		}, {});
	}

	$scope.changedHasFla = function (hasFla) {
    	if (!hasFla) {
    		$scope.account_profile.fla_file_id = null;
    		$scope.account_profile.fla_category_id = null;
		}
	};

	$scope.verifyOperationsForm = function(isNavButton, completionFunction){
		$scope.operationsCheck = {};

		$scope.operationsCheck.targets = (
			$scope.account_profile.retail_distribution_model != 1 || (
				$scope.account_profile.undergrad_target_audience == 1 ||
				$scope.account_profile.alumni_target_audience == 1 ||
				$scope.account_profile.family_friends_target_audience == 1
			)
		);


		$scope.operationsCheck.models = (
			$scope.account_profile.wholesale_distribution_model == 1 || $scope.account_profile.retail_distribution_model == 1
		);

        $scope.operationsCheck.channels = (Object.values($scope.distributionChannelSelection).indexOf(true) > -1);

		$scope.operationsCheck.suppliers = (JSON.stringify($scope.suppliers).length > 2);

		$scope.operationsCheck.flaFile = (
            $scope.account_profile.has_labor_fla != 1 || (
                $scope.account_profile.fla_file_id !== null
			)
		);


		$scope.operationsCheck.manufacturers = (
			$scope.account_profile.external_manufacturing != 1 || (
				JSON.stringify($scope.manufacturers).length > 4)
    );

		$scope.operationsCheck.manufacturing = ( $scope.account_profile.internal_manufacturing == 1 || $scope.account_profile.external_manufacturing == 1 );
		$.each($scope.operationsCheck, function(key, element){
			$('#'+key+'-error').remove();
			if(!element){
				$('#'+key+'-header').append('<span class="text-danger" id="'+key+'-error"> - Required</span>');
			}
		});
		//once verified, we con move on
		if(
			$scope.operationsCheck.targets &&
			$scope.operationsCheck.models &&
      $scope.operationsCheck.channels &&
			$scope.operationsCheck.suppliers &&
			$scope.operationsCheck.manufacturing &&
			$scope.operationsCheck.manufacturers &&
			$scope.operationsCheck.flaFile
		)
		{
			//$('#operations-form').submit();
			saveOperations(completionFunction);
			if(isNavButton){
				$scope.navButtonClicked(true);
				$('#nextButton').tab('show');
			}
			else{
				growl('Operations Changes Saved');
			}

		}
		else{
			var error = "Please complete all required fields.";
			growlError(error);
		}
	};

	function saveOperations(completionFunction) {
		$http.post(
			'/account/'+$scope.account.id+'/edit/operations',
			{
				profile: $scope.account_profile,
				selectedChannels: $scope.distributionChannelSelection,
				suppliers: $scope.suppliers,
        manufacturers: $scope.manufacturers
			}
			).then(function (response) {
			    if (completionFunction) {
			        completionFunction();
                }
			});
	}

    $scope.button_text = 'Upload File';

    var options = {
        data: {
            type: 'private',
            scope: 'application',
            action: 'fla'
        },
        previousSize: 0,
        handleProgress: function(e) {
            if (!e) return;

            var progress = this.totalProgress + e.loaded;

            var size = parseInt(progress / this.totalSize * 100, 10);

            $scope.button_text = size + '% uploaded...';

            if (size >= 100) $scope.button_text = 'Finalizing...';

            if (!$scope.$$phase) $scope.$apply();
        },
        handleComplete: function(e) {
            var status = e.target.status;

            switch (status) {
                case 200:
                    var json = $.parseJSON(e.target.response);

                    if (!json.success) {
                        growlError(json.message);
                    } else {
                        $scope.fileError = false;
                        $scope.account_profile.flaFile = json.data;
                        $scope.account_profile.fla_file_id = json.data.id;
                    }

                    break;
                default:
                    growlError('Upload failed!');
                    break;
            }

            $scope.button_text = 'Upload File';

            if (!$scope.$$phase) $scope.$apply();
        }
    };

    $scope.uploadFile = function() {
        $.each(arguments, function(i, file) {
            new FileUploader(file, options);
        });
    };

}])
.controller('AccountEditCtrl', ['$scope', '$timeout', '$location', 'Account', 'API', 'USER_TYPE', '$http', function($scope, $timeout, $location, Account, API, USER_TYPE, $http) {
	$scope.account = {};
	$scope.users = {};
	$scope.undo = {};
  $scope.reject = '';
  $scope.is_loading = true;
  $scope.isAdmin = USER_TYPE == 'admin';

	$scope.deleteAccount = function(accountId) {
		$('#delete-account-modal').modal('hide');
		Account.delete({'accountID': accountId}, function(response) {
			console.log('Deleted account');
            $location.path('/');
        });
	};

	$scope.hash = window.location.hash;

  var parts = getPathParts();
	$scope.api_call = parts.length == 2 ? 'account' : 'account/' + parts[1];
	if (parts.length == 1 && parts[0] == 'reports') $scope.api_call = 'account';
	if (parts.length == 1 && parts[0] == 'account') $scope.api_call = 'account';
	if (USER_TYPE == 'admin' && parts.length == 2) $scope.api_call = 'account/1';

	API.call($scope.api_call).success(function(response) {
		$scope.account = response.data;

        $scope.account_profile = $scope.account.profile;
        if ($scope.account_profile.fla_category_id) {
          $scope.account_profile.fla_category_id = Number($scope.account_profile.fla_category_id);
        }
        if ($scope.parseOperations) {
            $scope.parseOperations();
        }

    $scope.is_loading = false;
		if ($scope.account.market_id) {

      $scope.is_loading = true;
			API.call($scope.api_call + '/market-profile').success(function(response) {
        $scope.is_loading = false;
				$scope.market_profile = response.data;
			});
    }
        if (!$scope.$$phase) $scope.$apply();
	});

    //#############################################################################################################################
    //#	Expedite Designs
    //#############################################################################################################################
	$scope.expedite_consent = false;
	$scope.updateExpeditedDesigns = function(event, enable){
        event.target.disabled = true;
		var type = (enable === true ? 'enable' : 'disable');
        API.call($scope.api_call + '/'+type+'_expedited_designs', 'POST', {}).success(function(response) {
            event.target.disabled = false;
            $scope.account.profile = response.data;
        });
	};

	//#############################################################################################################################
	//#	WHITE LABEL
	//#############################################################################################################################
	$scope.white_label_button_text = 'Click to upload banner...';
	var wl_options = {
		data: {
			scope: 'account',
			action: 'white-label'
		},
		previousSize: 0,
		handleProgress: function(e) {
			if (!e) return;

			var progress = this.totalProgress + e.loaded;

			var size = parseInt(progress / this.totalSize * 100, 10);

			$scope.white_label_button_text = size + '% uploaded...';

			if (size >= 100) $scope.white_label_button_text = 'Finalizing...';

			if (!$scope.$$phase) $scope.$apply();
		},
		handleComplete: function(e) {
			var status = e.target.status;

			switch (status) {
				case 200:
					var json = $.parseJSON(e.target.response);

					if (!json.success) {
						growlError(json.message);
					} else {
						$scope.account_profile.wl_header_file_id = json.data.id;
						$scope.account_profile.wl_header_file = json.data;
						$scope.account_profile.wl_header_file.url = json.data.url.or;
					}


					break;
				default:
					growlError('Upload failed!');
					break;
			}

			$scope.white_label_button_text = 'Click to upload banner...';

			if (!$scope.$$phase) $scope.$apply();
		}
	};
	$scope.uploadWhiteLabelHeaderFile = function() {
		wl_options.data.accountID = $scope.account.id;

		$.each(arguments, function(i, file) {
			new FileUploader(file, wl_options);
		});
	};

	//#############################################################################################################################
	//# INSURANCE
	//#############################################################################################################################
	$scope.insurance_button_text = 'Click to upload file...';
	var insurance_options = {
		data: {
			type: 'private',
			scope: 'account',
			action: 'insurance'
		},
		previousSize: 0,
		handleProgress: function(e) {
			if (!e) return;

			var progress = this.totalProgress + e.loaded;

			var size = parseInt(progress / this.totalSize * 100, 10);

			$scope.insurance_button_text = size + '% uploaded...';

			if (size >= 100) $scope.insurance_button_text = 'Finalizing...';

			if (!$scope.$$phase) $scope.$apply();
		},
		handleComplete: function(e) {
			var status = e.target.status;

			switch (status) {
				case 200:
					var json = $.parseJSON(e.target.response);

					if (!json.success) {
						growlError(json.message);
					} else {

						if (USER_TYPE == 'admin' || $scope.insurance.current.incomplete > 0) {
							$scope.insurance.current.file = json.data;
							$scope.insurance.current.file_id = json.data.id;
						} else {
							$scope.insurance.pending.file = json.data;
							$scope.insurance.pending.file_id = json.data.id;
						}
					}

					break;
				default:
					growlError('Upload failed!');
					break;
			}

			$scope.insurance_button_text = 'Click to upload file...';

			if (!$scope.$$phase) $scope.$apply();
		}
	};
	$scope.uploadFile = function() {
		insurance_options.data.accountID = $scope.account.id;

		$.each(arguments, function(i, file) {
			new FileUploader(file, insurance_options);
		});
	};

	$scope.refreshInsurance = function() {
		API.call($scope.api_call + '/insurance').success(function(response) {
			$scope.insurance = response.data;

			if (!$scope.$$phase) $scope.$apply();
		});
	};

	API.call($scope.api_call + '/insurance').success(function(response) {
		$scope.insurance = response.data;
	});

	$scope.approveInsurance = function(e) {
		var $button = $(e.delegateTarget);
		$button.data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i>Approving...').prop('disabled', true);
		$.ajax({
			method: 'post',
			url: window.location.pathname + '/insurance',
			data: {
				approve: true
			},
			success: function(response) {
				growlit(response);

				if (response.success) {
					$scope.insurance.current = response.data;
					$scope.insurance.pending = {};
					hideModals();
				}

				if (!$scope.$$phase) $scope.$apply();
			},
			complete: function() {
				$button.html($button.data('old')).prop('disabled', false);
			}
		});
	};

	$scope.updateScannedCopy = function(response) {

		if (response.success) {
			$scope.refreshInsurance();
		}

		$('.modal').modal('hide');
	};



	API.call($scope.api_call + '/users?limit=-1').success(function(response) {
		$scope.users = response.data;
	});



	$scope.updateAccountInfo = function(response) {
		if (response.data) {
			$scope.account = response.data;
			$scope.$apply();
		}
	};

	$scope.updatePaymentMethod = function() {

		API.call($scope.api_call + '/payment-method').success(function(response) {
			$scope.payment_method = response.data;
		});
	};

	$scope.updateCropperImage = function(image) {
		if ($scope.account.pending_address && $scope.account.pending_address.public) {
			$scope.account.pending_address.public.image_id = image.id;
			$scope.account.pending_address.old_image_id = image.id;
			$scope.account.pending_address.public.image.or = image.url.or;
		} else {
			$scope.account.image_id = image.id;
			$scope.account.image.or = image.url.or;
		}

		$scope.$apply();
	};

	$scope.$watch('account.pending_address.public.image.or', function(value) {
		if (!value) return;

		var current = $scope.account.image.or;
		// if current image equals the pending image then make sure the id changes as well

		if (current == value) {
			$scope.account.pending_address.old_image_id = $scope.account.pending_address.public.image_id;
			$scope.account.pending_address.public.image_id = $scope.account.image_id;
		} else if ($scope.account.pending_address.old_image_id) {
			$scope.account.pending_address.public.image_id = $scope.account.pending_address.old_image_id;
		}

	});

	$scope.deleteRequestModal = function(address_type) {
		$scope.reject = address_type;
		$('#delete-pending-address').modal('show');
	};

	$scope.deleteRequest = function(e) {
		var address = $scope.account.pending_address[$scope.reject];

		$(e.delegateTarget).prop('disabled', true);

		$.ajax({
			url: '/api/v1/versioning/account-profiles/' + address.id,
			type: 'DELETE',
			success: function(response) {
				$(e.delegateTarget).prop('disabled', false);
				if (response.success) growl(response.message);
				else growlError(response.message);

				delete $scope.account.pending_address[$scope.reject];
				$scope.reject = '';
				$scope.$apply();

				$('#delete-pending-address').modal('hide');
			}
		});
	};

	$scope.updatePaymentMethod();

}])
//////////////////////////////////////////////////////////////////////////////////
//				Payment Method													//
//////////////////////////////////////////////////////////////////////////////////
.controller('AddPaymentMethodCtrl', ['$scope', 'USER_TYPE', 'API', function($scope, USER_TYPE, API) {

	$scope.ba_window = 1;
	var parts = getPathParts();


	if (parts[0] == 'licenses' || parts[0] === '' || parts[0] == 'labels') {
		$scope.api_call = 'account';

	} else if (parts.length === 2) {

		if (USER_TYPE == 'admin') {
			$scope.api_call = 'account/1';
		} else {
			$scope.api_call = 'account';
		}

	} else {
		$scope.api_call = 'account/' + parts[1];
	}

	if(!$scope.updatePaymentMethod){
		$scope.updatePaymentMethod = function() {

			API.call($scope.api_call + '/payment-method').success(function(response) {
				$scope.payment_method = response.data;
			});
		};

		$scope.updatePaymentMethod();
	}

	API.call($scope.api_call).success(function(response) {
		$scope.account = response.data;

		if (!$scope.account.owner) {
			if (USER_TYPE == 'admin') {
				growlError('This account needs an owner!');
			}
		} else {
			$scope.owner_name = $scope.account.owner.fullname;
		}
	});
	/*****************************************************************************************
					JS for stripe forms
	******************************************************************************************/
	/*$.validator.addMethod("valueNotEquals", function(value, $('#add-sba-form'), arg){

		return arg != value;
	});*/

	$('#add-sba-form').validate({
		rules: {
			ba_routing: 'required',
			ba_number: 'required',
			ba_account_holder_name: 'required',
			ba_account_type: 'required',
			ba_number_verify: {
				equalTo: '#ba_number'
			},
		},
		submitHandler: function(form) {
			function addError(message) {
				var $modalBody = $('#add-sba-form').find('div.modal-progress');
				$('#add-sba-form').find('.alert').remove();
				$modalBody.before('<div class="alert modal-alert alert-danger"><i class="fa fa-exclamation-triangle"></i> '+ message +'</div>');
			}

			Stripe.setPublishableKey($('#add-sba-form').attr('publishableKey'));
			var $button = $(form).find('[type="submit"]');

			var payload = {
				routing_number: $('#ba_routing').val(),
				account_number: $('#ba_number').val(),
				account_holder_name: $('#ba_account_holder_name').val(),
				account_holder_type: $('#ba_account_type').val(),
				country:'US',
				currency:'USD',
				//name: $scope.owner_name || $scope.account.id,
			};
			if (!Stripe.bankAccount.validateRoutingNumber(payload.routing_number, 'US')) return addError('You have entered an invalid routing number.');
			if (!Stripe.bankAccount.validateAccountNumber(payload.account_number, 'US')) return addError('You have entered invalid bank account information.');

			$('#add-sba-form').find('.alert').remove();
			$scope.ba_window = 2;
			$scope.$apply();
			$button.prop('disabled', true).data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i> Creating...');

			// Create bank account
			Stripe.bankAccount.createToken(payload, function(status, response) {

				if (status === 200) {
					$(form).find('[type="submit"]').html('<i class="fa fa-spin fa-spinner"></i> Finalizing...');
					$.ajax({
						url: form.action,
						type: 'post',
						data: {
							uri: response.id
						},
						dataType: 'json',
						success: function(response) {
							$scope.ba_window = 3;
							if (response.success) {
								$scope.updatePaymentMethod();
								$scope.$apply();
							} else {
								addError(response.message);
							}
						},
						complete: function() {
							$button.html($button.data('old')).prop('disabled', false);
						}
					});
				} else {
					addError(response.error.message);
					$scope.ba_window = 1;
					$scope.$apply();
					$button.prop('disabled', false).html($button.data('old'));
				}
			});
		}
	});


	$('#verify-sba-form').validate({
		rules: {
			amount_1: {
				required: true,
				range: [0.01, 0.99]
			},
			amount_2: {
				required: true,
				range: [0.01, 0.99]
			}
		},
		submitHandler: function(form) {
			function addError(message) {

				var $modalBody = $('#verify-sba-form').find('div.modal-progress');

				$('#verify-sba-form').find('.alert').remove();
				$modalBody.before('<div class="alert modal-alert alert-danger"><i class="fa fa-exclamation-triangle"></i> '+ message +'</div>');
			}

			var $button = $(form).find('[type="submit"]');

			$button.prop('disabled', true).data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i> Verifying...');

			$.ajax({
				url: form.action,
				type: 'post',
				data: $(form).serialize(),
				dataType: 'json',
				success: function(response) {
					if (response.code === 303) {
						$('#verify-ba-modal').find('.form-group').remove();
						$('#verify-ba-modal').find('.modal-footer [type="submit"]').remove();
						$('#verify-ba-modal').find('.modal-footer [type="button"]').html('Close');
						$scope.ba_window = 3;
						addError(response.message);
						$scope.updatePaymentMethod();
						$scope.$apply();
					} else if (response.success) {
						$('#verify-sba-form').find('.alert').remove();
						$('#verify-progress-bar-div').attr('aria-valuenow', 100).css('width', '100%');
						$('#verify-ba-modal').find('.modal-footer [type="submit"]').remove();
						$('#verify-ba-modal').find('.modal-footer [type="button"]').html('Close').removeClass('pull-left').addClass('pull-right');
						$scope.ba_window = 2;
						$scope.updatePaymentMethod();
						$scope.$apply();
					} else if (response.error) {
						addError(response.error.message);
						$('#amount_2').val('');
						$('#amount_1').val('').focus();
					} else {
						addError(response.message);
						$('#amount_2').val('');
						$('#amount_1').val('').focus();
					}
				},
				complete: function() {
					$button.html($button.data('old')).prop('disabled', false);
				}
			});
		}
	});
	/******************************************************************************************************
				End stripe forms
	******************************************************************************************************/
	$scope.closeModal = function() {
		$('.modal').modal('hide');
		$scope.updatePaymentMethod();
		$scope.ba_window = 1;
		$scope.$apply();
	};
}])
;
