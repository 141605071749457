import portalApp from './_angular';
portalApp.controller('NewActivityCtrl', ['$scope', '$routeParams', 'Activity', 'USER_TYPE', function($scope, $routeParams, Activity, USER_TYPE) {
	var activity;

	if (USER_TYPE == 'admin') $scope.view_feed = '1,2,3,4';
	else if (USER_TYPE == 'client') $scope.view_feed = '2,4';
	else $scope.view_feed = '3,4';

	$scope.updateActivity = function() {
		Activity.get({ entityID: $routeParams.entityID }, function(response) {
			$scope.activity = response;
		});
	};

	var groups = { 1: 'Admin', 2: 'Client', 3: 'Vendor', 4: 'Everyone' };

	var activity_options = {
		'1,2,3,4': {
			'name': 'All Activity',
			'description': 'Admin View',
			'group': ['1', '2', '3', '4']
		},
		'2': {
			'name': 'Client Discussion',
			'description': 'Client View',
			'group': ['2', '4']
		},
		'3': {
			'name': 'Vendor Discussion',
			'description': 'Vendor View',
			'group': ['3', '4']
		}
	};

	if (USER_TYPE == 'admin') this.selected_activity = activity_options['1,2,3,4'];
	else if (USER_TYPE == 'client') this.selected_activity = activity_options['2'];
	else this.selected_activity = activity_options['3'];

	$scope.updateActivity();

	$scope.$watch('entity.client_account_id', function(value) {
		$('select.multiselect').multiselect('rebuild');
	});

	$scope.$watch('entity.vendor_account_id', function(value) {
		$('select.multiselect').multiselect('rebuild');
	});

	$scope.$watch('entity.phase', function(value) {
		if (!value) return;

		$scope.updateActivity();
	});

	$scope.message = '';

	$scope.sendMessage = function(e, group) {
		// var message = $('#activity-message').html();
		var sending_to = '';

		if (!$scope.message) {
			growl('No message provided');
			return;
		}

		// permission checks
		if (USER_TYPE == 'client') {
			group = 2;
		} else if (USER_TYPE == 'vendor') {
			group = 3;
		} else {
			sending_to = ' to ' + groups[group];
		}

		var data = {
			'note': $scope.message
		};

		var $messageChoices = $('#message-choices');
		$messageChoices.data('old', $messageChoices.html()).prop('disabled', true).html('<i class="fa fa-spin fa-spinner"></i> Sending'+ sending_to +'...');

		$.ajax({
			url: '/api/v1/entities/' + $routeParams.entityID + '/activity/' + group + '/add',
			type: 'post',
			data: data,
			dataType: 'json',
			success: function(response) {
				if (response.success) {
					$scope.activity = response.data;
					$scope.message = '';
					$('#activity-message').html('');
					$scope.$apply();
				} else growlError(response.message);
			},
			complete: function() {
				$messageChoices.prop('disabled', false).html($messageChoices.data('old'));
			}
		});
	};

}]);
