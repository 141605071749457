import portalControllers from './_controllers';
import DineroFactory from "dinero.js";
function addInvoiceMethods($scope) {
	$scope.line_items = [];

	$scope.addLineItem = function() {
		$scope.line_items.push({ receiving_account_id: '', description: '', amount_due: '' });
	};

	$scope.removeItem = function(index) {
		$scope.line_items.splice(index, 1);

		$scope.updateFauxTotal();
	};

	$scope.updateAmountTotal = function() {
		var amount = 0;
		$.each($scope.line_items, function(i, line_item) {
			amount += parseFloat(line_item.amount_total);
		});

		$scope.invoice.amount_total = amount;
	};

	$scope.updateFauxTotal = function() {
		var amount = 0;
		$.each($scope.line_items, function(i, line_item) {
			amount += parseFloat(line_item.amount_total);
		});

		$scope.faux_total = amount;
	};
}

portalControllers
.controller('InvoiceIndexCtrl', ['$scope', '$routeParams', 'Invoices', 'das', function($scope, $routeParams, Invoices, das) {
	new das($scope, Invoices);
}])
.controller('AddInvoiceCtrl', ['$scope', '$routeParams', '$location', 'API', 'Invoices', function($scope, $routeParams, $location, API, Invoices) {

	$scope.editing_invoice = true;

	API.call('clients?limit=-1&return-func=toBasic&sort%5Bshort_name%5D%3Dasc').success(function(response) {
		$scope.clients = response.data;
	});

	Invoices.saved({ accountID: $routeParams.accountID }, function(response) {
		$scope.invoice = response;

		if (response.line_items) $scope.line_items = response.line_items;
	});


	addInvoiceMethods($scope);

	$scope.addLineItem();

	$scope.updateScope = function(response) {
		if (response.success) {
			var invoice = response.data;

			$location.path('/vendors/' + invoice.account_id + invoice.url);

			$scope.$apply();

			hideModals();
		}
	};

	$scope.quickDueDateChange = function () {
    if ($scope.invoice && $scope.quick_due_date && $scope.invoice.invoice_date) {
      const newDate = new Date();
      newDate.setTime((new Date($scope.invoice.invoice_date)).getTime()+parseInt($scope.quick_due_date, 10)*24*60*60*1000);
      $scope.quick_due_date = '';
      $scope.invoice.due_date = newDate.toISOString().substr(0, 10);
    } else {
      console.log('No invoice set?');
    }
  };

}])
.controller('InvoiceCtrl', ['$scope', '$routeParams', '$location', 'API', 'Invoices', 'USER_TYPE', function($scope, $routeParams, $location, API, Invoices, USER_TYPE) {
	$scope.payment_line_items = [];
	$scope.calculated_amount = 0;

	$scope.editing_invoice = false;

	var options = {
		'void': {
			title: 'Void Invoice',
			val: 'void',
			button: 'Void Invoice',
			button_alt: 'Voiding...',
			show_form: 'none',
			button_class: 'info'
		},
		'waive': {
			title: 'Waive Invoice',
			val: 'waive',
			button: 'Waive Invoice',
			button_alt: 'Waiving...',
			show_form: 'none',
			button_class: 'info'
		}
	};

	$scope.large_invoice = {};

	$scope.setLargeInvoice = function() {
		$scope.large_invoice.amount_total = $scope.invoice.amount_due;

		var total_amount = $scope.invoice.amount_due;
		var partial_amount;
		var view_amount;
		var payments = [];

		while (total_amount > 0) {
			if (total_amount - 200000 > 0) { partial_amount = 200000; }
			else { partial_amount = total_amount; }
			view_amount = (Math.round(partial_amount * 100) / 100).toFixed(2);
			payments.push({ 'amount_total': view_amount });
			total_amount -= partial_amount;
		}

		$scope.large_invoice.payments = payments;
		$scope.large_invoice.payment_count = payments.length;

	};

	$scope.editInvoice = function(edit) {
		$scope.editing_invoice = edit;
	};

	$scope.emailVendor = function() {
		$.getJSON(window.location.pathname + '/email', {}, function(response) {
			growlit(response);
		});
	};

	$scope.delete_payment = {};

	$scope.setDeletePayment = function(payment) {
		$scope.delete_payment.amount_paid = payment.amount_paid;
		$scope.delete_payment.url = window.location.pathname + '/payments/' + payment.id;
		$scope.delete_payment.id = payment.id;
	};

	$scope.selected_option = {};

	$scope.setSelectedOption = function(key) {
		$scope.selected_option = options[key];
	};

	$scope.can_show_verify = false;
	$scope.$watch('payment_method', function(method) {
		if (!method) return;

		var set = moment(method.verification_sent_at);
		var now = moment();
		var diff = set.diff(now, 'hours');
		$scope.can_show_verify = diff < -12 ? 1 : 0;
	});

	$scope.redirectBack = function(response) {
		if (response.success) {
			$('.modal').modal('hide');
			$('body').removeClass('modal-open');
			$location.path(response.url);
			$scope.$apply();
		}
	};

	API.call('clients?limit=-1&return-func=toBasic&sort%5Bshort_name%5D%3Dasc').success(function(response) {
		$scope.clients = response.data;
	});

	$scope.getInvoice = function() {
		Invoices.get({ invoiceID: $routeParams.invoiceID }, function(result) {
			$scope.invoice = result;

			$scope.faux_total = $scope.invoice.amount_total;

			$scope.line_items = result.line_items;

			$scope.payment_amount = $scope.invoice.amount_due;

			if ($scope.payment_amount > 200000) $scope.setLargeInvoice();
		});

		Invoices.payments({ invoiceID: $routeParams.invoiceID }, function(result) {
			$scope.payments = result;
		});
	};

  $scope.generateInterestInvoice = function() {
    $('.modal').modal('hide');
    $('body').removeClass('modal-open');
    $scope.is_loading = true;
    API.generateInterestInvoice($routeParams.invoiceID).success(function (response) {
      $scope.is_loading = false;
      $location.path('/vendors/' + $routeParams.accountID + '/invoices/' + response.data.id);
    }).error(function (response) {
      $scope.is_loading = false;
      growlError(response.message);
    });
  };

	$scope.duplicateInvoice = function() {
        $('.modal').modal('hide');
        $('body').removeClass('modal-open');
        $scope.is_loading = true;
        $scope.invoice.$duplicate( function(result) {
            $location.path('/vendors/' + $routeParams.accountID + '/invoices/' + result.id);
        });
	};

	$scope.commissionOptions = [
		{
			id: true,
			label: 'Has Commission'
		},
		{
			id: false,
			label: 'No Commission'
		},
	];

	$scope.updatePaymentMethod = function() {
		API.paymentMethod($routeParams.accountID).success(function(response) {
			$scope.payment_method = response.data;
		});
	};

	// reopen invoice
	$scope.reopenInvoice = function(e) {
		var $button = $(e.delegateTarget);

		$button.data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i> Reopening...').prop('disabled', true);

		$.ajax({
			url: window.location.pathname + '/reopen',
			method: 'post',
			dataType: 'json',
			success: function(response) {
				growlit(response);

				if (response.success) {
					$scope.invoice = response.data;
					$scope.faux_total = $scope.invoice.amount_total;

					$scope.line_items = response.data.line_items;
				}

				$('.modal').modal('hide');

				if (!$scope.$$phase) $scope.$apply();
			},
			complete: function(response) {
				$button.prop('disabled', false).html($button.data('old'));
			}
		});
	};

	$scope.$watch('line_items', function(val) {
		if (!val) return;
		$scope.payment_line_items = [];

		$.each($scope.line_items, function(i, line_item) {

			if (parseFloat(line_item.amount_due) !== 0 || parseFloat(line_item.amount_total) === 0) {
				var temp_line_item = angular.copy(line_item);
				$scope.payment_line_items.push(temp_line_item);
			}
		});
	});

	addInvoiceMethods($scope);

	$scope.updateScope = function(response) {
		if (response.success) {
			$scope.editing_invoice = false;

			$scope.getInvoice();
			$scope.$apply();
			$('.modal').modal('hide');
		}
	};

	$scope.updatePaymentMethod();

	$scope.getInvoice();

	$scope.matchCommissionFlag = function () {
		var newInvoiceType = $scope.invoice.invoice_type.id;

        var typeMap = {
            2: true,
            3: false,
            4: true,
            5: false,
            6: true,
            7: false,
            10: true,
            11: false,
            12: true,
            13: true,
            14: false,
            15: false,
            16: false,
            17: false
        };

        if (newInvoiceType in typeMap) {
        	var fieldVal = typeMap[newInvoiceType] ? 1 : 0;
        	$scope.invoice.is_admin_commission = fieldVal;
		}
  };

  $scope.isPaymentApproved = 0;

}])
.controller('InvoicePaymentCtrl', ['$scope', function($scope) {
	$scope.editing_payment = false;

	$scope.cur_or_prev_quarter = !isBeforeQuarter($scope.payment.payment_quarter, $scope.payment.payment_year, getPreviousQuarter());

	$scope.editItem = function(edit) {
		$scope.editing_payment = edit;
	};

	$scope.updateAmountPaid = function() {
		var total = 0;
		$.each($scope.payment.line_items, function(i, line_item) {
			total += parseFloat(line_item.amount_paid);
		});
		$scope.payment.amount_paid = total;
	};

	$scope.editPaymentDone = function(response) {
		if (response.success) {
			$scope.editItem(false);
			$scope.getInvoice();
		}
	};

}])
.controller('VendorPaymentCtrl', ['$scope', function($scope) {
	// vendor payment controller
}])
.controller('AdminPayment', ['$scope', function($scope) {


	$scope.updatePaymentAmountLineItems = function() {
		var payment_amount = parseFloat($scope.payment_amount);
		var invoice_amount_due = parseFloat($scope.invoice.amount_due);

		// ensure payment amount is not greater than the amount due
		if (invoice_amount_due < 0) {
			if (payment_amount > 0) {
				payment_amount *= -1;
				$scope.payment_amount *= -1;
			}

			if (payment_amount < invoice_amount_due) {
				$scope.payment_amount = invoice_amount_due;
			}
		} else if (invoice_amount_due >= 0 && payment_amount > invoice_amount_due) {
			$scope.payment_amount = invoice_amount_due;
		}

		// reset the payment amount
		payment_amount = $scope.payment_amount;

		const allocationRatios = $scope.payment_line_items.map(item => {
		  const amount = Math.round(parseFloat(item.amount_due) * 100);
		  return amount > 0 ? amount : 0;
    });

		let negativeSum = 0;
    $scope.payment_line_items.forEach(item => {
      const amount = parseFloat(item.amount_due);
      if (amount < 0) {
        negativeSum += amount;
      }
    });

		const allocations = DineroFactory({ amount: Math.round((parseFloat(payment_amount) - negativeSum) * 100) })
      .allocate(allocationRatios);

    for (let i = 0; i < allocations.length; i++) {
      const item = $scope.payment_line_items[i];
      const allocation = allocations[i];

			item.amount_total	= parseFloat(item.amount_total);
			item.amount_paid	= parseFloat(item.amount_paid);
			item.amount_due		= parseFloat(item.amount_due);

			if (item.amount_due < 0) {
        item.amount_paid = item.amount_due;
      } else {
			  item.amount_paid = allocation.getAmount() / 100;
      }
		}
	};


	$scope.updatePaymentAmount = function() {
		$scope.payment_amount = 0;

		$.each($scope.payment_line_items, function(i, li) {
			$scope.payment_amount += parseFloat(li.amount_paid);
		});
	};

}])
.controller('PaymentLineItem', ['$scope', function($scope) {

	$scope.$watch('line_item.amount_paid', function(value) {
		var due = parseFloat($scope.line_item.amount_due);
		var paid = parseFloat($scope.line_item.amount_paid);

		if (due < 0 && paid > 0) {
			paid *= -1;
			$scope.line_item.amount_paid = paid;
		}

		if ((due < 0 && paid < due) || (due >= 0 && paid > due)) {
			$scope.line_item.amount_paid = $scope.line_item.amount_due;
		}

		$scope.updatePaymentAmount();
	});
}])
.controller('AddLineCtrl', ['$scope', function($scope) {
	$scope.add_line_val = 1;

	$scope.$watch('add_line_val', function(value) {
		var add_line_val = $scope.add_line_val;
		// $scope.add_line_val = isNaN(add_line_val) ? 0 : parseFloat(add_line_val);
		if(isNaN(add_line_val) || add_line_val < 1) { $scope.add_line_val = 1; }
		else if (add_line_val > 150) { $scope.add_line_val = 150; }
	});

	$scope.addLines = function(value) {
		value = $scope.add_line_val;
    let description = null;

    /* If exactly one description is used on the existing line items, copy that to the new line items.
     * Otherwise, leave it blank.
     */
    $scope.line_items.forEach(lineItem => {
		  if (description === '') {
		    return;
      }
		  if (description === null) {
        description = lineItem.description;
      } else if (description !== lineItem.description) {
		    description = '';
      }
    });
		for (var i = 0; i < value; i++) {
			$scope.line_items.push({ receiving_account_id: '', description: description ? description : '', amount_due: '' });
		}
	};

}])
.controller('InvoiceBalancedCtrl', ['$scope', function($scope) {
	$scope.url_to_view = false;

	$scope.uploadFile = function(e) {
		var $button = $(e.delegateTarget);
		var file_input = document.getElementById('upload-csv');
		var file = file_input.files[0] || false;

		if (file) {
			$button.html('<i class="fa fa-spin fa-spinner"></i>Submitting...').prop('disabled', true);

			new FileUploader(file, {
				url: window.location.pathname,
				handleComplete: function(e) {
					$button.html('Submit CSV').prop('disabled', false);

					var json = $.parseJSON(e.target.response);

					growlit(json);

					if (json.success) {
						$scope.url_to_view = json.data;
						if (!$scope.$$phase) $scope.$apply();
					}
				}
			});
		}
	};
}]);
