import portalApp from './_angular';
portalApp
.controller('ClientsIndexCtrl', ['$scope', '$location', 'Clients', 'das', function($scope, $location, Clients, das) {
	new das($scope, Clients);

	$scope.clientAdded = function(response) {
		if (response.success) {
			$location.path('/clients/' + response.data.id + '/settings');
			$scope.$apply();

			hideModals();
		}
	};
}]);
