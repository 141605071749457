import portalControllers from './_controllers';
portalControllers
    .controller('AdminIndexCtrl', ['$scope', 'AdminResource', function ($scope, AdminResource) {

        $scope.hash = window.location.hash;

        $scope.is_loading = true;

        AdminResource.get(function (response) {
            $scope.is_loading = false;

            $.each(response, function (key, val) {
                $scope[key] = val;
            });
        });

    }])
    .controller('AnnualRenewalsCtrl', ['$scope', '$routeParams', 'AnnualRenewals', function ($scope, $routeParams, AnnualRenewals) {
        $scope.vendors = [];
        $scope.is_loading = true;
        $scope.generating = false;
        $scope.emailing = false;

        var $genbutton = $('#generate-invoices-button');
        var $emailbutton = $('#email-invoices-button');

        $scope.vendors_left = [];
        $scope.emails_left = [];

        AnnualRenewals.query({year: $routeParams.year}, function (response) {
            $scope.vendors = response;
            $scope.is_loading = false;

            $.each($scope.vendors, function (i, vendor) {
                if (!vendor.invoice_id) $scope.vendors_left.push(i);
                if (!vendor.emailed_at) $scope.emails_left.push(i);
            });
        });

        $scope.updateCounts = function (e) {

        };

        function generateNextInvoice() {
            if (!$scope.generating) return;

            if ($scope.vendors_left.length < 1) {
                return;
            }

            var index = $scope.vendors_left.shift();

            var vendor = $scope.vendors[index];
            var $input = $('#input-' + vendor.id);
            var $spinner = $('<i class="fa fa-pause"></i>');
            var $file = $('#file-' + vendor.id);

            if ($input.is(':checked') && $file.find(':contains("Invoiced")').length === 0) {
                $input.hide().after($spinner);

                AnnualRenewals.generateInvoice({year: $routeParams.year, vendorID: vendor.id}, function (response) {
                    $spinner.hide();
                    $input.show();
                    vendor.invoice_id = response.id;
                    vendor.emailed_at = response.emailed_at;
                    generateNextInvoice();
                }, function () {
                    // If failed, send the next invoice
                    generateNextInvoice();
                });

            } else {
                generateNextInvoice();
            }
        }

        $scope.generateInvoices = function (e) {
            $genbutton = $('#generate-invoices-button');

            if (!$scope.generating) {
                $genbutton.html('<i class="fa fa-pause"></i> Pause Generation');
                $scope.generating = true;

                generateNextInvoice();
                generateNextInvoice();
                generateNextInvoice();
                generateNextInvoice();

            } else {
                $genbutton.html('<i class="fa fa-play"></i> Continue Generation');
                $scope.generating = false;
            }
        };


        function emailNextInvoice() {
            if (!$scope.emailing) return;

            if ($scope.emails_left.length < 1) {
                if (!$scope.$$phase) $scope.$apply();
                return;
            }

            var index = $scope.emails_left.shift();

            var vendor = $scope.vendors[index];
            var $input = $('input[value="' + vendor.id + '"]');
            var $spinner = $('<i class="fa fa-pause"></i>');

            if ($input.is(':checked') && !vendor.emailed_at) {
                $input.hide().after($spinner);

                AnnualRenewals.emailInvoice({year: $routeParams.year, vendorID: vendor.id}, function (response) {
                    $spinner.hide();
                    $input.show();
                    vendor.invoice_id = response.id;
                    vendor.emailed_at = response.emailed_at;
                    emailNextInvoice();
                }, function () {
                    // If failed, send the next email
                    emailNextInvoice();
                });
            } else {
                emailNextInvoice();
            }

            if (!$scope.$$phase) $scope.$apply();
        }

        $scope.emailInvoices = function (e) {

            if (!$scope.emailing) {
                $emailbutton.html('<i class="fa fa-pause"></i> Pause Emailing');
                $scope.emailing = true;

                emailNextInvoice();
                emailNextInvoice();
                emailNextInvoice();
                emailNextInvoice();

            } else {
                $emailbutton.html('<i class="fa fa-play"></i> Continue Emailing');
                $scope.emailing = false;
            }
        };

        $scope.checkAllBoxes = function (e, check_it) {

            $('.affinity-table input[type="checkbox"]').prop('checked', check_it);
        };
    }])
    .controller('', ['$scope', function ($scope) {

    }])
    .controller('LateFeesCtrl', ['$scope', '$filter', '$routeParams', 'LateFees', function ($scope, $filter, $routeParams, LateFees) {
        $scope.vendors = [];
        $scope.complete_vendors = [];
        $scope.is_loading = true;
        $scope.generating = false;
        $scope.total_due = 0;
        $scope.late_fees_left = [];

        LateFees.query({year: $routeParams.year, quarter: $routeParams.quarter}, function (response) {
            $scope.vendors = response;
            $scope.is_loading = false;

            updateCompleteVendors();
        });

        $scope.$watch('generating', function (value) {
            if (!value) updateCompleteVendors();
        });

        function updateCompleteVendors() {
            $scope.complete_vendors = [];
            $scope.total_due = 0;

            $.each($scope.vendors, function (i, vendor) {
                if (vendor.is_complete) {
                    $scope.complete_vendors.push(vendor.id);
                } else {
                    $scope.total_due += parseFloat(vendor.total_due);
                }
            });

            $scope.invoices_to_go = getInvoicesToGo();
        }

        function generateNextLateFee() {
            if (!$scope.generating) return;

            if ($scope.late_fees_left.length < 1) {
                $scope.generating = false;
                updateCompleteVendors();
                if (!$scope.$$phase) $scope.$apply();
                return;
            }

            var vendor = $scope.late_fees_left.shift();
            vendor.generateLateFee(function (response) {
                generateNextLateFee();
            });
        }

        $scope.runLateFees = function (e) {
            var $runbutton = $('#run-fees-button');

            if (!$scope.generating) {
                $runbutton.html('<i class="fa fa-pause"></i> Pause Running');
                $scope.generating = true;

                generateNextLateFee();
                generateNextLateFee();
                generateNextLateFee();
                generateNextLateFee();
            } else {
                $runbutton.html('<i class="fa fa-play"></i> Continue Late Fees');
                $scope.generating = false;
            }
        };


        function generateNextInvoice() {
            if (!$scope.generating) {
                $scope.invoices_to_go = getInvoicesToGo();
                return;
            }

            var vendor = $scope.invoices_to_go.shift();
            vendor.generateInvoice(function () {
                generateNextInvoice();
            });

            if (!$scope.$$phase) $scope.$apply();
        }

        function getInvoicesToGo() {
            return $filter('filter')($scope.vendors, function (vendor) {
                if (vendor.invoice && vendor.invoice.id > 0) return false;
                if (vendor.is_complete) return false;
                return vendor.can_invoice > 0;
            });
        }

        $scope.invoices_to_go = [];

        $scope.generateInvoices = function (e) {
            var $runbutton = $('#generate-invoices-button');

            $scope.invoices_to_go = getInvoicesToGo();

            if (!$scope.generating && $scope.invoices_to_go.length > 0) {
                $runbutton.html('<i class="fa fa-pause"></i> Pause Generation');
                $scope.generating = true;

                generateNextInvoice();
                generateNextInvoice();
                generateNextInvoice();
                generateNextInvoice();
                generateNextInvoice();
            } else {
                $runbutton.html('<i class="fa fa-play"></i> Continue Generation');
                $scope.generating = false;
            }
        };


        updateCompleteVendors();
    }])
    .controller('LateFeeItem', ['$scope', 'LateFees', function ($scope, LateFees) {

        if (!$scope.vendor.is_complete) {
            $scope.late_fees_left.push($scope.vendor);
        }

        $scope.vendor.generateLateFee = function (cbfunc) {

            $scope.is_ajax = true;

            LateFees.getLateFee({
                id: $scope.vendor.id,
                quarter: $scope.vendor.quarter,
                year: $scope.vendor.year
            }, function (response) {

                $.each(response, function (key, value) {
                    $scope.vendor[key] = value;
                });

                $scope.is_ajax = false;

                if (cbfunc) cbfunc();
            });
        };

        $scope.vendor.generateInvoice = function (cbfunc) {
            $scope.is_ajax = true;

            LateFees.generateInvoice({
                id: $scope.vendor.id,
                quarter: $scope.vendor.quarter,
                year: $scope.vendor.year
            }, function (response) {
                $scope.vendor.invoice = response;
                $scope.vendor.invoice_id = response.id;

                $scope.is_ajax = false;

                if (cbfunc) cbfunc();
            });
        };

        $scope.updateLateFee = function (e) {
            $scope.is_ajax = true;

            $scope.vendor.$save(function () {
                $scope.is_ajax = false;
                growl('Updated');
            });
        };

    }])
    .controller('VendorAlertsOverview', ['$scope', '$rootScope', '$http', 'API', 'AccountAlerts', '$routeParams', '$location', function ($scope, $rootScope, $http, API, AccountAlerts, $routeParams, $location) {

        $scope.params = $location.search();

        $scope.statusMap = {
            '0' : {'color':'success', 'title':'No Alerts'},
            '1' : {'color':'danger', 'title':'Urgent'},
            '2' : {'color':'warning', 'title':'Action Needed'},
            '3' : {'color':'info', 'title':'Info'}
        };

        $scope.sectionMap = {
            6:'Licenses',
            4:'Labels',
            5:'Designs',
            1:'Invoices',
            2:'Royalties',
            3:'Insurance'
        };

        function loadData(updateCounts) {
            $scope.is_loading = true;
            $http.get('/api/v2/vendor-alerts/', {'params':$scope.params}).then(
                function success(response) {
                    //we only want to use this flag once
                    delete($scope.params.force_refresh);
                    if(updateCounts) {
                        $scope.updateCounts();
                    }
                    $scope.is_loading = false;
                    $scope.dataObject = response.data;
                    $scope.accounts = $scope.dataObject.data;
                }
            );
        }

        $scope.updateCounts = function() {
            $http.get('/api/v2/vendor-alerts/counts', {'params':$scope.params}).then(
                function success(response) {
                    $scope.counts = response.data;
                }
            );
        };

        loadData(true);

        $scope.getMostSevere = function (accountAlerts) {

            var alertsLength = accountAlerts.length;
            var severity = 0;

            if (alertsLength !== 0) {
                severity = 3;
                for (var i = 0; i < alertsLength; i++) {
                    if ( accountAlerts[i].alert_status < severity) {
                        severity = accountAlerts[i].alert_status;
                    }
                }
            }

            var status = $scope.statusMap[severity];

            return "<i class=\"fa fa-circle text-"+status.color+"\"></i> "+status.title;
        };

        $scope.goToPage = function(pageNum) {
            $scope.updatePageNumber(pageNum);

            $scope.refreshPage(false);
        };

        $scope.updatePageNumber = function(newPage) {
            if (newPage > 1) {
                $scope.params.page = newPage;
            } else {
                delete $scope.params.page;
            }
        };

        $scope.selectStatus = function(status, statusType) {
            if (status !== null) {
                $scope.params[statusType] = status;
                $scope.updatePageNumber(1);
            } else {
                delete $scope.params[statusType];
            }
            //we want the counts to stay up to date, but alert_status has no effect on them
            if(statusType != 'alert_status') {
                $scope.updateCounts();
            }
        };

        $scope.refreshPage = function(updateCounts, forceRefresh) {
            //Scroll to top of the page
            $(".main-content").animate({ scrollTop: 0 }, 400);
            //update the url params
            $location.search($scope.params);

            if (forceRefresh) {
                $scope.params.force_refresh = 1;
            }
            //reload the data
            loadData(updateCounts);
        };

        $scope.csvParams = function () {
            var str = [];
            var search = $location.search();
            for (var p in search) {
                if (search.hasOwnProperty(p)) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(search[p]));
                }
            }
            return str.join('&');
        };
    }]);
