var sharedFilters = angular.module('sharedFilters', [])
.filter('diffForHumans', function() {
	return function(date) {
		if (!date) return date;

		if (date <= '0000-00-00 00:00:00') return '';
		
		return moment(date).fromNow();
	};
})
.filter('momentFormat', function() {
	return function(date, format) {
		if (!date) return date;
		
		if (date <= '0000-00-00 00:00:00') return '';
		
		return moment(date).format(format);
	};
})
.filter('momentDiffDaysFormat', function() {
	return function(date, format, days) {
		if (!date) return date;
		if (!days) return date;
		
		if (date <= '0000-00-00 00:00:00') return '';
		if (days <= 0) return date;
		
		var duration = moment.duration(days,'days');
		
		return moment(date).add(duration).format(format);
	};
})
.filter('momentDiffDaysFromNow', function() {
	return function(date, format, days) {
		if (!date) return date;
		if (!days) return date;
		
		if (date <= '0000-00-00 00:00:00') return '';
		if (days <= 0) return date;
		
		var duration = moment.duration(days,'days');
		
		return moment(date).add(duration).fromNow();
	};
})
.filter('isFutureDate', function() {
	return function(date) {
		return !moment(date).isBefore();
	}
})
.filter('fullname', function(){
	return function(user){
		if (!user) return user;
		
		var fullName = user.first_name + ' ' + user.last_name;
		return fullName;
	}
})
.filter('phone', function() {
	return function(input, display) {
		if (input === undefined) return input;

		if (!display) return input.replace(/[^0-9]/g, '');
		else return input;
	};
})
.filter('phase', function() {
	return function(entities, phase) {
		if (!entities) return entities;
		
		var new_entities = [];
		$.each(entities, function(i, entity) {
			if (entity.phase.id == phase) new_entities.push(entity);
		});
		return new_entities;
	};
})
.filter('url', function() {
	return function(input, display) {
		if (!input) return input;
		
		// detect http
		var found_http = input.match(/^http/);
		
		if (!display) {
			if (found_http) return input;
			else return 'http://' + input;
		} else {
			if (found_http) return input.replace(/^h.*\/\//, '');
			else return input;
		}
		
		return input;
	};
})
.filter('link', function() {
	return function(input) {
		if (!input) return input;
		
		// detect http
		var found_http = input.match(/^http/);
		
		var url = found_http ? input : 'http://' + input;
		var html = found_http ? input.replace(/^h.*\/\//, '') : input;
		
		return '<a target="_blank" href="' + url + '">' + html + '</a>';
	};
});