import portalControllers from './_controllers';

portalControllers
.controller('AddProductsCtrl', ['$scope', 'API', function($scope, API) {
	uploading_designs = 0;

	$scope.showFileChooser = function(e) {
		var $file = $('<input type="file" multiple="" />');
		$file.click();
	};

	var parts = getPathParts();
	var api_call = parts[0] + '/' + parts[1] + '/products';
	api_call += '?return-func=toBasic';

	$scope.products = [];

	$scope.updateScope = function() {
		API.call(api_call+'&limit=-1').success(function(response) {
			$scope.products = $scope.products.concat(response.data);
		});
	};

	$scope.updateScope();
}]);

var uploading_designs = 0;
