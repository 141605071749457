import portalApp from './_angular';
portalApp
.factory('Insurance', ['$resource', function($resource) {
  return $resource(
    "/api/v2/insurance/:id",
    {},
    {
      adminAction: {'method': 'POST', 'url': "/api/v2/insurance/adminAction/:id/"}
    }
  );
}])
.factory('VendorInsurance', ['$resource', function($resource) {
  return $resource("/api/v2/vendors/:id/insurance");
}])
.factory('EntityReview', ['Entity', 'USER_TYPE', function(Entity, USER_TYPE) {
  var h_warning = [], h_success = [], h_danger = [];

  function EntityHelp(entity) {
    this.entity = entity;

    this.updatePhases();
  }

  EntityHelp.prototype = {
    entity: {},
    helpers: {},

    sendDecision: function(choice, id, data, cbfunc) {
      Entity[choice]({ entityID: id }, data, function(response) {
        growlit(response);

        if (cbfunc && typeof cbfunc == 'function') cbfunc(response);
      });
    },

    approve: function(data, cbfunc) {
      this.sendDecision('approve', this.entity.entity_id, data, cbfunc);
    },

    reject: function(data, cbfunc) {
      this.sendDecision('reject', this.entity.entity_id, data, cbfunc);
    },

    setPhase: function(data, cbfunc) {
      this.sendDecision('setPhase', this.entity.entity_id, data, cbfunc);
    },

    updateHelpers: function(phase) {
      if (h_warning.indexOf(phase) > -1) this.helpers.warning = true;
      else if (h_success.indexOf(phase) > -1) this.helpers.success = true;
      else if (h_danger.indexOf(phase) > -1) this.helpers.danger = true;
      else this.helpers.default = true;
    },

    updateLicensePhases: function(phase) {
      h_success  = ['4'];
      h_danger  = ['6', '7', '8', '10'];

      switch (USER_TYPE) {
        case 'admin':
          h_warning  = ['3'];
          break;
        case 'vendor':
          h_warning  = ['1'];
          break;
        case 'client':
          h_warning  = ['2'];
          break;
      }
    },

    updateProductPhases: function(phase) {
      h_success = ['4', '14'];
      h_danger = ['6', '7', '8', '10'];

      switch (USER_TYPE) {
        case 'admin':
          h_warning = ['3'];
          break;
        case 'vendor':
          h_warning = [];
          break;
        case 'client':
          h_warning = [];
          break;
      }
    },

    updatePhases: function() {
      this.helpers = {};

      var phase  = this.entity.phase.id;
      var type  = this.entity.type;

      this.helpers.displayText = this.entity.phase.display;

      switch (type) {
        case 'license':
          this.updateLicensePhases(phase);
          break;
        case 'product':
          this.updateProductPhases(phase);
          break;
        default:
          console.warn("Unknown phase", phase);
      }

      this.updateHelpers(phase);

      if (this.helpers.warning) this.helpers.className = 'warning';
      else if (this.helpers.success) this.helpers.className = 'success';
      else if (this.helpers.danger) this.helpers.className = 'danger';
      else if (this.helpers.default) this.helpers.className = 'default';
    }
  };

  return EntityHelp;
}])
.factory('das', ['$rootScope', '$routeParams', '$location', '$timeout', function($rootScope, $routeParams, $location, $timeout) {
  var params, resource, obj_params, scope;

  function das(s, r) {
    var that = this;
    params = getParams();
    obj_params = {};
    resource = r;
    scope = s;
    s.das = this;

    this.refresh(true);

    s.$on('$routeUpdate', function(current, routes) {
      that.resourceCall(routes.params);
    });

    $timeout(function() {
      if ($('#search-object').is(':visible')) $('#search-object').focus();
    });

    s.closeModal = function(response) {
      if (response.success) {
        s.das.refresh(true);

        hideModals();
      }
    };
  }

  function deleteIfEmpty(value, obj, key) {
    if (value === undefined) delete obj[key];
    if (value === '') delete obj[key];
  }

  das.prototype = {
    formatParams: function() {
      // limit check/update so we don't have a page out of range
      if (params.page && scope.das) {
        var newTotalPages = Math.ceil(scope.das.total / scope.das.limit);
        if (newTotalPages <  params.page) params.page = newTotalPages;
      }

      return $.param(params).replace(/\+/g, '%20');
    },

    update: function(data) {
      delete this.where;
      delete this.sort;

      angular.forEach(data, function(val, key) {
        this[key] = val;
      }.bind(this));

      scope.is_loading = false;
      scope.param_str = this.formatParams();

      if (!scope.$$phase) scope.$apply();
    },

    resourceCall: function(params) {
      var parts = getPathParts();

      scope.is_loading = true;

      if (parts.length > 1 && ['clients', 'vendors'].indexOf(parts[0]) > -1) {
        // accountID = parts[1];
        params.id = parts[1];
        var method_to_call;

        if (parts[0] === 'clients' && resource.forLicensor) method_to_call = 'forLicensor';
        else if (parts[0] === 'vendors' && resource.forLicensee) method_to_call = 'forLicensee';
        else method_to_call = 'forAccount';

        resource[method_to_call](params, this.update.bind(this));
        this.getCounts(params.id);
      } else if (parts.length > 2 && parts[0] == 'markets' && parts[2] == 'blog') {
        params.marketID = $routeParams.marketID;
        resource.get(params, this.update.bind(this));
      } else {
        resource.get(params, this.update.bind(this));
        this.getCounts();
      }
    },

    getCounts: function(accountID) {
      var parts = getPathParts();
      var method_to_call;
      if (accountID) {
        if (parts[0] === 'clients' && resource.countsForLicensor) method_to_call = 'countsForLicensor';
        else if (parts[0] === 'vendors' && resource.countsForLicensee) method_to_call = 'countsForLicensee';
        else method_to_call = 'countsForAccount';

        if (resource[method_to_call]) {
          resource[method_to_call]({ id: accountID }, function(results) {
            scope.counts = results;
          });
        }
      } else if (resource.counts) {
        resource.counts(function(results) {
          scope.counts = results;
        });
      }
    },

    setResource: function(res) {
      resource = res;
    },

    refresh: function(force) {
      obj_params = convertToParamArray(params);

      $location.search(obj_params);

      if (force) {
        this.resourceCall(obj_params);
      }
    },

    addSearch: function(search) {
      params.search = search;

      deleteIfEmpty(search, params, 'search');

      this.refresh();
    },

    clearParams: function(e) {
      params = {};

      this.refresh();
    },

    //#############################################################################################################################
    //  WHERES
    //#############################################################################################################################
    addWhere: function(key, operation, value) {
      if (!params.where) params.where = {};

      if (!params.where[key]) params.where[key] = {};

      params.where[key][operation] = value;

      deleteIfEmpty(value, params.where[key], operation);

      this.refresh();
    },
    addIs: function(key, value) {
      this.addWhere(key, 'is', value);
    },
    addNot: function(key, value) {
      this.addWhere(key, 'not', value);
    },
    addBefore: function(key, value) {
      this.addWhere(key, 'before', value);
    },
    addAfter: function(key, value) {
      this.addWhere(key, 'after', value);
    },
    addBeforeEqual: function(key, value) {
      this.addWhere(key, 'before_equal', value);
    },
    addAfterEqual: function(key, value) {
      this.addWhere(key, 'after_equal', value);
    },
    addLike: function(key, value) {
      this.addWhere(key, 'like', value);
    },

    changeEndDate: function() {
      if (scope.das.end) params.end = scope.das.end;
      else if (params.end) delete params.end;

      this.refresh();
    },
    changeStartDate: function() {
      if (scope.das.start) params.start = scope.das.start;
      else if (params.start) delete params.start;

      this.refresh();
    },

    addIn: function(key, value) {
      if (!params.where) params.where = {};

      if (!params.where[key]) params.where[key] = {};

      if (!params.where[key]['in']) params.where[key]['in'] = value;
      else {
        var items = params.where[key]['in'].split(',');
        if (items.indexOf(value) < 0) params.where[key]['in'] += ',' + value;
      }

      this.refresh();
    },

    removeIn: function(key, value) {
      if (!params.where) return;

      if (!params.where[key]) return;

      if (!params.where[key]['in']) return;

      var items = params.where[key]['in'].split(',');
      var index = items.indexOf(value);
      if (index > -1) items.splice(index, 1);

      if (items.length > 0) params.where[key]['in'] = items.join(',');
      else delete params.where[key]['in'];

      this.refresh();
    },

    //#############################################################################################################################
    //  SORT
    //#############################################################################################################################
    sortBy: function(key, direction) {
      if (!params.sort) params.sort = {};

      if (!params.sort[key]) params.sort[key] = '';

      // check direction
      if (!direction) {
        switch (params.sort[key]) {
          case 'asc':
            params.sort[key] = 'desc';
            break;
          case 'desc':
            delete params.sort[key];
            break;
          default:
            params.sort[key] = 'asc';
            break;
        }
      } else {
        if (['asc', 'desc'].indexOf(direction) > -1) {
          params.sort[key] = direction;
        }
      }

      this.refresh();
    },

    setLimit: function(num) {
      var number = parseInt(num, 10);

      if (isNaN(number)) return false;
      if (number < 1) return false;

      params.limit = number;

      this.refresh();
    },

    setPage: function(num) {
      var number = parseInt(num, 10);

      if (isNaN(number)) return false;
      if (number < 1) return false;

      params.page = number;


      this.refresh();
    },

    nextPage: function() {
      this.setPage(this.current_page + 1);
    },

    prevPage: function() {
      this.setPage(this.current_page - 1);
    }
  };

  return das;
}])

.factory('gatewayApi', ['$http', '$rootScope', '$localStorage', '$location', function($http, $rootScope, $localStorage, $location) {

  var server = $location.host();

  var base_url = 'https://' + server + '/api';

  function callApi(url, data, method, successCallback, failCallback, params) {
    function clearEmptyFilters(params){
      var nonEmpty = {};
      $.each(params, function(index, value){
        if(value){
          nonEmpty[index] = value;
        }
      });
      return nonEmpty;
    }
    var makeRequest = function(request, successCallback, failCallback){
      $http(request).then(successCallback, failCallback);
    };
    var request = {
      method:method,
      url:base_url+url,
    };
    if(method == 'POST' || method == 'PUT'){
      request.data = data;
    }
    if (params) {
      request.params = clearEmptyFilters(params);
    }
    makeRequest(request, successCallback, failCallback);
  }

  function uploadFileToApiWithToken(url, file, successCallback, failCallback, progressCallback, otherData){

    var xhr = new XMLHttpRequest();

    xhr.open('POST', base_url + url);

    xhr.onload = successCallback;
    xhr.onerror = failCallback;
    xhr.upload.onprogress = progressCallback;

    var formData = new FormData();
    if(otherData.file_field){
      formData.append(otherData.file_field, file);
      delete otherData.file_field;
    } else{
      formData.append('image', file);
    }

    if (otherData) {
      $.each(otherData, function(key, val) {
        formData.append(key, val);
      });
    }

    var token = $localStorage.$default({jwt_token:null}).jwt_token;

    //if we dont have one, we need to obtain one
    if (token === null) {
      $http.get('/api/get_token').then(function(response){
        //store token
        token = response.data.data;
        $localStorage.jwt_token = token;
        xhr.setRequestHeader('Authorization', 'bearer '+token);
        //call API with newly recieved token
        xhr.send(formData);
      },
      failCallback);
    } else {
      xhr.setRequestHeader('Authorization', 'bearer '+token);
      xhr.send(formData);
    }
  }
  return {
    get: function(url, successCallback, failCallback, params) {
      callApi(url, [], "GET", successCallback, failCallback, params);
    },
    post: function(url, data, successCallback, failCallback) {
      callApi(url, data, "POST", successCallback, failCallback);
    },
    update: function(url, data, successCallback, failCallback) {
      callApi(url, data, "PUT", successCallback, failCallback);
    },

    uploadFile: function(url, file, successCallback, failCallback, progressCallback, otherData){
      uploadFileToApiWithToken(url, file, successCallback, failCallback, progressCallback, otherData);
    }
  };
}]);
