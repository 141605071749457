var sharedDirectives = angular.module('sharedDirectives', [])
.directive('initId', function() {
	return function(scope, element, attrs) {
		scope.id = attrs.initId;
	};
})
.directive('gaTrackLink', function() {
	return function(scope, element, attrs) {
		var url = scope.$eval(attrs.gaTrackLink);

		element.click(function() {
			ga('send', 'event', 'outbound', 'click', url);
		});
	};
})
.directive('alSrc', function() {
	function link(scope, element, attrs) {

		function updateImage(value) {
			if (value.substr(0, 6) == 'holder') {
				$(element).attr('data-src', value);
				Holder.run({ images: element[0] });
			} else $(element).attr('src', value);
		}

		if (attrs.nowatch) updateImage(attrs.alSrc);
		else {
			scope.$watch(attrs.alSrc, function(value) {
				if (!value) return;
				updateImage(value);
			});
		}
	}

	return {
		link: link
	};
})
.directive('alOnload', function() {
	return function(scope,ele,attrs){
		ele.load(function() {
			scope.$apply(attrs.alOnload);
		});
	};
})
.directive('alSuggest', [function() {
	function link(scope, element, attrs, ngModel) {

		var suggestOptions = {
            valueField: attrs.valueField || 'id',
        	displayField: attrs.displayField || 'name',
        	maxSelection: typeof(attrs.maxSelection) == "undefined" ? 1 : scope.$eval(attrs.maxSelection),
        	name: attrs.name || '',
        	noSuggestionText: attrs.noSuggestionText || 'No results...',
            allowFreeEntries: attrs.freeEntries ? true : false,
            data: scope.$eval(attrs.url),
            method: 'get',
            typeDelay: attrs.typeDelay || 750,
            resultsField: 'data',
			queryParam: 'search',
			id: attrs.id || ''
		};

		if (attrs.groupBy) {
			suggestOptions.groupBy = attrs.groupBy;
		}

		$('#' + attrs.id + '.ms-ctn').remove();

		var ms = $(element).magicSuggest(suggestOptions);

	    if (attrs.onselection) {
		   $(ms).on('selectionchange', scope[attrs.onselection]);
	    }

		if (attrs.modelwatch && scope[attrs.modelwatch]) {
			scope[attrs.modelwatch](ms, attrs);
		}
	}

	return {
		link: link
	};
}])
.directive('alTinymce', ['$timeout', 'USER_TYPE', function($timeout, USER_TYPE) {
	var plugins = [
		["save advlist autolink link lists charmap print hr anchor pagebreak textcolor"],
		["searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking"],
		["table directionality emoticons paste"]
	];

	var toolbar = "save | templatepicker | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link unlink";

	if (USER_TYPE == 'admin') toolbar += ' | code';

	function link(scope, element, attrs) {

		if (attrs.url) {
			var template_url = scope.$eval(attrs.url);
		}

		$timeout(function() {
			tinyMCE.baseURL = '/assets/tinymce';

			var tiny = tinymce.init({
				mode: 'exact',
				selector: '#' + element[0].id,
				theme_url: '/assets/tinymce/themes/modern/theme.js',
				skin_url: '/assets/tinymce/skins/gateway',
				plugins_url: '/assets/tinymce/plugins',
				browser_spellcheck: true,
				height: 300,
				plugins: plugins,
				menubar: false,
				autofocus: true,
				toolbar: toolbar,
				convert_urls: false,
				save_onsavecallback: function(editor) {
					editor.isNotDirty = true;

					element.parents('form').first().submit();

					return true;
				},
				init_instance_callback: function(editor) {
					if (template_url) {
						$.getJSON(template_url, {}, function(response) {
							editor.setContent(response.data);
						});
					}
				}
			});
		});
	}

	return {
		link: link
	};
}])
.directive('alLicenseTinymce', ['$timeout', '$routeParams', 'USER_TYPE', function($timeout, $routeParams, USER_TYPE) {
	var plugins = [
		["save advlist autolink link lists charmap print hr anchor pagebreak textcolor"],
		["searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking"],
		["table directionality emoticons paste template"]
	];

	var toolbar = "save | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link unlink";

	if (USER_TYPE == 'admin') toolbar += ' | code';

	function link(scope, element, attrs) {

		var template_url = scope.$eval(attrs.url);

		tinyMCE.baseURL = '/assets/tinymce';

		var tiny = tinymce.init({
			mode: 'exact',
			selector: '#' + element[0].id,
			theme_url: '/assets/tinymce/themes/modern/theme.js',
			skin_url: '/assets/tinymce/skins/gateway',
			plugins_url: '/assets/tinymce/plugins',
			browser_spellcheck: true,
			height: 500,
			plugins: plugins,
			menubar: false,
			autofocus: true,
			toolbar: toolbar,
			convert_urls: false,
			save_onsavecallback: function(editor) {
				editor.isNotDirty = true;

				var data = {
					content: editor.getContent()
				};

				$.ajax({
					url: '/api/v2/licenses/' + $routeParams.entityID + '/tpl',
					type: 'post',
					data: data,
					dataType: 'json',
					success: function(response) {
						growlit(response);
					}
				});

				return true;
			},
			init_instance_callback: function(editor) {
				function updateTemplate() {
					$.ajax({
						url: template_url,
						success: function(response) {
							editor.setContent(response);
						}
					});
				}

				element.on('update-template', function() {
					updateTemplate();
				});

				updateTemplate();
			}
		});
	}

	return {
		link: link
	};
}])
.directive('alLoadContent', function() {
	return function(scope, element, attrs) {
		var url = scope.$eval(attrs.alLoadContent);
		element.load(url);
	}
})
.directive('alFadeIn', function() {
	function link(scope, element, attrs) {
		var fadedin = false;
		$(element).css('opacity', 0);

		scope.$watch(attrs.alFadeIn, function(value) {
			if (fadedin || !value) return;

			$(element).animate({ opacity: 1 }, 500);
			fadedin = true;
		});
	}

	return {
		link: link
	};
})
.directive('alSlideDown', function() {
	function link(scope, element, attrs) {
		var fadedin = false;
		$(element).hide();

		scope.$watch(attrs.alSlideDown, function(value) {
			if (fadedin || !value) return;

			$(element).slideDown();
			fadedin = true;
		});
	}

	return {
		link: link
	};
})
.directive('alDatepicker', function() {
	function link(scope, element, attrs) {
    var picker  = new Pikaday({
      field: element[0],
      format: 'YYYY-MM-DD',
    });
	}

	return {
		link: link
	};
})
.directive('alSelectpicker', ['$timeout', function($timeout) {
	function link(scope, element, attrs, ngModel) {

		function refresh() {
			$timeout(function() {
				$(element).selectpicker('refresh');
			}, 100);
		}

		scope.$watch(attrs.alSelectpicker, function(value) {
			if (value === undefined) return;

			if (attrs.alSelectpickerClass) {
				$(element).selectpicker({
          style: attrs.alSelectpickerClass,
          iconBase: false,
				});
			}

			refresh();
    });


    // Ensures both values are strings for comparisons to work
    if (ngModel) {
      ngModel.$formatters.push(function (value) {
        return `${value}`;
      });
      ngModel.$parsers.push(function (value) {
        return `${value}`;
      });
    }


		scope.$watch(attrs.ngModel, function(value) {
      if (value === undefined) return;

			refresh();
    });

		refresh();
	}

	return {
    link: link,
    require: '?ngModel',
	};
}])
.directive('alTip', function() {
	function link(scope, element, attrs) {
		if (attrs.alTip) {
			scope.$watch(attrs.alTip, function() {
				$(element).tooltip();
			});
		} else $(element).tooltip();

	}

	return {
		link: link
	};
})
.directive('alAlertsPopover', function() {
	function link(scope, element, attrs) {
		var alerts = scope.$eval(attrs.alerts);

		var options = {
            html:true,
            trigger:"hover",
            placement:"right",
			content:"<table style='width:120px;'>"
		};

		$.each(scope.sectionMap, function(key, value){
            options.content += "<tr><td style=\"padding: 0px\">"+value+"</td><td style=\"padding: 0px\">";
            var result = alerts.find(function (element) {
                return element.account_alert_section_id == key;
            });

            if (result) {
                options.content += result.count+" <i class=\"fa fa-circle text-"+scope.statusMap[result.alert_status].color+"\"></i></td>";
            } else {
                options.content += " 0 <i class=\"fa fa-circle text-success\"></i></td>";
            }
            options.content +="</td></tr>"
        });

        options.content += "</table>";




		$(element).popover(options);

	}

	return {
		link: link
	};
})
.directive('alDelayChange', function() {

	function link(scope, element, attrs) {

		scope.$watch(attrs.ngModel, function(value) {
			if (value === undefined) return;

			var prev_value = value;

			window.setTimeout(function() {
				var cur_val = scope.$eval(attrs.ngModel);

				if (cur_val == prev_value) {
					scope.$apply(attrs.alDelayChange);
				}
			}, 500);
		});
	}

	return {
		link: link
	};
})
.directive('alEnter', function() {
	return function(scope, element, attrs) {
		element.bind("keydown keypress", function(event) {
			if(event.which === 13) {
				scope.$apply(function(){
					scope.$eval(attrs.alEnter, {'event': event});
				});

				event.preventDefault();
			}
		});
	};
})
.directive('alFormValidate', function() {
	return function(scope, element, attrs, ctrl) {
		var cbfunc		= attrs.alFormValidate;
		var growlType	= attrs.growl;

		$(element).validate({
			submitHandler: function(form) {
				var $submits	= $(element).find('[type="submit"]');
				var altText		= $submits.attr('data-alt-text');

				// disable submit button
				$submits.prop('disabled', true);
				if (altText) $submits.attr('data-prev-text', $submits.html()).html(altText);

				scope.is_loading = true;
				//$(form).trigger('submit.al-form-validate', [form]);

				$.ajax({
					url: form.action || window.location.pathname,
					type: $(form).attr('method'),
					data: $(form).serialize(),
					dataType: 'json',
					success: function(response) {
						scope.is_loading = false;
						scope.$apply();

						if (response.success) {
							if (scope[form.name]) {
								scope[form.name].$setPristine();
							}

							if (response.message) {
								if (window.growl) growl(response.message, growlType);
							}
							if (attrs.reset) form.reset();
						} else {
							if (window.growlError) growlError(response.message, growlType);
						}

						if (scope[cbfunc]) {
							scope[cbfunc](response);
						}
					},
					complete: function(response) {
						scope.is_loading = false;
						$submits.prop('disabled', false);
						if (altText) $submits.html($submits.attr('data-prev-text'));
					}
				});
			}
		});
	};
})
.directive('alGoogleMap', function() {
	var geocoder;
	var map;
	this.default_lat = 37.3842;
	this.default_lng = -98.6673;
	var sd_lat = 32.7848;
	var sd_lng = -116.7023;
	var default_zoom = 3;
	var zoomedIn = false;
	var markersArray = [];
	var that = this;
	this.auto_zooming = false;

	google.maps.Map.prototype.clearOverlays = function() {
		for (var i = 0; i < markersArray.length; i++ ) {
			markersArray[i].setMap(null);
		}
		markersArray = [];
	};

	this.autoZoom = function(latlng) {
		that.auto_zooming = true;

		//  Make an array of the LatLng's of the markers you want to show
		var LatLngList = markersArray;
		//  Create a new viewpoint bound
		var bounds = new google.maps.LatLngBounds();

		if (latlng) {
			bounds.extend(latlng);
		}

		//  Go through each...
		for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
			if (i > 5) continue;

			//  And increase the bounds to take this point
			var position = LatLngList[i].getPosition();

			bounds.extend(position);
		}

		//  Fit these bounds to the map
		map.fitBounds(bounds);

		if (map.getZoom() > 9) {
			map.setZoom(Number(9));
		}
	};

	this.createMarker = function(location, title, clickEvent) {
		var marker = new google.maps.Marker({
			position: location,
			map: map,
			title: title
		});

		markersArray.push(marker);

		if (clickEvent) {
			google.maps.event.addListener(marker, 'click', clickEvent);
		} else {
			google.maps.event.addListener(marker, "click", function() {
				$('.map-marker-detail').filter('.map-marker-detail').show();
				$('.map-marker-detail h3').html(title);
			});
		}

		return marker;
	};

	this.getLatLng = function(lat, lng) {
		return new google.maps.LatLng(lat, lng);
	};

	this.getLocation = function(value, cbfunc) {
		geocoder.geocode({ address: value }, cbfunc);
	};

	this.geocode = function(latlng, cbfunc) {
		geocoder.geocode({ latLng: latlng }, cbfunc);
	};

	this.geocodeLatLng = function(lat, lng, cbfunc) {
		that.geocode(that.getLatLng(lat, lng), cbfunc);
	};

	this.getCurrentLocation = function(goodfunc, badfunc) {
		navigator.geolocation.getCurrentPosition(goodfunc, badfunc);
	};

	this.clearMarkers = function() {
		if (map && map.clearOverlays) {
			map.clearOverlays();
		}
	};

	this.getRadius = function() {
		var bounds = map.getBounds();

		if (!bounds) return 350;

		var center = bounds.getCenter();
		var ne = bounds.getNorthEast();

		// r = radius of the earth in statute miles
		var r = 3963.0;

		// Convert lat or lng from decimal degrees into radians (divide by 57.2958)
		var lat1 = center.lat() / 57.2958;
		var lon1 = center.lng() / 57.2958;
		var lat2 = ne.lat() / 57.2958;
		var lon2 = ne.lng() / 57.2958;

		// distance = circle radius from center to Northeast corner of bounds
		return r * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));
	};

	function codeAddress() {
		var address = document.getElementById('address').value;
		geocoder.geocode( { 'address': address}, function(results, status) {
			if (status == google.maps.GeocoderStatus.OK) {
				map.setCenter(results[0].geometry.location);
				var marker = new google.maps.Marker({
					map: map,
					position: results[0].geometry.location
				});
			} else {
				alert('Geocode was not successful for the following reason: ' + status);
			}
		});
	}

	this.centerUSA = function() {
		this.goto(false, that.default_lat, that.default_lng);
	};

	function zoomMap() {
		var zoom = Number(9);
		map.setZoom(zoom);
	}

	function centerMap() {
		var lat = document.getElementById('lat').value;
		var lng = document.getElementById('lng').value;
		var latlng = that.getLatLng(lat,lng);
		map.setZoom(default_zoom);
		map.panTo(latlng);
	}

	this.goto = function(zoom,lat,lng){
		var latlng = that.getLatLng(lat,lng);
		map.panTo(latlng);
		if (zoom) map.setZoom(zoom);
		return latlng;
	};

	this.composeAddressString = function(data) {
		var address = '';

		$.each(data[0].address_components, function(i, component) {
			// this is the city
			if (component.types[0] === 'locality') address += component.short_name;
			// this is the state
			if (component.types[0] === 'administrative_area_level_1') address += ', ' + component.short_name;
		});

		return address;
	};

	function link(scope, element, attrs) {
		var params = getParams();

		scope.setMap(that);

		// use geocoder if the vendor's address is not lat/long
		geocoder = new google.maps.Geocoder();

		if (params.latitude && params.longitude) {
			that.default_lat = params.latitude;
			that.default_lng = params.longitude;
		}

		var latlng = that.getLatLng(that.default_lat, that.default_lng);

		var mapOptions = {
			center: latlng,
			zoom: default_zoom,
			streetViewControl: false,
			mapTypeControl: false,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: google.maps.ControlPosition.BOTTOM_CENTER
			},
		};

		map = new google.maps.Map(element[0], mapOptions);

		google.maps.event.addListener(map, 'center_changed', function() {
			if (that.mapChangedEvent) that.mapChangedEvent(map.getCenter(), map.getZoom(), false);
		});

		google.maps.event.addListener(map, 'zoom_changed', function() {
			if (that.mapChangedEvent) that.mapChangedEvent(map.getCenter(), map.getZoom(), true);
		});

		if (params.latitude && params.longitude) {
			var latlng = that.goto(Number(9), params.latitude, params.longitude);
			that.geocode(latlng, function(data, status) {
				var address = that.composeAddressString(data);

				if (that.updateLocationString) that.updateLocationString(address);
			});
		}
	}

	return {
		link: link
	};
});
