import portalControllers from './_controllers';
portalControllers.controller('NotificationsCtrl', ['$scope', '$window', 'Notifications', function($scope, $window, Notifications) {

	$scope.getNotifications = function() {
		Notifications.get(function(response) {
			$scope.notifications = response.data;
		});
	};

	$scope.markAsRead = function() {
		var ids = [];
		angular.forEach($scope.notifications, function(notif) {
			ids.push(notif.id);
		});

		Notifications.markViewed({ viewed: ids });
	};

	$scope.getNotifications();

}]);
