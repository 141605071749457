
import portalApp from './_angular';

portalApp.config(['$routeProvider', '$locationProvider', function($routeProvider, $locationProvider) {
	$locationProvider.html5Mode(true).hashPrefix('!');

	function returnPath(params) {
		const existingSearch = window.location.search;
		const newSearch = existingSearch ? existingSearch + '&is_angular' : '?is_angular';

		return window.location.pathname + newSearch;
	}

	var options = {
		templateUrl: returnPath,
		reloadOnSearch: false
	};

	$routeProvider
	.when('/login', options)
	.when('/login/:skin', options)
	.when('/account/finalize/:token', options)
	.when('/password', options)
	.when('/password/:path', options)
	.when('/password/reset/:token',	options)
	.when('/signup', options)
	.when('/verify-email', options)
	.when('/welcome', options)
	.when('/legal/:page', options)

  .when('/blog', { templateUrl: returnPath })
  .when('/blog/:entryID', { templateUrl: returnPath })




	//#############################################################################################################################
	//	clients
	//#############################################################################################################################
	.when('/clients', options)
	.when('/clients/:accountID', options)
	.when('/clients/:accountID/:section', options)

	.when('/clients/:accountID/reports/:subreport', { templateUrl: returnPath })
	.when('/reports/:subreport', { templateUrl: returnPath })

//   .when('/clients/:accountID/account/edit', options)

	.when('/clients/:accountID/settings/admin', options)
	// .when('/clients/:accountID/account/edit', options)
	// .when('/clients/:accountID/account/edit', options)
	// .when('/clients/:accountID/account/edit', options)

	//#############################################################################################################################
	//	clients & vendors
	//#############################################################################################################################
	.when('/:account_type/:accountID/:entity_type/:entityID/review', options)

	//#############################################################################################################################
	//	vendors
	//#############################################################################################################################
	.when('/vendors', options)
	.when('/vendors/:accountID', options)
	.when('/vendors/inquiries/:inquiryID', options)
  .when('/vendors/:accountID/:section', options)

	.when('/vendors/:accountID/settings/admin', options)

	.when('/insurance', options)
	.when('/settings/insurance', options)
	.when('/vendors/:accountID/insurance', options)
	.when('/vendors/:accountID/settings/insurance', options)

	.when('/licenses', options)
	.when('/licenses/create/:section', options)
	.when('/licenses/:entityID', options)
	.when('/:account_type/:accountID/licenses/:entityID', options)

	.when('/licenses/applications', options)
	.when('/licenses/applications/:licenseApplicationID', options)

	.when('/invoices', options)
	.when('/invoices/add', options)
	.when('/invoices/:invoiceID', options)
	.when('/vendors/:accountID/invoices/add', options)
	.when('/vendors/:accountID/invoices/:invoiceID', options)

	.when('/royalties', options)
	.when('/royalties/:royaltyId', options)
	.when('/royalties/:royaltyId/sales-data', options)
	.when('/royalties/:royaltyId/sales-data/mappings', options)
	.when('/royalties/:royaltyId/aggregate', options)
	.when('/royalties/:royaltyId/submit-review', options)
	.when('/vendors/:accountID/royalties/:royaltyId', options)

	.when('/products', options)
	.when('/products/:entityID/review', options)
	.when('/:account_type/:accountID/products/:entityID/review', options)

	/*
	//
	.when('/designs', options)
	.when('/designs/:entityID/review', options)
	.when('/:account_type/:accountID/designs/:entityID/review', options)
	*/

  // Using {} skips the extra network call
	.when('/brand', {})
	.when('/:account_type/:accountID/brand', {})

	.when('/findgreek', options)
	.when('/findgreek/users', options)
	.when('/findgreek/vendors', options)
	.when('/findgreek/tags', options)
	.when('/findgreek/collections', options)
	.when('/findgreek/products', options)
	.when('/findgreek/products/:productID', options)
	.when('/:account_type/findgreek/:accountID', options)
	.when('/:account_type/findgreek/:accountID/products', options)
	.when('/:account_type/findgreek/:accountID/products/add', options)

	//#############################################################################################################################
	//	users
	//#############################################################################################################################
	.when('/users', options)
	.when('/users/:userID', options)
	.when('/users/:userID/edit', options)
  .when('/settings/users/:userID', options)
	.when('/settings/users/:userID/edit', options)
	.when('/:account_type/:accountID/users/:userID', options)
	.when('/:account_type/:accountID/users/:userID/edit', options)
	.when('/:account_type/:accountID/settings/users/:userID', options)
	.when('/:account_type/:accountID/settings/users/:userID/edit', options)

	.when('/profile', options)
	.when('/profile/edit', options)

	.when('/admin', options)
	.when('/admin/annuals/:year', options)
	.when('/admin/late-fees/:year/:quarter', options)

	// .when('/account', {
	// 	templateUrl: '/account/edit'
	// })
//   .when('/account/edit', options)

  .when('/settings/admin', options)
	.when('/settings/billing', options)
	.when('/settings/designs', options)

	.when('/', { templateUrl: '/home/portal' })
	.when('/:param1?/:param2?/:param3?/:param4?/:param5?/:param6?/:param7?', options)

	.otherwise({
		redirectTo: '/'
	});
}]);
