import portalControllers from './_controllers';
portalControllers
    .controller('RoyaltyScheduleAddCtrl',['$scope', 'USER_TYPE', '$http', '$location', '$window', function($scope, USER_TYPE, $http, $location, $window){

        $scope.schedule = {
            is_custom: false,
            is_exempt: false,
            is_vendor_specific: false,
            is_exclusive: false,
            client_id: $location.path().split('/')[2],
            product_categories: [],
            brand_marks: [],
            distribution_channels: []
        };
        $scope.errors = {};


        var redirectUponCompletion = '/clients/'+$scope.schedule.client_id+'/royaltyschedules/';

        $scope.setSuggest = function(ms, attrs) {
            $scope[attrs.id] = ms;
        };

        function setArrayValue(arrayName, values) {
            if (values.length > 0) {
                $scope.schedule[arrayName] = values;
            } else {
                $scope.schedule[arrayName] = [];
            }

            if (!$scope.$$phase) $scope.$apply();
        }


        //categories
        $scope.categories = [];

        $http.get('/api/v1/products/categories/tree').then(
            function (response) {
                var productCategories = response.data.data;
                for(var x in productCategories){
                    parseCategoryArray(productCategories[x], '');
                }
                if ($scope.product_category_suggest) {

                  $scope.product_category_suggest.setData($scope.categories);
                }
            }
        );

        function parseCategoryArray(category, parentNameString) {
            if (category.items) {
                if (parentNameString !== '') {
                    parentNameString = parentNameString + ', ';
                }
                parentNameString = parentNameString + category.title;
                for (var x in category.items) {
                    parseCategoryArray(category.items[x], parentNameString);
                }
            } else {
                category.parent_name = parentNameString;
                $scope.categories.push(category);
            }
        }

        $scope.setProductCategories = function(e,m,r) {
            var categoriesArray = r.map(function (entry) {
                return {
                    name: entry.title,
                    id: entry.id
                };
            });
            setArrayValue('product_categories', categoriesArray);
        };

        // end categories

        // Brand Marks
        $http.get('api/brandmarks', {params:{client_id: $scope.schedule.client_id}}).then(
            function success (response) {
                const insignia = response.data.data.map(i => {
                  if (i.parent) {
                    i.title = `${i.parent.title} > ${i.title}`;
                  }
                  return i;
                });
                $scope.brand_marks_suggest.setData(insignia);
            }
        );

        $scope.setBrandMarks = function(e,m,r) {
            setArrayValue('brand_marks', r);
        };
        // End Brand Marks

        // Distribution Channels
        $http.get('api/distributionChannels', {params:{client_id: $scope.schedule.client_id}}).then(
            function success (response) {
                var channelsList = response.data.data.map(function (entry) {
                    return {
                        'id': entry.id,
                        'name': entry.name,
                        'category': entry.category.name
                    };
                });
                $scope.distribution_channels_suggest.setData(channelsList);
            }
        );

        $scope.setDistributionChannels = function(e,m,r) {
            setArrayValue('distribution_channels', r);
        };
        // End Distribution Channels

        //vendors
        $http.get('/api/vendors?minimal=1', {
            params: {
                'status[]': [1,2,3,4],
                order_by: 'short_name'
            }
        }).then(
            function success (response) {
                $scope.vendors_suggest.setData(response.data.data);
            }
        );

        $scope.setVendors = function(e,m,r) {
            setArrayValue('exclusive_vendors', m.getValue());
        };

        $scope.updateVendorsSwitch = function (element, checked) {
            $scope.schedule.is_vendor_specific = checked;

            if (!$scope.schedule.is_vendor_specific) {
                delete $scope.schedule.vendors;
                $scope.schedule.is_exclusive = false;
            }

            if (!$scope.$$phase) $scope.$apply();
        };
        //end Vendors

        $scope.updateExclusive = function (element, checked) {
            $scope.schedule.is_exclusive = checked;

            if (!$scope.$$phase) $scope.$apply();
        };

        $scope.updateCustomSwitch = function (element, checked) {
            $scope.schedule.is_custom = checked;

            if ($scope.schedule.is_custom) {
                $scope.removeNumeric();
            }

            if (!$scope.$$phase) $scope.$apply();
        };

        $scope.updateExemptSwitch = function (element, checked) {
            $scope.schedule.is_exempt = checked;

            if ($scope.schedule.is_exempt) {
                $scope.removeNumeric();
            }

            if (!$scope.$$phase) $scope.$apply();
        };

        $scope.removeNumeric = function() {
            delete $scope.schedule.royalty_rate;
            delete $scope.schedule.minimum_royalty_per_unit;
            delete $scope.schedule.minimum_guarantee;
            delete $scope.schedule.flat_royalty_per_unit;
        };

        function precheck() {
            var passed = true;
            if ($scope.schedule.royalty_rate && !/^[0-9]{1,2}(\.[0-9]{1,2})?$/.test($scope.schedule.royalty_rate)){
                $scope.errors.royalty_rate = ["Must be less than 100 and no more than 2 decimal places"];
                passed = false;
            }
            if ($scope.schedule.minimum_royalty_per_unit && !/^[0-9]+(\.[0-9]{1,2})?$/.test($scope.schedule.minimum_royalty_per_unit)){
                $scope.errors.minimum_royalty_per_unit = ["Must be a number with no more than 2 decimal places"];
                passed = false;
            }
            if ($scope.schedule.flat_royalty_per_unit && !/^[0-9]+(\.[0-9]{1,2})?$/.test($scope.schedule.flat_royalty_per_unit)){
                $scope.errors.flat_royalty_per_unit = ["Must be a number with no more than 2 decimal places"];
                passed = false;
            }
            return passed;

        }

        function parsIds(schedule) {
            var applicableFields = ['product_categories', 'brand_marks', 'distribution_channels'];
            applicableFields.forEach(function(element){
                schedule[element] = schedule[element].map(function(item){
                    return item.id;
                });
            });
            return schedule;
        }

        $scope.submit = function() {
            if(precheck()){
                $scope.is_loading = true;
                var schedulePost = Object.assign({}, $scope.schedule);
                schedulePost = parsIds(schedulePost);
                $http.post('/api/royaltySchedules', schedulePost).then(
                    function success(response) {
                        $scope.is_loading = true;
                        $location.path(redirectUponCompletion);
                        growl('success');
                    },
                    function fail(response) {
                        $scope.is_loading = false;
                        $scope.errors = response.data.errors;
                        for(var x in $scope.errors){
                            growlError($scope.errors[x]);
                        }
                    }
                );
            }
        };
    }])
    .controller('RoyaltyScheduleTemplatePreviewCtrl', ['$scope', function($scope) {
        $scope.getUnitDefinitionText = function() {
            return $scope.schedule && $scope.schedule.unit_definition ? $scope.schedule.unit_definition : 'per unit';
        };

        $scope.getProductCategoriesText = function(schedule) {
            if (schedule.product_categories.length === 0) {
                return 'All Product Categories';
            }
            return schedule.product_categories.map(function (category) {
                return category.name;
            }).join(', ');
        };

        $scope.getInsigniaText = function(schedule) {
            if (schedule.brand_marks.length === 0) {
                return 'All Insignia';
            }
            return schedule.brand_marks.map(function (mark) {
                return mark.title;
            }).join(', ');
        };

        $scope.getDistributionChannelsText = function(schedule) {
            if (schedule.distribution_channels.length === 0) {
                return 'All Distribution Channels';
            }
            return schedule.distribution_channels.map(function (channel) {
                return channel.name;
            }).join(', ');
        };

        $scope.isNonZero = function(value) {
            return value && value !== '0.00';
        };

        $scope.showRoyaltyRate = function(schedule, entity) {
            if (entity === undefined || !schedule.is_default_schedule ) {
                return true;
            }
            return entity.has_standard_royalty == '1';
        };

        $scope.showMinimumRoyalty = function(schedule, entity) {
            if (!schedule.minimum_guarantee){
                return false;
            }
            if (entity === undefined || !schedule.is_default_schedule) {
                return true;
            }
            return entity.has_minimum_royalty == '1';
        };
    }])
;
