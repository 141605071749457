

var portalApp = angular.module('portalApp', [
	'ngSanitize',
	'portalControllers',
	'portalFilters',
	'reviewControllers',
	'sharedDirectives',
	'sharedFilters',
	'sharedFactories',
	'ngRoute',
	'ngResource',
	'portalApi',
	'ui.tree',
	'ngStorage',
]).config(['$sceProvider', '$httpProvider', '$sceDelegateProvider', function($sceProvider, $httpProvider, $sceDelegateProvider) {
	$sceProvider.enabled(false);

	$httpProvider.interceptors.push(['$q', '$window', function ($q, $window) {
		return {
			'response': function (response) {
				//Will only be called for HTTP up to 300
				var headers = response.headers();

				if (headers && headers['al-redirect-url'] && headers['al-redirect-url'] != response.config.url) {

					$window.location.href = headers['al-redirect-url'];
					return $q.when(response);
				} else {
					return response;
				}
			}
		};
	}]);

	$sceDelegateProvider.resourceUrlWhitelist([
    // Allow same origin resource loads.
    'self',
    // Allow loading from our api domain.  Notice the difference between * and **.
    'https://api*.affinitygateway.*/**'
  ]);

  // The blacklist overrides the whitelist so the open redirect here is blocked.
  $sceDelegateProvider.resourceUrlBlacklist([
  ]);

}])
// .run(function($location, $rootElement) {
//   // $rootElement.off('click');
// })
.run(['$rootScope', '$compile', '$document', '$location', '$route', '$controller', '$templateCache',  function($rootScope, $compile, $document, $location, $route, $controller, $templateCache) {

	var $barWrapper = $('#slim-loading-bar');
	var $bar = $barWrapper.find('.bar');

	$('body').on('hidden.bs.modal', '.modal', function () {
		$(this).removeData('bs.modal');
	});


	$rootScope.$on('$locationChangeStart', function(event) {


		var parts = getPathParts();
		var new_parts = getPathPartsFromUrl($location.path());

		$barWrapper.fadeIn(400);
		$bar.animate({'width':'36%'},400).animate({'width':'70%'},4000);

		$('.medium-editor-toolbar').remove();
		$('.medium-editor-anchor-preview').remove();

		$('.modal.close-on-change').each(function(i, el) {
			if ($(el).is(':visible')) $(el).modal('hide');
		});

		// NAV.updatePrimaryNav($location.path());
		// NAV.loadSecondaryNav($location.path());
	});


	$rootScope.$on('$locationChangeSuccess', function() {
    $(".main-content").animate({ scrollTop: 0 }, 400);

	});

	$rootScope.$on('$viewContentLoaded', function() {
		$templateCache.removeAll();

		var title = $('body').find('title').html();
		if (title) document.title = title;


		pageLoaded();

	});

	$rootScope.closeReview = function() {
		var path = getPathParts();
		var part = path.pop();
		if (part === 'review') {
			path.pop();
			var new_path = '/' + path.join('/');
			$location.path(new_path, false);
		}

		$('#review').hide();
		$('body').removeClass('fullscreen');
	};

	$rootScope.isImage = function(file) {
		if (!file || !file.content_type) return false;

		return file.content_type.match(/image\/(jpeg|gif|jpg|png|bmp)/) ? true : false;
	};

	$('.modal').on('loaded.bs.modal', function() {
		$compile($(this))($rootScope);
	});

	var original = $location.path;
    $location.path = function (path, reload) {
        if (reload === false) {
            var lastRoute = $route.current;
            var un = $rootScope.$on('$locationChangeSuccess', function () {
                $route.current = lastRoute;
                un();
            });
        }
        return original.apply($location, [path]);
    };

}])
.constant('API_URL', '/api/v2/')
.constant('USER_TYPE', window.userType)
.constant('ACCOUNT_ID', window.accountId);

export default portalApp;

