import portalControllers from './_controllers';
portalControllers
    .controller('BrandMarksCtrl',['$scope', 'USER_TYPE', '$http', '$location', function($scope, USER_TYPE, $http, $location){

        $scope.brand_marks = [];

        $scope.selected_mark = {};

        $scope.account_id = $location.path().split('/')[2];

        $scope.selectMark = function(brandMark){
            $scope.selected_mark = brandMark;
        };

        $scope.getBrandMarks = function(){
            $scope.is_loading = true;
            $http.get('/api/brandmarks', {params:{'client_id':$scope.account_id}}).then(
                function success(response) {

                    var brandMarks = response.data.data;

                    brandMarks.sort(function (a, b) {
                        // Non-deleted brand marks come first
                        if (a.deleted_at === null && b.deleted_at !== null) {
                            return -1;
                        }
                        if (b.deleted_at === null && a.deleted_at !== null) {
                            return 1;
                        }

                        return 0;
                    });
                    $scope.brand_marks = brandMarks;
                    $scope.is_loading = false;
                },
                function fail(response) {
                    $scope.is_loading = false;
                    console.log(response.data);
                }
            );
        };
        $scope.getBrandMarks();


        $scope.deleteBrandMark = function(markId, modalId) {
            $http.delete('/api/brandmarks/' + markId).then(
                function success(response) {
                    $scope.reloadPage(modalId);
                    growl(response.data.message);
                },
                function fail(response) {
                    growlError(response.data.message);
                }
            );
        };

        $scope.createNewBrandMark = function(modalId) {
            $scope.selected_mark.client_id = $scope.account_id;
            $scope.is_loading = true;
            $http.post('/api/brandmarks', $scope.selected_mark).then(
                function success(response) {
                    $scope.reloadPage(modalId);
                    growl('success!');
                },

                function fail(response) {
                    $scope.is_loading = false;
                    $scope.errors = response.data.errors;
                    for(var x in $scope.errors){
                        growlError($scope.errors[x]);
                    }
                }
            );
        };
        $scope.reloadPage = function (modalId) {
            //Close the modal
            $('#'+modalId).modal('toggle');
            //reset the selected_schedule
            $scope.selected_mark = {};
            //Refresh list
            $scope.getBrandMarks();
        };
    }])
;
