import portalApi from './api';
function resourceForAccount(API_URL, path_part, params) {
	return {
		method: 'get',
		url: API_URL + 'accounts/:id/' + path_part,
		params: params
	};
}

function resourceForLicensor(API_URL, path_part, params) {
	return {
		method: 'get',
		url: API_URL + 'clients/:id/' + path_part,
		params: params
	};
}

function resourceForLicensee(API_URL, path_part, params) {
	return {
		method: 'get',
		url: API_URL + 'vendors/:id/' + path_part,
		params: params
	};
}

function resourceCounts(API_URL, path_part, params) {
	return {
		method: 'get',
		url: API_URL + path_part + '/counts',
		params: params
	};
}

function resourceCountsForAccount(API_URL, path_part, params) {
	return {
		method: 'get',
		url: API_URL + 'accounts/:id/' + path_part + '/counts',
		params: params
	};
}

function resourceCountsForLicensor(API_URL, path_part, params) {
	return {
		method: 'get',
		url: API_URL + 'clients/:id/' + path_part + '/counts',
		params: params
	};
}

function resourceCountsForLicensee(API_URL, path_part, params) {
	return {
		method: 'get',
		url: API_URL + 'vendors/:id/' + path_part + '/counts',
		params: params
	};
}

portalApi
.factory('User', ['$resource', function($resource) {
	return $resource('/api/v1/users/:id', { id: '@id' });
}])
.factory('Vendor', ['$resource', function($resource) {
	return $resource('/api/v1/account/:id');
}])
.factory('AccountProfileVersions', ['$resource', function($resource) {
	return $resource('/api/v1/account/:id/versioning/status');
}])
.service('gChart', function() {
	// Load the Visualization API and the piechart package.
	google.load('visualization', '1', { 'packages': ['corechart', 'controls'], callback: tempFunc });

	var that = this;

	var funcs = [];

	that.has_initialized = false;

	function callFunc(func) {
		func();
	}

	this.pushFunction = function(func) {
		if (that.has_initialized) callFunc(func);
		else funcs.push(func);
	};

	function tempFunc() {
		that.has_initialized = true;

		if (funcs.length) {
			angular.forEach(funcs, function(func, i) {
				callFunc(func);
			});
		}
	}
})
.factory('Account', ['$resource', function($resource) {
	return $resource('/api/v2/accounts/:accountID', { accountID: '@id' });
}])
.factory('AccountWithTransformer', ['$resource', function($resource) {
	return $resource('/api/accounts/:accountID');
}])
.factory('AccountProfileUpdate', ['$resource', function($resource) {
	return $resource('/api/account-updates/current?account_id=:accountID');
}])
.factory('AccountAlerts', ['$resource', function($resource) {
	return $resource('/api/v2/flags', {}, {
		getForVendor: {'method': 'GET', url: '/api/v2/vendors/:accountID/flags'},
		getForCurrent: {'method': 'GET', url: '/api/v2/flags'}
	});
}])
.factory('AccountProfile', ['$resource', function($resource) {
	return $resource('/api/v2/accounts/:accountID/profile', { accountID: '@account_id' });
}])
.factory('Activity', ['$resource', function($resource) {
	return $resource('/api/v2/entities/:entityID/activity/:activityID', { entityID: '@entity_id', activityID: '@id' });
}])
.factory('AdminResource', ['$resource', function($resource) {
	return $resource('/api/v2/admin', {});
}])
.factory('AnnualRenewals', ['$resource', function($resource) {
	return $resource('/api/v2/admin/annuals/:year', { year: '@year', vendorID: '@vendorID' }, {
		generateInvoice: {
			method: 'POST',
			url: '/api/v2/admin/annuals/:year/vendors/:vendorID'
		},
		emailInvoice: {
			method: 'POST',
			url: '/api/v2/admin/annuals/:year/vendors/:vendorID/email'
		}
	});
}])
.factory('BrandMarks', ['$resource', 'API_URL', function($resource, API_URL) {
	var section = 'brand';
	var extras = {};
	extras.categories = { method: 'GET', url: API_URL + section + '/categories', isArray: true };
	extras.forLicensor = resourceForLicensor(API_URL, section);
	extras.forLicensee = resourceForLicensee(API_URL, section);

	return $resource(API_URL + section + '/:markID', { markID: '@id' }, extras);
}])
.factory('ClientAnnualReports', ['$resource', function($resource) {
	return $resource('/api/v2/accounts/:accountID/annual-reports/:reportID', { accountID: '@account_id', reportID: '@id' });
}])
.factory('Clients', ['$resource', 'API_URL', function($resource, API_URL) {
	var section = 'clients';
	var extras = {};
	extras.counts = resourceCounts(API_URL, section);

	return $resource(API_URL + section + '/:clientID', { clientID: '@id' }, extras);
}])
.factory('Inquiries', ['$resource', 'API_URL', function($resource, API_URL) {
	var section = 'inquiries';
	var extras = {};
	extras.forAccount = { method: 'GET', url: API_URL + section };

	return $resource(API_URL + section + '/:inquiryID', { inquiryID: '@id' }, extras);
}])
.factory('Invoices', ['$resource', 'API_URL', function($resource, API_URL) {
	var section = 'invoices';
	var extras = {};
	extras.counts = resourceCounts(API_URL, section);
	extras.forLicensor = resourceForLicensor(API_URL, section);
	extras.forLicensee = resourceForLicensee(API_URL, section);
	extras.countsForLicensor = resourceCountsForLicensor(API_URL, section);
	extras.countsForLicensee = resourceCountsForLicensee(API_URL, section);
	extras.payments = { method: 'GET', url: API_URL + section + '/:invoiceID/payments', isArray: true };
	extras.saved = { method: 'GET', url: API_URL + 'vendors/:accountID/' + section + '/saved' };
	extras.duplicate = { method: 'POST', url: API_URL + section + '/:invoiceID/duplicate'};

	return $resource(API_URL + section + '/:invoiceID', { invoiceID: '@id' }, extras);
}])
.factory('LateFees', ['$resource', function($resource) {

	return $resource('/api/v2/admin/late-fees/:year/:quarter/vendors/:id', { year: '@year', quarter: '@quarter', id: '@id' }, {
		getLateFee: {
			method: 'GET',
			url: '/api/v2/admin/late-fees/:year/:quarter/vendors/:id'
		},
		generateInvoice: {
			method: 'POST',
			url: '/api/v2/admin/late-fees/:year/:quarter/vendors/:id/invoice'
		}
	});
}])
.factory('Licenses', ['$resource', function($resource) {
	return $resource('/api/v2/licenses/:licenseID', { licenseID: '@entity_id' });
}])
.factory('LicenseApplications', ['$resource', function($resource) {
	return $resource('/api/v2/licenses/applications/:licenseApplicationID', { licenseApplicationID: '@id' });
}])
.factory('LicenseProducts', ['$resource', function($resource) {
	return $resource('/api/v2/licenses/:licenseID/products');
}])
.factory('Markets', ['$resource', 'API_URL', function($resource, API_URL) {
	var section = 'markets';
	var extras = {};
	extras.counts = resourceCounts(API_URL, section);

	return $resource('/api/v2/' + section + '/:marketID', { marketID: '@id' }, extras);
}])
.factory('MarketBlogEntries', ['$resource', 'API_URL', function($resource, API_URL) {
	var section = 'blog';
	var extras = {};
  // extras.counts = resourceCounts(API_URL, section);

	return $resource('/api/v2/' + section + '/:entryID', { entryID: '@id' }, extras);
}])
.factory('Notifications', ['$resource', 'API_URL', function($resource, API_URL) {
	return $resource(API_URL + 'notifications', {}, {
		markViewed: {
			method: 'POST',
			url: API_URL + 'notifications'
		}
	});
}])
.factory('ProductCategory', ['$resource', function($resource) {
	return $resource('/api/v2/product-categories/:categoryID', {}, {
		inOrder: {
			method: 'GET',
			url: '/api/v2/product-categories/in-order'
		}
	});
}])
.factory('ProductRules', ['$resource', function($resource) {
	return $resource('/api/v2/accounts/:accountID/rules/:ruleID');
}])
.factory('Products', ['$resource', function($resource) {
	return $resource('/api/v2/products/:productID', { productID: '@entity_id' }, {
		forVendor: {
			method: 'GET',
			url: '/api/v2/vendors/:vendorID/products',
			isArray: true
		}
	});
}])
.factory('RoyaltyReports', ['$resource', 'API_URL', function($resource, API_URL) {
	var section = 'royalties';
	var extras = {};
	extras.counts = resourceCounts(API_URL, section);
	extras.forLicensor = resourceForLicensor(API_URL, section);
	extras.forLicensee = resourceForLicensee(API_URL, section);
	extras.countsForLicensor = resourceCountsForLicensor(API_URL, section);
	extras.countsForLicensee = resourceCountsForLicensee(API_URL, section);
	extras.clients = {
		method: 'GET',
		url: API_URL + section + '/clients',
		isArray: true
	};
	extras.regenerateInvoice = {
		method: 'POST',
		url: API_URL + section + '/:royaltyID/regenerate-invoice'
	};

	return $resource(API_URL + section + '/:royaltyID', { royaltyID: '@id' }, extras);
}])
.factory('Stats', ['$resource', function($resource) {
	return $resource('/api/v2/stats/:type/:id');
}])
.factory('Users', ['$resource', 'API_URL', function($resource, API_URL) {
	var section = 'users';
	var extras = {};
	extras.scopes = { url: API_URL + section + '/:userID/scopes', method: 'GET', isArray: true };
	extras.profile = { url: API_URL + 'profile', method: 'GET' };
	extras.forLicensor = resourceForLicensor(API_URL, section);
	extras.forLicensee = resourceForLicensee(API_URL, section);

	return $resource(API_URL + section + '/:userID', { userID: '@id' }, extras);
}])
.factory('Entity', ['$resource', function($resource) {
	return $resource('/api/v2/entities/:entityID', { entityID: '@entity_id' }, {
		approve: {
			method: 'POST',
			url: '/api/v2/entities/:entityID/approve'
		},
		reject: {
			method: 'POST',
			url: '/api/v2/entities/:entityID/reject'
		},
		setPhase: {
			method: 'POST',
			url: '/api/v2/entities/:entityID/set-phase'
		}
	});
}]);
