import portalApp from './_angular';

portalApp
    .directive('format', ['$filter', function ($filter) {
        return {
            require: '?ngModel',
            link: function (scope, elem, attrs, ctrl) {
                if (!ctrl) return;

                var format = {
                    prefix: '',
                    centsSeparator: '.',
                    thousandsSeparator: '',
                    allowNegative: true,
                };

                ctrl.$parsers.unshift(function (value) {
                    elem.priceFormat(format);
                    return elem[0].value;
                });

                ctrl.$formatters.unshift(function (value) {
                    if (value === null) return value;
                    elem[0].value = Math.round(ctrl.$modelValue * 100);
                    elem.priceFormat(format);
                    return elem[0].value;
                });
            }
        };
    }])
    .directive('alTableRoyaltyDueDate', function () {
        return function (scope, element, attrs) {
            var report = scope.$eval(attrs.alTableRoyaltyDueDate);
            if (!report) return;

            var next_year = parseInt(report.year, 10) + 1;
            var year, month, day;

            switch (report.quarter) {
                case '1':
                    year = report.year;
                    month = 'OCT';
                    day = 30;
                    break;
                case '2':
                    year = next_year;
                    month = 'JAN';
                    day = 30;
                    break;
                case '3':
                    year = next_year;
                    month = 'APR';
                    day = 30;
                    break;
                case '4':
                    year = next_year;
                    month = 'JUL';
                    day = 30;
                    break;
            }

            var $div = $('<div class="table-date" />');
            $div.append('<span class="table-date-month text-primary">' + month + '</span>');
            $div.append('<span class="table-date-day">' + day + '</span>');
            $div.append('<span class="table-date-year"><span>' + year + '</span></span>');
            element.html($div);
        };
    })
    .directive('alTableSalesFrom', ['USER_TYPE', function (USER_TYPE) {
        return function (scope, element, attrs) {
            var report = scope.$eval(attrs.alTableSalesFrom);
            if (!report) return;

            var url = report.url;
            if (USER_TYPE == 'admin') url = report.vendor.url + url;

            var $div = $('<a href="' + url + '" />');
            var next_year = parseInt(report.year, 10) + 1;

            var text = '';

            switch (report.quarter) {
                case '1':
                    text = 'Jul 1 - Sep 30, ' + report.year;
                    break;
                case '2':
                    text = 'Oct 1 - Dec 31, ' + report.year;
                    break;
                case '3':
                    text = 'Jan 1 - Mar 31, ' + next_year;
                    break;
                case '4':
                    text = 'Apr 1 - Jun 30, ' + next_year;
                    break;
            }

            $div.append('<strong>' + text + '</strong>');
            $div.append('<small>Q' + report.quarter + ' ' + report.year + '-' + next_year.toString().substr(2) + ' FY</small>');
            element.append($div);
        };
    }])
    .directive('alTableDate', function () {
        return function (scope, element, attrs) {
            var date = scope.$eval(attrs.alTableDate);

            if (!date) return;

            var momentYear = moment(date).format('YYYY');
            var momentMonth = moment(date).format('MMM').toUpperCase();
            var momentDay = moment(date).format('DD');
            var $div = $('<div class="table-date" />');
            $div.append('<span class="table-date-month text-primary">' + momentMonth + '</span>');
            $div.append('<span class="table-date-day">' + momentDay + '</span>');
            $div.append('<span class="table-date-year"><span>' + momentYear + '</span></span>');
            element.html($div);
        };
    })
    .directive('alTableLocation', function() {
      return function (scope, element, attrs) {
        var account = scope.$eval(attrs.alTableLocation);
        if (!account) {
          return;
        }
        const state = account.state;
        if (!state) {
          return;
        }
        const city = account.city;
        var $div = $('<div />');
        if (city) {
          $div.append(city + ', ');
        }
        $div.append(state);
        element.html($div);
      };
    })
    .directive('alTableQuarter', function () {
        return function (scope, element, attrs) {
            var obj = scope.$eval(attrs.alTableQuarter);

            var num_year = parseInt(obj.year.substr(2), 10);
            var year = num_year + '-' + (num_year + 1);
            //var year = obj.year;
            var quarter = 'Q' + obj.quarter;
            var $div = $('<div class="table-date table-date-royalties" />');
            $div.append('<span class="table-date-month text-primary">' + year + '</span>');
            $div.append('<span class="table-date-day">' + quarter + '</span>');
            //$div.tooltip();
            element.html($div);
        };
    })
    .directive('alClientAccountNameLink', ['USER_TYPE', function (USER_TYPE) {
      return function (scope, element, attrs) {
        var account = scope.$eval(attrs.alClientAccountNameLink);
        var name = account.short_name.trim();
        var imgSrc = account.image.th.substr(0, 6) == 'holder' ? 'data-src="' + account.image.th + '"' : 'src="' + account.image.th + '"';
        var $img = $('<img class="pull-left hidden-xs" ' + imgSrc + '>');
        if (account.image.th.substr(0, 6) == 'holder') {
          Holder.run({images: $img[0]});
        }

        var parts = getPathParts();
        var currentFeature = parts.pop();
        var $a;
        if (USER_TYPE === 'admin') {
          $a = $('<a class="load-account client-td" href="' + account.url + '" />');
        } else if (USER_TYPE == 'vendor') {
          if (currentFeature == '/brand') {
            $a = $('<a class="load-account client-td" href="' + account.url + currentFeature + '" />');
          } else {
            $a = $('<a class="load-account client-td"/>');
          }

        } else {
          $a = $('<a href="#" data-remote="/ajax/snippets/account/' + account.id + '/vendor-modal" data-toggle="modal" data-target="#large-modal" />');
        }

        name = name || '<span style="color: #ccc;">Missing Name</span>';

        var $name = $('<strong style="text-overflow:ellipsis;overflow:hidden">' + name + '</strong>');
        $a.append($img);
        $a.append($name);
        element.append($a);
      };
    }])
    .directive('alAccountNameLink', ['USER_TYPE', function (USER_TYPE) {
        return function (scope, element, attrs) {
            var account = scope.$eval(attrs.alAccountNameLink);


            var name = account.short_name.trim();
            var imgSrc = account.image.th.substr(0, 6) == 'holder' ? 'data-src="' + account.image.th + '"' : 'src="' + account.image.th + '"';
            var $img = $('<img class="pull-left hidden-xs" ' + imgSrc + '>');
            if (account.image.th.substr(0, 6) == 'holder') Holder.run({images: $img[0]});

            var parts = getPathParts();
            var currentFeature = parts.pop();

            if (currentFeature == 'clients' || currentFeature == 'vendors' || currentFeature == 'tags' ||  currentFeature == 'alerts') {
                currentFeature = '';
            } else if (currentFeature == 'review') {
                parts.pop();
                currentFeature = '/' + parts.pop();
            } else if (currentFeature === 'insurance') {
                currentFeature = '/settings/insurance';

            } else {
                currentFeature = '/' + currentFeature;
            }

            var location = account.city !== '' ? account.city + ', ' + account.state : account.state;
            name = name || '<span style="color: #ccc;">Missing Name</span>';

            var $name = $('<strong>' + name + '</strong>');

            var $aliases = $('<small>' + (account.materialized_aliases ? account.materialized_aliases : account.name) + '</small>');

            var $a;
            if (USER_TYPE === 'admin') {
                $a = $('<a class="load-account" href="' + account.url + currentFeature + '" />');
            } else if (USER_TYPE == 'vendor') {
                if (currentFeature == '/brand') {
                    $a = $('<a class="load-account" href="' + account.url + currentFeature + '" />');
                } else {
                    $a = $('<a class="load-account"/>');
                }

            } else {
                $a = $('<a href="#" data-remote="/ajax/snippets/account/' + account.id + '/vendor-modal" data-toggle="modal" data-target="#large-modal" />');
            }

            $a.append($img);
            $a.append($name);
            $a.append($aliases);
            element.append($a);
        };
    }])
    .directive('alInsuranceStatus', ['USER_TYPE', function (USER_TYPE) {
        return function (scope, element, attrs) {
            var policy = scope.$eval(attrs.alInsuranceStatus);
            var statusId = policy.phase;
            var action = false;
            var statusLabel;
            var statusBtnClass;
            var statusIcon;
            var linkUrl = '/vendors/' + (!policy.account ? policy.id : policy.account.id) + '/settings/insurance';
            console.log('test');

            switch (statusId) {
                case '1':
                    statusLabel = "Vendor Review";
                    statusBtnClass = 'info';
                    statusIcon = 'dot-circle-o';
                    break;
                case '2':
                    statusLabel = "Admin Review";
                    statusBtnClass = 'info';
                    statusIcon = 'dot-circle-o';
                    action = true;
                    break;
                case '3':
                    if (policy.expiring || policy.expired) {
                        if (policy.expiring) {
                            statusLabel = "Expiring Soon";
                            statusIcon = 'calendar';
                            statusBtnClass = 'info';
                        } else {
                            statusLabel = "Policy Expired";
                            statusIcon = 'calendar-times-o';
                            statusBtnClass = 'danger';
                        }
                    } else {
                        statusLabel = "Accepted";
                        statusBtnClass = 'success';
                        statusIcon = 'check';
                    }
                    break;
                default:
                    statusLabel = "Missing";
                    statusBtnClass = 'danger';
                    statusIcon = 'exclamation-triangle';
            }
            var $el;

            if (action) {
                $el = $('<a class="btn btn-sm btn-block btn-' + statusBtnClass + '" href="' + linkUrl + '">' + statusLabel + '</a>');
            } else {
                $el = $('<a href="' + linkUrl + '" style="font-weight: bold; font-size: 12px; padding: 10px 0px; line-height:1; text-align: center;" class="text-' + statusBtnClass + '"><i class="fa fa-' + statusIcon + '"></i>&nbsp; ' + statusLabel + '</a>');
            }

            element.append($el);
        };

    }])
    .directive('alMarketNameLink', ['USER_TYPE', function (USER_TYPE) {
        return function (scope, element, attrs) {
            var account = scope.$eval(attrs.alMarketNameLink);


            var marketName = account.market.name.trim();

            var imgSrc = account.market.image.th.substr(0, 6) == 'holder' ? 'data-src="' + account.market.image.th + '"' : 'src="' + account.market.image.th + '"';
            var $img = $('<img class="pull-left hidden-xs" ' + imgSrc + '>');
            if (account.market.image.th.substr(0, 6) == 'holder') Holder.run({images: $img[0]});

            var parts = getPathParts();
            var currentFeature = parts.pop();

            if (currentFeature == 'clients' || currentFeature == 'vendors') {
                currentFeature = '';
            } else if (currentFeature == 'review') {
                parts.pop();
                currentFeature = '/' + parts.pop();
            } else {
                currentFeature = '/' + currentFeature;
            }

            // var location = account.city !== '' ? account.city + ', ' + account.state : account.state; // REMOVE

            var $name = $('<strong>' + marketName + '</strong>');

            var $a;
            if (USER_TYPE === 'admin') {
                $a = $('<a href="?where%5bmarket_id%5D%5Bis%5D=' + account.market.id + '" />');
            } else if (USER_TYPE == 'vendor') {
                $a = $('<a href="#" />');
            } else {
                $a = $('<a href="#" />');
            }

            $a.append($img);
            $a.append($name);
            element.append($a);
        };
    }])
    .directive('alLicenseStatusLabel', ['USER_TYPE', function (USER_TYPE) {
        return function (scope, element, attrs) {

            var object = scope.$eval(attrs.alLicenseStatusLabel);

            var btnClass = 'default';
            var action = false;
            var batch = false;
            var btnText = '';
            var icon = 'dot-circle-o';
            var $el;
            switch (object.phase.id) {
                // vendor review
                case '1':
                    if (USER_TYPE === 'vendor') {

                        if (object.is_refresh == 1) {
                            btnText = 'Continue';
                            batch = true;
                        } else {
                            btnText = 'Sign Now';
                        }
                        btnClass = 'info';
                        action = true;
                    }
                    else {
                        btnClass = 'info';
                    }
                    break;
                // client review
                case '2':
                    if (USER_TYPE === 'client') {
                        if (object.is_refresh == 1) {
                            btnText = 'Batch Sign';
                            batch = true;
                        } else {
                            btnText = 'Sign Now';
                        }
                        btnClass = 'info';

                        action = true;
                    }
                    else {
                        btnClass = 'info';
                    }
                    break;
                // admin review
                case '3':
                    if (USER_TYPE === 'admin') {
                        btnClass = 'info';
                        btnText = 'Review Now';
                        action = true;
                    }
                    else {
                        btnClass = 'info';
                    }
                    break;

                // potential
                case '13':
                    btnClass = 'info';
                    break;

                case '4':
                case '14':
                    btnClass = 'success';	// Licensed or Approved
                    icon = 'check';
                    break;

                case '5':
                    btnClass = 'success';	// One-Time
                    icon = 'check';
                    break;

                case '6':				// Declined by vendor
                case '7':				// by Client
                case '8':				// by Admin
                case '12':				// illegal
                    btnClass = 'danger';
                    icon = 'times';
                    break;

                case '9':				// Awaiting Paper Signature
                    btnClass = 'info';
                    break;

                case '10':				// Cancelled
                    btnClass = 'danger';
                    icon = 'times';
                    break;

                case '11':				// Cancelled
                    btnClass = 'danger';
                    icon = 'times';
                    break;

                default:
                    if (USER_TYPE === 'vendor') {
                        var $a = $('<a title="Not Licensed, please apply." data-placement="left" href="/licenses/apply/organizations?select=' + object.id + '" class="btn btn-info btn-sm btn-block">Apply Now</a>');
                        // $a.append('<i class="fa fa-arrow-right icon-right"></i>');
                        element.append($a);
                        return;
                    }
                    break;
            }

            var parts = getPathParts();
            var path = '/licenses/' + (batch ? 'batch' : object.entity_id);
            if (USER_TYPE == 'admin') {
                if (parts[0] != 'licenses') {
                    path = '/' + parts[0] + '/' + parts[1] + path;
                }
            }

            if (USER_TYPE == 'vendor' && object.phase.id == 3) {
                btnText = object.phase.display;
                $el = $('<button style="background: none; border: none; width: 100%; outline: none; font-weight: bold; font-size: 12px; padding: 10px 0px; line-height:1; text-align: center;" class="text-' + btnClass + '"><i class="fa fa-' + icon + '"></i>&nbsp; ' + btnText + '</button>');
                element.append($el);
                return;
            } else if (action) {
                $el = $('<a href="' + path + '" class="btn btn-sm btn-block btn-' + btnClass + '">' + btnText + '</a>');
            } else {
                btnText = object.phase.display;
                $el = $('<a href="' + path + '" style="background: none; border: none; width: 100%; outline: none; font-weight: bold; font-size: 12px; padding: 10px 0px; line-height:1; text-align: center;" class="text-' + btnClass + '"><i class="fa fa-' + icon + '"></i>&nbsp; ' + btnText + '</a>');
            }

            element.append($el);

            if (USER_TYPE == 'admin') {
                $el.mousedown(function () {
                    if (window.location.search) {
                        $el.prop('href', path + window.location.search);
                    }
                });
            }
        };
    }])
    .directive('alInsurancePrompt', function () {
        return {
            link: function ($scope, element, attrs) {
                function render() {
                    var displayable = false;
                    if ($scope.creatingNew) {
                        element.css('display', 'none');
                        return;
                    }

                    if ($scope.insurance.old && !$scope.insurance.new) {
                        displayable = true;
                    }

                    if (!$scope.insurance.old) {
                        // If there is no existing policy
                        displayable = true;
                    } else {

                        var currentTs = (new Date()).getTime();
                        var expirationTs = (new Date($scope.insurance.old.valid_end)).getTime();

                        // If existing policy is expiring soon (30 days)
                        // or existing policy is already expired
                        if (expirationTs - currentTs < (1000 * 60 * 60 * 24) * 30) {
                            displayable = true;
                        }

                    }

                    if (displayable) {
                        element.css('display', 'block');
                    } else {
                        element.css('display', 'none');
                    }
                }

                $scope.$watch('insurance.old', function () {
                    render();
                });

                render();

            }
        };
    })
    .directive('alApplicationStatusLabel', ['USER_TYPE', function (USER_TYPE) {
        return function (scope, element, attrs) {

            /* NEW DIRECTIVE */

            var application = scope.$eval(attrs.alApplicationStatusLabel);

            var btnClass = 'default';
            var action = false;
            // var btnText = invoice.payment_status.display;
            var btnText = "Button Text";
            var icon = 'dot-circle-o';
            var $el;

            switch (application.status) {
                // In Progress
                case '1':
                    btnClass = 'info';
                    btnText = 'In Progress';
                    break;
                // Admin Review
                case '2':
                    btnClass = 'info';
                    action = true;
                    btnText = 'Review Now';
                    break;
                // Pending Refresh
                case '3':
                    btnClass = 'info';
                    btnText = 'Pending Refresh';
                    break;
                // Approved
                case '4':
                    btnClass = 'success';
                    btnText = 'Approved';
                    icon = 'check';
                    break;
                // Rejected
                case '5':
                    btnClass = 'danger';
                    icon = 'times';
                    btnText = 'Rejected';
                    break;
            }

            // var url = '';
            var url = USER_TYPE == 'admin' ? '/vendors/' + application.vendor.id + '/licenses/applications/' + application.id : application.id;

            if (action) {
                $el = $('<a class="btn btn-sm btn-block btn-' + btnClass + '" href="' + url + '">' + btnText + '</a>');
            } else {
                $el = $('<a href="' + url + '" style="font-weight: bold; font-size: 12px; padding: 10px 0px; line-height:1; text-align: center;" class="text-' + btnClass + '"><i class="fa fa-' + icon + '"></i>&nbsp; ' + btnText + '</a>');
            }

            element.append($el);

        };
    }])
    .directive('alInvoiceType', ['USER_TYPE', function (USER_TYPE) {
        return function (scope, element, attrs) {
            var invoice = scope.$eval(attrs.alInvoiceType);

            var url = USER_TYPE == 'admin' ? invoice.account.url + invoice.url : invoice.url;
            var label = 'success';
            var displayText = invoice.invoice_type.display;

            switch (invoice.invoice_status.id) {
                // open
                case '1':
                    label = 'danger';
                    break;
                // closed
                case '2':
                    label = 'success';
                    break;
                // waived, voided
                case '3':
                case '4':
                    label = 'info';
                    break;
            }

            switch (invoice.invoice_type.id) {
                // annual
                case '2':
                    var year = invoice.fiscal_year > 0 ? invoice.fiscal_year + ' ' : '';
                    displayText = year + displayText;
                    break;
                // royalty payment
                case '11':
                    if (invoice.fiscal_year) {
                        var next_year = parseInt(invoice.fiscal_year.substr(2), 10) + 1;
                        displayText += ' Q' + invoice.quarter + ' ' + invoice.fiscal_year + '-' + next_year;
                    }
                    break;
                case '18':
                  if (invoice.fiscal_year) {
                    var next_year = parseInt(invoice.fiscal_year.substr(2), 10) + 1;
                    displayText += ' Q' + invoice.quarter + ' ' + invoice.fiscal_year + '-' + next_year;
                  }
                  break;
            }

            var $a = $('<a style="color: #999; font-weight: bold;" class="load-account" href="' + url + '" />');
            $a.append(displayText + '<div style="font-size: 85%; line-height:1;" class="text-' + label + '"><i class="fa fa-bullseye"></i> #' + invoice.id + '</div>');
            element.append($a);
        };
    }])
    .directive('alInvoiceStatusLabel', ['USER_TYPE', function (USER_TYPE) {
        return function (scope, element, attrs) {

            var invoice = scope.$eval(attrs.alInvoiceStatusLabel);

            var btnClass = 'default';
            var action = false;
            var btnText = invoice.payment_status.display;
            var icon = 'dot-circle-o';
            var $el;

            switch (invoice.invoice_status.id) {
                // open
                case '1':
                    btnClass = 'danger';
                    btnText = 'View Invoice';
                    action = true;
                    if (parseFloat(invoice.amount_total) < 0) {
                        action = false;
                        btnClass = 'info';
                        icon = 'dot-circle-o';
                        btnText = 'Refund';
                    }
                    break;
                // closed
                case '2':
                    btnClass = 'success';
                    icon = 'check';
                    break;
                // waived
                case '3':
                    btnClass = 'info';
                    icon = 'dot-circle-o';
                    break;
                // voided
                case '4':
                    btnClass = 'info';
                    icon = 'dot-circle-o';
                    break;
            }

            switch (invoice.invoice_type.id) {
                // refund
                case '10':
                    if (invoice.invoice_status.id > 1) {
                        btnClass = 'success';
                        btnText = 'Refunded';
                        icon = 'check';
                    } else {
                        btnClass = 'info';
                        btnText = 'Pending Refund';
                        icon = 'dot-circle-o';
                    }

                    action = false;
                    break;
            }

            var url = USER_TYPE == 'admin' ? invoice.account.url + invoice.url : invoice.url;

            if (action) {
                $el = $('<a class="btn btn-sm btn-block btn-' + btnClass + '" href="' + url + '">' + btnText + '</a>');
            } else {
                $el = $('<a href="' + url + '" style="font-weight: bold; font-size: 12px; padding: 10px 0px; line-height:1; text-align: center;" class="text-' + btnClass + '"><i class="fa fa-' + icon + '"></i>&nbsp; ' + btnText + '</a>');
            }

            element.append($el);
        };
    }])
    .directive('alMedium', function () {
        function link(scope, element, attrs) {
            var options = {
                targetBlank: true,
                buttonLabels: 'fontawesome'
            };
            if (attrs.type) {
                if (attrs.type == 'brief') options.buttons = ['bold', 'italic', 'underline', 'anchor'];
                else options.buttons = ['bold', 'italic', 'underline', 'anchor', 'header1', 'header2', 'unorderedlist'];
            } else {
                options.buttons = ['bold', 'italic', 'underline', 'anchor', 'header1', 'header2', 'unorderedlist'];
            }
            if (attrs.placeholder) options.placeholder = attrs.placeholder;

            new MediumEditor(element, options);
        }

        return {
            link: link
        };
    })
    .directive('contenteditable', ['$sce', function ($sce) {
        return {
            restrict: 'A', // only activate on element attribute
            require: '?ngModel', // get a hold of NgModelController
            link: function (scope, element, attrs, ngModel) {
                if (!ngModel) return; // do nothing if no ng-model

                // Specify how UI should be updated
                ngModel.$render = function () {
                    element.html($sce.getTrustedHtml(ngModel.$viewValue || ''));
                };

                // Listen for change events to enable binding
                element.on('blur keyup change', function () {
                    scope.$apply(read);
                });
                read(); // initialize

                // Write data to the model
                function read() {
                    var html = element.html();
                    // When we clear the content editable the browser leaves a <br> behind
                    // If strip-br attribute is provided then we strip this out
                    if (attrs.stripBr && html == '<br>') {
                        html = '';
                    }
                    ngModel.$setViewValue(html);
                }
            }
        };
    }])
    .directive('alWheelzoom', function () {
        function link(scope, element, attrs) {

            $(element).dblclick(function (e) {
                $(element).trigger('wheelzoom.reset');
            });

            scope.$watch(attrs.alSrc, function (value) {
                if (!value) return;

                $(element).removeAttr('height').removeAttr('width').css('background', '');

                window.setTimeout(function () {
                    $(element).wheelzoom();
                }, 500);
            });

        }

        return {
            link: link
        };
    })
    .directive('alHasLoading', function () {
        function link(scope, element, attrs) {
            var val = attrs.alHasLoading || 'is_loading';

            scope.$watch('is_loading', function (value) {
                if (!value) $(element).removeClass('ajax-opacity');
                else $(element).addClass('ajax-opacity');
            });
        }

        return {
            link: link
        };
    })
    .directive('ngFiles', function () {
        return function (scope, element, attrs) {
            element.click(function (event) {
                var func = attrs.ngFiles;

                if (scope[func]) {
                    $('<input type="file" multiple="multiple" />').change(function (e) {
                        scope[func].apply(e.delegateTarget, this.files);
                    }).click();
                }
            });
        };
    })
    .directive('alSwitchery', function () {

        function ajaxCall(url, data, icon) {
            $.ajax({
                url: url,
                type: 'post',
                data: data,
                dataType: 'json',
                success: function (response) {
                    if (response.success) growl(response.message, icon);
                    else growlError(response.message, icon);
                }
            });
        }

        function clientField(e) {
            var attrs = e.data.attrs;

            var data = {};
            data[attrs.alSwitchery] = this.checked ? 1 : 0;
            ajaxCall(window.location.pathname + '/profile', data, 'account');
        }

        function vendorField(e) {
            var attrs = e.data.attrs;

            var data = {};
            data[attrs.alSwitchery] = this.checked ? 1 : 0;
            ajaxCall(window.location.pathname + '/profile', data, 'account');
        }

        function emailSubscriptionField(e) {
            var attrs = e.data.attrs;

            var unsubscribe = this.checked ? false : true;
            ajaxCall('/api/v2/users/' + attrs.id + '/preferences/email', {
                scope: attrs.alSwitchery,
                unsubscribe: unsubscribe
            }, 'email-subscription');
        }

        function scopeAccessField(e) {
            var attrs = e.data.attrs;

            var unsubscribe = this.checked ? false : true;
            ajaxCall('/api/v2/users/' + attrs.id + '/preferences/scope', {
                scope: attrs.alSwitchery,
                unsubscribe: unsubscribe
            }, 'scope');
        }

        function updateFunction(e) {
            var attrs = e.data.attrs;
            if (attrs.onUpdate) {
                e.data.scope[attrs.onUpdate](e, this.checked);
            }
        }

        function link(scope, element, attrs) {
            var switchery = new Switchery(element[0], {
                color: '#91ac21',
                secondaryColor: '#eeeeee', // this doesn't work?
                className: 'switchery switchery-sm'
            });

            if (attrs.ngModel) {
                scope.$watch(attrs.ngModel, function (value) {
                    if (!value || value == '0') switchery.setPosition(false);
                    else switchery.setPosition(true);
                });
            }

            switch (attrs.alType) {
                case 'client-information':
                case 'vendor-information':
                    $(element).change({attrs: attrs}, clientField);
                    break;
                case 'email-subscription':
                    $(element).change({attrs: attrs}, emailSubscriptionField);
                    break;
                case 'scope-access':
                    $(element).change({attrs: attrs}, scopeAccessField);
                    break;
                default :
                    $(element).change({attrs: attrs, scope: scope}, updateFunction);
            }

        }

        return {
            link: link,
            transclude: true
        };
    })
    .directive('alHref', ['$location', function ($location) {
        return function (scope, element, attrs) {
            element.bind('click', function (event) {
                if (event.target.nodeName == 'A') return;

                var url = attrs.alHref;
                $location.url(url, url);
                scope.$apply();
            });
        };
    }])
    .directive('alLabelStatusLabel', ['USER_TYPE', function (USER_TYPE) {
        return function (scope, element, attrs) {
            /*
             <td style="width: 165px;" al-label-status-label="order.status">

             <a href="" style="font-weight: bold; font-size: 12px; padding: 10px 0px; line-height:1; text-align: center;" class="text-danger"><i class="fa fa-exclamation-triangle"></i>&nbsp; Not Submitted</a>
             <a href="" style="font-weight: bold; font-size: 12px; padding: 10px 0px; line-height:1; text-align: center;" class="text-info"><i class="fa fa-retweet"></i>&nbsp; Processing</a>
             <a href="" style="font-weight: bold; font-size: 12px; padding: 10px 0px; line-height:1; text-align: center;" class="text-success"><i class="fa fa-share"></i>&nbsp; Shipped</a>

             </td>
             */
            var order = scope.$eval(attrs.alLabelStatusLabel);
            var orderStatus = order.status;

            var url = (USER_TYPE == 'admin' ? order.account.url : '');

            url = url + '/labels/order/' + order.id;

            var btnClass = 'default';
            var icon = '';
            var $el;
            var btnText = '';

            switch (orderStatus) {
                // Not submitted yet
                case '1':
                    btnClass = 'danger';
                    btnText = 'Not Submitted';
                    icon = 'exclamation-triangle';

                    break;
                // Processing
                case '2':
                    btnClass = 'info';
                    btnText = 'Processing';
                    icon = 'retweet';

                    break;
                // Shipped
                case '3':
                    btnClass = 'success';
                    btnText = 'Shipped';
                    icon = 'share';

                    break;
            }

            $el = $('<a href="' + url + '" style="font-weight: bold; font-size: 12px; padding: 10px 0px; line-height:1; text-align: center;" class="text-' + btnClass + '"><i class="fa fa-' + icon + '"></i>&nbsp; ' + btnText + '</a>');

            element.append($el);
        };
    }])
    .directive('alLabelInvoiceType', ['USER_TYPE', '$filter', function (USER_TYPE, $filter) {
        return function (scope, element, attrs) {
            var order = scope.$eval(attrs.alLabelInvoiceType);
            var invoice = order.invoice;

            var url = USER_TYPE == 'admin' ? order.account.url + invoice.url : invoice.url;
            var label = 'muted';
            var displayText = invoice.payment_status.display;

            switch (invoice.payment_status.id) {
                // open, partially paid, failed
                case 1:
                case 2:
                case 7:
                    label = 'text-danger';
                    break;
                // waived, voided
                case 3:
                case 4:
                    label = 'text-info';
                    break;
                // Check, eft
                case 5:
                case 6:
                    label = 'text-muted';
                    break;
            }


            var $a = $('<a class="load-account" href="' + url + '" />');
            $a.append('<div class="' + label + '">' + $filter('currency')(order.total, '$') + '<div style="font-size: 85%; line-height:1; font-weight: bold;">' + displayText + '</div></div>');
            element.append($a);
        };
    }])
    .directive('alDisallowPoBox', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attrs, ctrl) {
                ctrl.$validators.invalidAddrType = function (modelValue, viewValue ) {
                    const strippedValue = viewValue.toLowerCase().replace(/[^a-z]/gi, '');
                    return strippedValue.indexOf("pobox") === -1;
                };
            }
        };
    })
    .directive('alLabelOrderDetail', function () {
        return function (scope, element, attrs) {

            var orderProducts = scope.$eval(attrs.alLabelOrderDetail);

            var $el;
            var prodList = '';

            orderProducts.forEach(function (item, index) {
                prodList += `${item.quantity}x ${item.label.name}`;


                if (index != orderProducts.length - 1) {
                    prodList += '<br> ';
                }
            });

            $el = $('<a><small>' + prodList + '</small></a>');

            element.append($el);
        };
    });
