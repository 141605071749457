import portalControllers from './_controllers';
portalControllers
.controller('InquiryIndexCtrl', ['$scope', '$routeParams', 'Inquiries', 'das', function($scope, $routeParams, Inquiries, das) {
	new das($scope, Inquiries);
}])
.controller('InquiryCtrl', ['$scope', '$routeParams', '$location', 'Inquiries', function($scope, $routeParams, $location, Inquiries) {

	$scope.inquiry = Inquiries.get({ inquiryID: $routeParams.inquiryID });

	var $form = $('#inquiry-form');

	$scope.approveAndEmail = function(e) {
		var $button = $(e.delegateTarget);

		if ($form.valid()) {
			var data = $form.serializeArray();
			data.push({ name: 'decision_text', value: $('#approve-inquiry-email').html().trim() });

			$button.prop('disabled', true).data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i> Approving...');

      $location.search({});
			$.ajax({
				url: window.location.pathname,
				type: 'post',
				data: data,
				dataType: 'json',
				success: function(response) {
					growlit(response);

					if (response.success) {
						$('.modal').modal('hide');

						window.setTimeout(function() {
              $location.path('/vendors/inquiries');
							$scope.$apply();
						}, 500);
					} else if (!response.success && response.error) {
            growlError(response.error);
          }
				},
				complete: function(response) {
					$button.prop('disabled', false).html($button.data('old'));
				}
			});
		}
	};

	$scope.rejectInquiry = function(e) {
		var $button = $(e.delegateTarget);

		var data = $form.serializeArray();
		data.push({ name: 'decision_text', value: $('#reject-inquiry-email').html().trim() });
		$button.prop('disabled', true).data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i> Rejecting...');

    $location.search({});
		$.ajax({
			url: window.location.pathname,
			type: 'delete',
			data: data,
			dataType: 'json',
			success: function(response) {
				growlit(response);

				if (response.success) {
					$('.modal').modal('hide');

					window.setTimeout(function() {
            $location.path('/vendors/inquiries');
						$scope.$apply();
					}, 500);
				}
			},
			complete: function(response) {
				$button.prop('disabled', false).html($button.data('old'));
			}
		});
	};


	$scope.inviteUser = function(e) {
		var $button = $(e.delegateTarget);

		var data = $form.serializeArray();

		data.push({ name: 'decision_text', value: $('#invite-inquiry-email').html().trim() });
		data.push({ name: 'vendor_id', value: $scope.selected_vendor.id });

		$button.prop('disabled', true).data('old', $button.html()).html('<i class="fa fa-spin fa-spinner"></i> Inviting...');

		$.ajax({
			url: window.location.pathname + '/invite-user',
			type: 'post',
			data: data,
			dataType: 'json',
			success: function(response) {
				growlit(response);

				if (response.success) {
					$('.modal').modal('hide');

					window.setTimeout(function() {
						$location.path('/vendors/inquiries');
						$scope.$apply();
					}, 500);
				}
			},
			complete: function(response) {
				$button.prop('disabled', false).html($button.data('old'));
			}
		});
	};

	$scope.updateSelectedVendor = function(vendor) {
		$scope.selected_vendor = vendor;
	};

}])
.controller('ExistingVendorsCtrl', ['$scope', function($scope) {
	$scope.selected_vendor = false;

	$scope.setSelectedVendor = function(e, vendor) {
		$scope.selected_vendor = vendor;
		$(e.target).siblings().hide();

		$scope.updateSelectedVendor(vendor);
	};
}]);
