import portalControllers from './_controllers';
portalControllers
.controller('LicenseApplicationCtrl', ['$scope', '$location', '$window', 'API', 'USER_TYPE', '$controller', '$http', '$timeout', function($scope, $location, $window, API, USER_TYPE, $controller, $http, $timeout) {
	$scope.is_loading = false;
  $scope.partial_object = {};
  $scope.isPaymentApproved = 0;

    var accountID = '';

	$scope.current_step = 0;

	// data-toggle="modal" data-target="#apply-modal"

	// Get URL parrameters
	var params = getParams();

	// Auto-check orgnaization, select=clientID
	$scope.select_client = params.select;
	$scope.update = params.update;

	var path_parts = getPathParts();
	var url_prefix = '';
	var step_index_to_look_at = 2;
	if (path_parts[0] == 'vendors') {
		step_index_to_look_at = 4;
		url_prefix = 'vendors/' + path_parts[1] + '/';

        accountID = path_parts[1];
	}
	if ($scope.update == "products"){
		$scope.progress_steps = [{
			url: 'organizations',
			title: 'Select Organizations',
			percent: '30'
		}, {
			url: 'products',
			title: 'Product Categories',
			percent: '60'
		}, {
			url: 'review',
			title: 'Review and Submit',
			percent: '90'
		}];

	} else {
		$scope.progress_steps = [{
			url: 'organizations',
			title: 'Select Organizations',
			percent: '10'
		}, {
			url: 'operations',
			title: 'Business Operations',
			percent: '20'
		}, {
			url: 'history',
			title: 'Business History',
			percent: '40'
		}, {
			url: 'products',
			title: 'Product Categories',
			percent: '60'
		}, {
			url: 'review',
			title: 'Review and Submit',
			percent: '80'
		}];
	}

    API.call('account/' + accountID).success(function(response) {
        $scope.account = response.data;
        $scope.account_profile = $scope.account.profile;
        if ($scope.account_profile.fla_category_id) {
          $scope.account_profile.fla_category_id = Number($scope.account_profile.fla_category_id);
        }
        if ($scope.parseOperationsFromAccount) {
        	$scope.parseOperationsFromAccount();
		}
        if (!$scope.$$phase) $scope.$apply();
    });


    if (path_parts[step_index_to_look_at] == 'organizations' && USER_TYPE != 'admin') {
		$('#apply-modal').modal({
			backdrop: 'static',
			keyboard: false
		}).on('hide.bs.modal', function() {
			if (!$('#sign-consent').is(':checked')) {
				window.setTimeout(function() {
					$location.path('/licenses');
					if (!$scope.$$phase) $scope.$apply();
				}, 400);
			}
		});
	}


	$('.disabled-navigation').html('Please finish and submit the license application.').addClass('hide-both');


	$scope.$on('$destroy', function(){
		$('.disabled-navigation').removeClass('hide-both').html('');
	});

	//
	// NEW FUNCTIONS
	// TO DO MOVE ALL / Application to new controller so naming is unique to application functions
	//

	$scope.getPossibleClients = function() {

		$.getJSON('/api/v2/' + url_prefix + 'licenses/applications/possible-clients', function(response) {

			$scope.possible_clients = response.data;
			$scope.updateSelectClientButton();
		});
	};
	$scope.selected_clients = {};
	$scope.getSelectedClients = function() {
		$.getJSON('/api/v2/' + url_prefix + 'licenses/applications/clients', function(response) {
			response.clients.forEach(function(element){
				$scope.selected_clients[element] = element;
			});

		});
	};
	$scope.selected_products = [];
	$scope.getSelectedProducts = function() {
		$.getJSON('/api/v2/' + url_prefix + 'licenses/applications/products', function(response) {
      $scope.selected_products = response.data;
      $scope.parseProducts();
		});
	};

	$scope.updateProgress = function() {
		var complete = true;
		$.each($scope.progress_steps, function(i, step) {
			step.active = false;
			step.complete = false;

			if (i == $scope.current_step) {
				step.active = true;
				complete = false;
			}

			step.complete = complete;
		});
	};

	$scope.goToStep = function(index) {
		if (index > $scope.current_step) return;

		$scope.setStep(index, true);
	};

	$scope.nextStep = function() {

		$scope.setStep($scope.current_step + 1, true);
	};

	$scope.prevStep = function() {
		if ($scope.current_step === 0) {
			$window.location.href = '/' + url_prefix + 'licenses';

		} else {
      if ($scope.current_step - 1 === 3) {
        $scope.parsed_products = {
          available:[],
          archived:[],
        };
        $scope.getSelectedProducts();
      }
      $scope.setStep($scope.current_step - 1, true);
		}
	};

	$scope.$watch('current_step', function(value) {
		if (value && value === 5) $('.disabled-navigation').removeClass('hide-both');
	});

	/**
		submits the form
	*/
	$scope.submitStep = function(response) {
    //$scope.nextStep();
    if (final_step) {
      const invoice = response.data;
      if (invoice) {
        if ($scope.total_cost > 0) {
          $location.path(url_prefix !== '' ? `${url_prefix}invoices/${invoice.id}/pay` : `/invoices/${invoice.id}/pay`);
        } else {
          $location.path(url_prefix !== '' ? `${url_prefix}licenses` : '/licenses');
        }

      } else {
        $location.path(url_prefix !== '' ? url_prefix + 'licenses/applications/' : '/licenses/applications/');
      }

    } else {
      $scope.nextStep();
    }

		/*
		if (final_step) {
			$scope.application = response.data;
			$scope.application.samples_needed = [];
			$.each($scope.application.products, function(i, prod) {
				if (prod.need_sample == '1') $scope.application.samples_needed.push(prod);
			});
		}
		*/
        if (!$scope.$$phase) $scope.$apply();
		$('#step-container').removeClass('hide-it');
	};
	var final_step = false;

	$scope.submitForm = function(e, is_submit_and_pay) {
		if (!$('#step-form').valid()) return;
		if (is_submit_and_pay) {
			final_step = true;
			$scope.is_loading = true;
			$('#apply_next').hide();
			$('#apply_back').hide();
			//$(e.delegateTarget).prop('disabled', true).find('.fa').removeClass('fa-check').addClass('fa-spin fa-spinner');
		}
		var parts = getPathParts();
		if (parts.pop() == 'products') {
			var values = $('#step-form').serializeArray();
			if (values.length < 1) {
				growlError('Please add a product in order to continue');
				return;
			}
		}

		$('#step-form').submit();
  };

  $scope.showPaymentApproval = function() {
    $('#confirm-payment').modal('show');
  };



	$('#step-form').on('submit.al-form-validate', function(form) {
		$('#step-container').addClass('hide-it');
	});

	$scope.setStep = function(num, force_set_url) {
		if(num == 5 || ($scope.update == "products" && num == 3)){
			$scope.is_loading = true;
			$('#apply_next').hide();
			$('#apply_back').hide();
			$location.path(url_prefix !== '' ? url_prefix + 'licenses/applications/' : '/licenses/applications/');

		} else {
			$scope.current_step = num;
			$scope.current_step_obj = $scope.progress_steps[$scope.current_step];

			if(!$scope.$$phase || force_set_url) {
				var path = url_prefix !== '' ? url_prefix + 'licenses/apply/' : '/licenses/apply/';

				$location.path(path + $scope.current_step_obj.url, false);
			}

			$(".main-content").animate({ scrollTop: 0 }, 100);

			$('#step-form').attr('action', $location.path());

			$scope.updateProgressBar();

			$scope.updateProgress();

			//$scope.getPartialObject();
			}
	};

	$scope.updateProgressBar = function() {

		$('#progress-bar-div').attr('aria-valuenow', $scope.current_step_obj.percent).css('width', $scope.current_step_obj.percent + '%');
	};

	$scope.getSelectedClients();
	$scope.getSelectedProducts();
	$scope.spinner = true;
	$.each($scope.progress_steps, function(i, step) {
		if (step.url == path_parts[step_index_to_look_at]) {
			$scope.setStep(i);
		}
	});


	/*#############################################################################################################################
	#	STEP 1 - clients
	#############################################################################################################################*/
	// Orgnaization Filters
	$scope.updateText = "License Update Application";
	$scope.updateDescription = "Apply to update existing licenses with new products, modified business information, or other significant updates.";

	$scope.filters = {
		name : "",
		market_id: "",
		organization_type: "",
		license_status: "1"
	};

	if($scope.update == "products") {
		$scope.filters.license_status = "2";
		$scope.updateText = "Add Products Application";
		$scope.updateDescription = "Apply to update existing licenses with new products.";
	}
	if($scope.update == "business") {
		$scope.filters.license_status = "2";
		$scope.updateText = "Business Change Application";
		$scope.updateDescription = "Apply to update existing licenses with modified business information.";
	}

	$scope.total_selected = 0;
	$scope.total_cost = 0;
	$scope.client_list = [];

	$.getJSON('/api/v2/' + url_prefix + 'licenses/applications/possible-clients', function(response) {
		$scope.spinner = false;
		$scope.possible_clients = response.data;
		$.each($scope.possible_clients, function(i, client) {
			$scope.client_list.push(client);

		});

		if($scope.current_step > 1){
			for(var element in $scope.selected_clients){
				$scope.clientAdded($scope.possible_clients[element]);

			}
		}

		$scope.updateSelectClientButton();
	});

	$scope.select_all = true;

	$scope.updateSelectClientButton = function() {
		var length = $('input[name="clients[]"]').length;
		var checked = 0;
		$('input[name="clients[]"]').each(function(i, el) {
			if (el.checked) checked += 1;
		});

		$scope.select_all = checked < length - 1;

		if (!$scope.$$phase) $scope.$apply();
	};

	$scope.clientAdded = function(client) {
		if(!$scope.selected_clients.hasOwnProperty(client.id) || $scope.selected_clients[client.id] == client.id){
			if(client.license_status != 2){
				$scope.total_cost +=  parseInt(client.application_fee, 10);
			}
			$scope.selected_clients[client.id] = client;
			$scope.total_selected += 1;
			$scope.updateSelectClientButton();
		}
	};

	$scope.clientRemoved = function(client) {
		if($scope.selected_clients.hasOwnProperty(client.id)){
			if(client.license_status != 2){
				$scope.total_cost -= client.application_fee;
			}
			delete $scope.selected_clients[client.id];
			$scope.total_selected -= 1;
		}


		$scope.updateSelectClientButton();
	};

	$scope.sortClients = function(client){
			return client.order;
	};

	$scope.selectAll = function(select) {
		$.each($scope.possible_clients, function(i, client) {
			if (select) client.checkIt();
			else client.unCheckIt();
		});
	};


	/*#############################################################################################################################
	#	STEP 2 - information
	#############################################################################################################################*/
	API.call(url_prefix + 'users?limit=-1').success(function(response) {
		$scope.users = response.data;
		if (!$scope.$$phase) $scope.$apply();
	});

    angular.extend(this, $controller('operationsUpdateFormCtrl', {$scope: $scope}));


	/*#############################################################################################################################
	#	STEP 4 - products
	#############################################################################################################################*/

	$scope.parsed_products = {
		available:[],
		archived:[],
	};




  $scope.getInitialProducts = function(){
    $http({ method: 'GET', url: `/api/vendor-products${accountID ? `?account_id=${accountID}` : ''}` }).success(function (response) {
      $scope.products = response.data;
      $scope.haveProducts = true;
      $scope.parseProducts();
    });
  }

  $scope.getInitialProducts();

	$scope.parseProducts = function(){
		var parsed_products = {
			available:[],
			archived:[]
    };
		if($scope.haveProducts && $scope.selected_products){

			$scope.products.forEach(function(element){
				//first pick out the archived
				if(element.category.archive == 1 || element.category.is_parent == 1 || element.category.verified != 1){
					parsed_products.archived.push(element);
				}
				//everything else is available
				else {
					parsed_products.available.push(element);
				}
      });
      $scope.parsed_products = parsed_products;
      $timeout(() => {
        $scope.$apply();
      });
    }
	};

	$scope.updatePaymentMethod = function(not_added) {
		var id = path_parts[0] == 'vendors' ? path_parts[1] : false;

		API.paymentMethod(id).success(function(response) {
			$scope.payment_method = response.data;
		});

		$scope.payment_method_added = !not_added;
	};
	$scope.updatePaymentMethod(true);

	$scope.productAdded = function(product) {
    $scope.products.push(product);
		$scope.parseProducts();
		$scope.$apply();
	};
}])
.controller('LicenseApplicationClientCtrl', ['$scope', function($scope) {

	//
	//	Dedicated Step one controller
	//

	var clients = $scope.selected_clients || {};

	// onLoad for preselected client
	if (($scope.select_client == $scope.client.id) || (clients.hasOwnProperty($scope.client.id)) || (($scope.update == "products" || $scope.update == "business") && $scope.client.license_status == 2)) {
		$scope.is_checked = 1;
		$scope.clientAdded($scope.client);
		if($scope.client.license_status == 2 && !($scope.update == "products" || $scope.update == "business")){

			$scope.filters.license_status = "";
		}
	}

	$scope.client.checkIt = function() {
		$scope.is_checked = 1;
		$scope.clientAdded($scope.client);
	};

	$scope.client.unCheckIt = function() {
		$scope.is_checked = 0;
		$scope.clientRemoved($scope.client);
	};

	$scope.change = function(value){
		// console.log(value);
		if (value == 1) $scope.clientAdded($scope.client);
		else $scope.clientRemoved($scope.client);
	};

	//this is to hide all except those that are selected. This will keep all the elements on the form and just hide those that dont want to be seen
	$scope.isExcludedByFilter = applySearchFilter();
	$scope.$watch('filters.license_status', function(value, oldval, $scope) {
		applySearchFilter();
	},true);
	$scope.$watch('filters.market_id', function(value, oldval, $scope) {
		applySearchFilter();
	},true);
	$scope.$watch('filters.organization_type', function(value, oldval, $scope) {
		applySearchFilter();
	},true);
	$scope.$watch('filters.name', function(value, oldval, $scope) {
		applySearchFilter();
	},true);

	function applySearchFilter() {
		//I am going to need four filters. One for license status, Gender Type, Name and Market
		var LicenseFilter = (
			//nothing is selected so allow
			$scope.filters.license_status === "" ||
			(
				(//if not licensed selected
					$scope.filters.license_status == 1 &&
					(
						$scope.client.license_status != 2
					)
				)||
				(//if licensed selected
					$scope.filters.license_status == 2 &&
					(
						$scope.client.license_status == 2 &&
						//to remove all pending licenses
						['1','2','3'].indexOf($scope.client.license_phase) < 0
					)
				)
			)
		);

		var MarketFilter = ($scope.client.market_id == $scope.filters.market_id || $scope.filters.market_id === "");

		var GenderFilter = ($scope.client.organization_type == $scope.filters.organization_type || $scope.filters.organization_type === "");

		var NameFilter = ($scope.client.name.toLowerCase().indexOf($scope.filters.name.toLowerCase()) !== -1);

		//should only show up if it passes all three filters
		$scope.isExcludedByFilter = !(LicenseFilter && MarketFilter && GenderFilter && NameFilter);
	}
}])
.controller('LicenseApplicationOperationsCtrl', ['$scope', function($scope) {




}])

.controller('LicenseApplicationProductCtrl', ['$scope', function($scope) {
	var products = $scope.selected_products;
  //console.log(products);

  $scope.setCheckedProducts = function(){
    if ($scope.product.is_checked == 1) {
      $scope.is_checked = 1;
    } else if (products.indexOf($scope.product.id) > -1) {
      $scope.is_checked = 1;
    } else if (products.length === 0) {
      $scope.is_checked = 1;
    }
  }
  $scope.setCheckedProducts();

}])
.controller('LicenseApplicationPaymentCtrl', ['$scope', 'API', function($scope, API) {
	var path_parts = getPathParts();
	var url_prefix = '';

	if (path_parts[0] == 'vendors') {
		url_prefix = 'vendors/' + path_parts[1] + '/';
	}
	$scope.url_prefix = url_prefix;
	//$scope.clients

	/*
	$.getJSON('/api/v2/licenses/applications/clients-info', function(response) {

		$scope.clients			= response.data.clients;
		$scope.total			= response.data.total;
	});
	*/
}]);
